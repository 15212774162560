import messages from '../../intl/messages';
import { useRef, useEffect } from 'react';
import { invertObj } from 'ramda';
import { useIntl } from 'react-intl';
import { Switch } from '@progress/kendo-react-inputs';
import { QUESTION_STATE, TEST_STATE } from '../../consts/ORGANIZATOR';
import { QUESTION, TEST } from '../../consts/RTK';
import { apiSlice } from '../../store/api/apiSlice';
import { useDispatch } from 'react-redux';
import { showModal } from '../../store/modals/modalsSlice';
import { MODAL_TYPES } from '../Modals/ModalsRoot';

const ItemState = ({ state, type, id, missing }) => {
  const intl = useIntl();
  const switchRef = useRef(null);
  const dispatch = useDispatch();
  const stateType = type ? QUESTION_STATE : TEST_STATE;
  const rtkType = type ? QUESTION : TEST;

  useEffect(() => {
    if (id) {
      const switchThumb =
        switchRef.current._element.lastElementChild.firstElementChild;
      if (switchThumb) {
        const label = invertObj(stateType)[state].toLowerCase();
        switchThumb.textContent = label;

        if (switchThumb.classList.contains('label')) {
          switchThumb.classList.toggle('warning');
          switchThumb.classList.toggle('success');
        } else {
          switchThumb.classList.add(
            'label',
            'bulletpoint',
            state === 2 ? 'success' : 'warning'
          );
        }
      }
    }
  }, [id, stateType, state]);

  const [updateState] =
    apiSlice[rtkType.UPDATE[type ? 'QUESTION_STATE' : 'TEST_STATE'].MUTATION]();

  const handleUpdateState = async () => {
    // stop switching to done state
    if (missing.length > 0) {
      dispatch(
        showModal({
          type: MODAL_TYPES['HAS_MISSING'],
          props: {
            item: {
              message: type ? 'Question' : 'Test',
              list: missing,
            },
          },
        })
      );
      return;
    }

    const newState = state === 2 ? 1 : 2;
    const result = await updateState({
      [type ? 'idItem' : 'idTest']: id,
      state: newState,
    });

    if (result?.error) {
      if (result.error.status === 400) {
        dispatch(
          showModal({
            type: MODAL_TYPES['SWITCH_TO_DRAFT'],
            props: {
              item: {
                message: type ? 'Question' : 'Test',
              },
            },
          })
        );
      } else {
        console.error(result.error.data.message);
      }
    }
  };

  if (id) {
    return (
      <Switch
        name="stateSwitch"
        className="state-switch"
        onChange={() => handleUpdateState()}
        size="small"
        onLabel={'Draft'}
        offLabel={'Done'}
        checked={state === 2 ? true : false}
        ref={switchRef}
      />
    );
  }

  return (
    <div
      className={`label bulletpoint ${
        state === stateType.DONE ? 'success' : 'warning'
      }`}
    >
      {typeof state !== 'undefined' &&
        intl.formatMessage(messages[invertObj(stateType)[state].toLowerCase()])}
    </div>
  );
};

export default ItemState;
