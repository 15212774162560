import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ROUTES from '../../consts/ROUTES';
import { EVENTS, QUESTIONS, TESTS } from '../../consts/RTK';
import messages from '../../intl/messages';
import { apiSlice } from '../../store/api/apiSlice';
import { getUserRoles } from '../../store/auth/authSlice';
import { filterRoutesByUserRoles } from '../../utils';
import { none } from 'ramda';
import Svg from '../Common/Svg';
import './LeftBarTemplate.scss';

const GetIntlString = (translation) => {
  const intl = useIntl();
  return !translation
    ? null
    : intl.formatMessage(messages[translation.props.id.match(/[^.]+$/)[0]]);
};

// Returns list of menu categories
const menuCategories = (items) => {
  const categories = [];

  for (const { CATEGORY } of items) {
    const category = GetIntlString(CATEGORY);

    if (!categories.includes(category)) categories.push(category);
  }

  return categories;
};

const Menu = () => {
  const userRoles = useSelector(getUserRoles);
  const checkRoles = (role) => userRoles?.includes(role);
  const skip = {
    events: none(checkRoles, ['eventManager', 'expert']),
    questions: none(checkRoles, ['author', 'expert']),
    tests: none(checkRoles, ['author', 'expert']),
  };
  const items = filterRoutesByUserRoles(ROUTES, userRoles);
  const { pathname } = useLocation();
  const { data: eventsCount } = apiSlice[EVENTS.GET_COUNT.QUERY](null, {
    refetchOnMountOrArgChange: true,
    skip: skip.events,
  });
  const { data: questionsCount } = apiSlice[QUESTIONS.GET_COUNT.QUERY](null, {
    refetchOnMountOrArgChange: true,
    skip: skip.questions,
  });
  const { data: testsCount } = apiSlice[TESTS.GET_COUNT.QUERY](null, {
    refetchOnMountOrArgChange: true,
    skip: skip.tests,
  });

  // Assign count
  const count = {
    [ROUTES.DASHBOARD.EVENTS.PATH]: eventsCount,
    [ROUTES.DASHBOARD.QUESTIONS.PATH]: questionsCount,
    [ROUTES.DASHBOARD.TESTS.PATH]: testsCount,
  };

  const linkClick = (event, isActive) => {
    if (isActive) {
      event.preventDefault();
    }
  };

  return menuCategories(items).map((parentCategory) => (
    <div key={`LeftBarTemplate-Menu-${parentCategory}`}>
      {parentCategory && <h4>{parentCategory}</h4>}
      <ul>
        {items
          .filter(
            ({ CATEGORY }) =>
              parentCategory && GetIntlString(CATEGORY) === parentCategory
          )
          .map(({ PATH, TITLE }, i) => {
            const isActive = pathname === PATH;

            return (
              <li
                key={`LeftBarTemplate-Menu-${parentCategory}-${PATH}-${i}`}
                className={`${isActive ? 'active' : ''}`}
              >
                <Link to={PATH} onClick={(event) => linkClick(event, isActive)}>
                  <div>
                    {/* TODO: Remove this condition when settings have icons */}
                    {!PATH.includes('settings') && <Svg name={PATH} />}
                    {TITLE}
                  </div>
                  {!PATH.includes('settings') && (
                    <span className="label">{count[PATH]}</span>
                  )}
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  ));
};

export default Menu;
