import ModalTemplate from './ModalTemplate';
import messages from '../../intl/messages';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextArea } from '@progress/kendo-react-inputs';
import { closeModal } from '../../store/modals/modalsSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { isEmpty } from 'ramda';

const RejectExamModal = ({
  idExamInstance,
  userId,
  violations,
  handleExamReview,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [violationMessage, setViolationMessage] = useState('');

  const handleSave = async () => {
    const response = await handleExamReview(idExamInstance, {
      reviewedBy: userId,
      review: 'FAIL',
      finalViolationMessage: violationMessage,
    });

    if (response?.data) {
      dispatch(closeModal());
    }
  };

  return (
    <ModalTemplate
      isSaveBtnDisabled={isEmpty(violationMessage)}
      onSave={handleSave}
      saveBtnTitle={<FormattedMessage {...messages.rejectExam} />}
      title={<FormattedMessage {...messages.rejectExam} />}
    >
      <strong>{intl.formatMessage(messages.violation)}</strong>
      <br />
      <DropDownList
        data={[...new Set(violations.map(({ comment }) => comment))]}
        onChange={(e) => setViolationMessage(e.value)}
        value={violationMessage}
      />
      <br />
      <br />

      <strong>{intl.formatMessage(messages.feedback)}</strong>
      <br />
      <TextArea
        placeholder={intl.formatMessage(messages.addFeedback)}
        rows={6}
      />
    </ModalTemplate>
  );
};

export default RejectExamModal;
