import messages from '../../intl/messages';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ROUTES from '../../consts/ROUTES';
import './Header.scss';

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <img className="logo" alt="logo" src="/images/logo.png" />
      </Link>
      <div>
        <FormattedMessage {...messages.needHelp} />
        <Link to={`${ROUTES.PUBLIC.FAQ.PATH}`} className="link">
          <FormattedMessage {...messages.learnMore} />
        </Link>
      </div>
    </div>
  );
};
export default Header;
