import QuestionsAnswers from './QuestionsAnswers';
import Proctoring from './Proctoring';
import messages from '../../../../intl/messages';
import { Context } from '../Results';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router';
import { proctoringApiSlice } from '../../../../store/proctoring/proctoringApiSlice';
import { PROCTORING } from '../../../../consts/RTK';

const ExamPreview = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [tabState, setTabState] = useState(0);
  const { currentExamInstance, idExamInstance } = useContext(Context);
  const { firstName, lastName } = currentExamInstance;

  const response = proctoringApiSlice[PROCTORING.GET.EXAM_PREVIEW.QUERY](
    { idExamInstance },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const currentProctoringExam =
    response?.status === 'rejected' ? {} : response?.data;

  const tabStripProctoring =
    currentProctoringExam?.exam?.jobStatus === 'COMPLETED' ? (
      <TabStripTab title={intl.formatMessage(messages.proctoring)}>
        <Proctoring currentProctoringExam={currentProctoringExam} />
      </TabStripTab>
    ) : (
      ''
    );

  return (
    <section className="exam-preview">
      <h1 className="display-flex">
        <span>
          <Button
            imageUrl="/images/arrowLeft.svg"
            className="plain"
            onClick={() => navigate(-1)}
          />
          {firstName} {lastName}
        </span>
      </h1>
      <TabStrip
        keepTabsMounted={true}
        onSelect={(e) => setTabState(e.selected)}
        selected={tabState}
      >
        {tabStripProctoring}
        <TabStripTab title={intl.formatMessage(messages.answersReview)}>
          <QuestionsAnswers />
        </TabStripTab>
      </TabStrip>
    </section>
  );
};

export default ExamPreview;
