import ROUTES from '../../consts/ROUTES';

const Svg = ({ name }) => {
  switch (name) {
    case ROUTES.DASHBOARD.EVENTS.PATH:
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case ROUTES.DASHBOARD.QUESTIONS.PATH:
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 6.5H10M5 10H13M5 16V18.3355C5 18.8684 5 19.1348 5.10923 19.2716C5.20422 19.3906 5.34827 19.4599 5.50054 19.4597C5.67563 19.4595 5.88367 19.2931 6.29976 18.9602L8.68521 17.0518C9.17252 16.662 9.41617 16.4671 9.68749 16.3285C9.9282 16.2055 10.1844 16.1156 10.4492 16.0613C10.7477 16 11.0597 16 11.6837 16H14.2C15.8802 16 16.7202 16 17.362 15.673C17.9265 15.3854 18.3854 14.9265 18.673 14.362C19 13.7202 19 12.8802 19 11.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V12C1 12.93 1 13.395 1.10222 13.7765C1.37962 14.8117 2.18827 15.6204 3.22354 15.8978C3.60504 16 4.07003 16 5 16Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case ROUTES.DASHBOARD.TESTS.PATH:
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 10H5M7 14H5M13 6H5M17 5.8V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H12.2C13.8802 1 14.7202 1 15.362 1.32698C15.9265 1.6146 16.3854 2.07354 16.673 2.63803C17 3.27976 17 4.11984 17 5.8Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'chevronDown':
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 1.5L6 6.5L11 1.5"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'event':
      return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="8" />
          <path
            d="M18 10.6666H6M14.6667 5.33325V7.99992M9.33333 5.33325V7.99992M9.2 18.6666H14.8C15.9201 18.6666 16.4802 18.6666 16.908 18.4486C17.2843 18.2569 17.5903 17.9509 17.782 17.5746C18 17.1467 18 16.5867 18 15.4666V9.86659C18 8.74648 18 8.18643 17.782 7.7586C17.5903 7.38228 17.2843 7.07632 16.908 6.88457C16.4802 6.66659 15.9201 6.66659 14.8 6.66659H9.2C8.0799 6.66659 7.51984 6.66659 7.09202 6.88457C6.71569 7.07632 6.40973 7.38228 6.21799 7.7586C6 8.18643 6 8.74648 6 9.86659V15.4666C6 16.5867 6 17.1467 6.21799 17.5746C6.40973 17.9509 6.71569 18.2569 7.09202 18.4486C7.51984 18.6666 8.0799 18.6666 9.2 18.6666Z"
            stroke="#FCFCFD"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'testPage':
      return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 1.26953V5.40007C11 5.96012 11 6.24015 11.109 6.45406C11.2049 6.64222 11.3578 6.7952 11.546 6.89108C11.7599 7.00007 12.0399 7.00007 12.6 7.00007H16.7305M11 16H5M13 12H5M17 8.98822V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H9.01178C9.74555 1 10.1124 1 10.4577 1.08289C10.7638 1.15638 11.0564 1.27759 11.3249 1.44208C11.6276 1.6276 11.887 1.88703 12.4059 2.40589L15.5941 5.59411C16.113 6.11297 16.3724 6.3724 16.5579 6.67515C16.7224 6.94356 16.8436 7.2362 16.9171 7.5423C17 7.88757 17 8.25445 17 8.98822Z"
            stroke="#0086C9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'welcomePage':
      return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.12602 13C6.57006 14.7252 8.13616 16 10 16C11.8638 16 13.4299 14.7252 13.874 13M9.0177 1.764L2.23539 7.03912C1.78202 7.39175 1.55534 7.56806 1.39203 7.78886C1.24737 7.98444 1.1396 8.20478 1.07403 8.43905C1 8.70352 1 8.9907 1 9.56505V16.8C1 17.9201 1 18.4801 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4801 19 17.9201 19 16.8V9.56505C19 8.9907 19 8.70352 18.926 8.43905C18.8604 8.20478 18.7526 7.98444 18.608 7.78886C18.4447 7.56806 18.218 7.39175 17.7646 7.03913L10.9823 1.764C10.631 1.49075 10.4553 1.35412 10.2613 1.3016C10.0902 1.25526 9.9098 1.25526 9.73865 1.3016C9.54468 1.35412 9.36902 1.49075 9.0177 1.764Z"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'thumbsUp':
      return (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83329 18.3333V9.16663M1.66663 10.8333V16.6666C1.66663 17.5871 2.41282 18.3333 3.33329 18.3333H14.5218C15.7558 18.3333 16.8051 17.433 16.9928 16.2134L17.8902 10.3801C18.1232 8.86571 16.9515 7.49996 15.4193 7.49996H12.5C12.0397 7.49996 11.6666 7.12686 11.6666 6.66663V3.72149C11.6666 2.58662 10.7466 1.66663 9.61176 1.66663C9.34107 1.66663 9.09578 1.82604 8.98584 2.0734L6.05324 8.67174C5.91949 8.97268 5.62106 9.16663 5.29173 9.16663H3.33329C2.41282 9.16663 1.66663 9.91282 1.66663 10.8333Z"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'thumbsUpWhite':
      return (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83329 18.3333V9.16663M1.66663 10.8333V16.6666C1.66663 17.5871 2.41282 18.3333 3.33329 18.3333H14.5218C15.7558 18.3333 16.8051 17.433 16.9928 16.2134L17.8902 10.3801C18.1232 8.86571 16.9515 7.49996 15.4193 7.49996H12.5C12.0397 7.49996 11.6666 7.12686 11.6666 6.66663V3.72149C11.6666 2.58662 10.7466 1.66663 9.61176 1.66663C9.34107 1.66663 9.09578 1.82604 8.98584 2.0734L6.05324 8.67174C5.91949 8.97268 5.62106 9.16663 5.29173 9.16663H3.33329C2.41282 9.16663 1.66663 9.91282 1.66663 10.8333Z"
            stroke="#ffffff"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'thumbsDown':
      return (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1667 1.66663V10.8333M17.3333 8.16663V4.33329C17.3333 3.39987 17.3333 2.93316 17.1517 2.57664C16.9919 2.26304 16.7369 2.00807 16.4233 1.84828C16.0668 1.66663 15.6001 1.66663 14.6667 1.66663H5.76501C4.54711 1.66663 3.93816 1.66663 3.44632 1.88949C3.01284 2.08591 2.64442 2.40198 2.38437 2.80056C2.08931 3.25279 1.99672 3.85466 1.81153 5.0584L1.37563 7.89173C1.13137 9.47939 1.00925 10.2732 1.24484 10.8909C1.45162 11.433 1.84054 11.8864 2.34494 12.1732C2.91961 12.5 3.72278 12.5 5.32912 12.5H6C6.46671 12.5 6.70007 12.5 6.87833 12.5908C7.03513 12.6707 7.16261 12.7982 7.24251 12.955C7.33334 13.1332 7.33334 13.3666 7.33334 13.8333V16.2784C7.33334 17.4133 8.25333 18.3333 9.3882 18.3333C9.65889 18.3333 9.90419 18.1739 10.0141 17.9265L12.8148 11.6251C12.9421 11.3385 13.0058 11.1952 13.1065 11.0901C13.1955 10.9972 13.3048 10.9262 13.4258 10.8826C13.5627 10.8333 13.7195 10.8333 14.0332 10.8333H14.6667C15.6001 10.8333 16.0668 10.8333 16.4233 10.6516C16.7369 10.4918 16.9919 10.2369 17.1517 9.92328C17.3333 9.56676 17.3333 9.10005 17.3333 8.16663Z"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'thumbsDownWhite':
      return (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1667 1.66663V10.8333M17.3333 8.16663V4.33329C17.3333 3.39987 17.3333 2.93316 17.1517 2.57664C16.9919 2.26304 16.7369 2.00807 16.4233 1.84828C16.0668 1.66663 15.6001 1.66663 14.6667 1.66663H5.76501C4.54711 1.66663 3.93816 1.66663 3.44632 1.88949C3.01284 2.08591 2.64442 2.40198 2.38437 2.80056C2.08931 3.25279 1.99672 3.85466 1.81153 5.0584L1.37563 7.89173C1.13137 9.47939 1.00925 10.2732 1.24484 10.8909C1.45162 11.433 1.84054 11.8864 2.34494 12.1732C2.91961 12.5 3.72278 12.5 5.32912 12.5H6C6.46671 12.5 6.70007 12.5 6.87833 12.5908C7.03513 12.6707 7.16261 12.7982 7.24251 12.955C7.33334 13.1332 7.33334 13.3666 7.33334 13.8333V16.2784C7.33334 17.4133 8.25333 18.3333 9.3882 18.3333C9.65889 18.3333 9.90419 18.1739 10.0141 17.9265L12.8148 11.6251C12.9421 11.3385 13.0058 11.1952 13.1065 11.0901C13.1955 10.9972 13.3048 10.9262 13.4258 10.8826C13.5627 10.8333 13.7195 10.8333 14.0332 10.8333H14.6667C15.6001 10.8333 16.0668 10.8333 16.4233 10.6516C16.7369 10.4918 16.9919 10.2369 17.1517 9.92328C17.3333 9.56676 17.3333 9.10005 17.3333 8.16663Z"
            stroke="#ffffff"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 6.5H10M5 10H13M5 16V18.3355C5 18.8684 5 19.1348 5.10923 19.2716C5.20422 19.3906 5.34827 19.4599 5.50054 19.4597C5.67563 19.4595 5.88367 19.2931 6.29976 18.9602L8.68521 17.0518C9.17252 16.662 9.41617 16.4671 9.68749 16.3285C9.9282 16.2055 10.1844 16.1156 10.4492 16.0613C10.7477 16 11.0597 16 11.6837 16H14.2C15.8802 16 16.7202 16 17.362 15.673C17.9265 15.3854 18.3854 14.9265 18.673 14.362C19 13.7202 19 12.8802 19 11.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V12C1 12.93 1 13.395 1.10222 13.7765C1.37962 14.8117 2.18827 15.6204 3.22354 15.8978C3.60504 16 4.07003 16 5 16Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};

export default Svg;
