import { useIntl } from 'react-intl';
import messages from '../../intl/messages';

const CSVButton = ({ endpoint, idExam, text }) => {
  const intl = useIntl();

  return (
    <a
      className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md primary"
      href={`${endpoint}?idExam=${idExam}`}
      target="_blank"
      rel="noreferrer"
    >
      <span className="k-button-text font-weight-normal">
        {intl.formatMessage(messages[text])}
      </span>
    </a>
  );
};

export default CSVButton;
