import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router';
import ROUTES from '../../consts/ROUTES';
import { Input } from '@progress/kendo-react-inputs';
import { isEmailValid } from '../../utils';
import './Login.scss';

const ForgottenPassword = ({ resetPassEmail, setResetPassEmail }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div className="login">
      <span className="logo">
        <img alt="Logo" src="/images/ellipse.svg" />
        <img alt="Logo" src="/images/ellipse.svg" />
      </span>
      <h2>
        <FormattedMessage {...messages.forgotPassword} />
      </h2>
      <h4>
        <FormattedMessage {...messages.weSendLinkToNewPassword} />
      </h4>
      <strong>
        <FormattedMessage {...messages.email} />
      </strong>
      <Input
        type="text"
        value={resetPassEmail}
        placeholder={intl.formatMessage(messages.enterYourEmail)}
        onChange={(event) => setResetPassEmail(event.target.value)}
      />
      <Button
        disabled={!isEmailValid(resetPassEmail)}
        className="primary"
        onClick={() => navigate(ROUTES.PUBLIC.CHECK_EMAIL.PATH)}
      >
        <FormattedMessage {...messages.sendResetLink} />
      </Button>
      <Button
        className="plain"
        onClick={() => navigate(ROUTES.PUBLIC.LOGIN.PATH)}
      >
        <img alt="Back" src="/images/arrowLeft.svg" />
        <FormattedMessage {...messages.backToLogIn} />
      </Button>
    </div>
  );
};

export default ForgottenPassword;
