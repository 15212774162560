import { defineMessages } from 'react-intl';
import base from './languages/base.json';

/*
Temporarily keep for flatten/unflatten json
TODO (SC-894) - delete after translations are done

const flattenJson = (obj) => {
  const flattened = {};
  // eslint-disable-next-line guard-for-in
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      flattened[key] = `${obj[key].defaultMessage}`;
    }
  }
  return flattened;
};

const flattenedJson = flattenJson(english);
console.log('flattenedJson');
console.log(flattenedJson);
*/

export default defineMessages(base);
