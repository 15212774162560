import './PublishEventModal.scss';
import Classroom from './Classroom';
import ModalTemplate from '../ModalTemplate';
import Proctoring from './Proctoring';
import ScheduleEvent from './ScheduleEvent';
import SelectTest from './SelectTest';
import Stepper from './Stepper';
import messages from '../../../intl/messages';
import { EVENT, EVENTS, EXAMINEES } from '../../../consts/RTK';
import { FormattedMessage } from 'react-intl';
import { GRID } from '../../../consts/KENDO';
import { apiSlice } from '../../../store/api/apiSlice';
import { closeModal } from '../../../store/modals/modalsSlice';
import { createContext, useEffect, useState } from 'react';
import { endpoints } from '../../../store/events/eventsApiSlice';
import { isEmpty } from 'ramda';
import { getLocale } from '../../../store/ui/uiSlice';
import { useDispatch, useSelector } from 'react-redux';

export const Context = createContext();

const steps = [
  { title: 'selectTest', component: <SelectTest /> },
  { title: 'scheduleEvent', component: <ScheduleEvent /> },
  { title: 'classroom', component: <Classroom /> },
  { title: 'proctoring', component: <Proctoring /> },
];

export const initExamSettings = {
  allowCopyPaste: false,
  allowHighlighter: false,
  allowNotepad: false,
  allowPrintScreen: false,
  allowSearching: false,
  calculatorVersion: 0,
  endDayUtc: null,
  examName: '',
  examType: 1,
  examinees: [],
  hideDistractorKeys: false,
  idTest: '',
  isPublicExam: true,
  resilienceTime: { h: 0, m: 0 },
  isResilienceTime: false,
  startDayUtc: null,
  testName: '',
  testTimeLimit: { h: 0, m: 0 },
  isTestTimeLimit: false,
  instructionTimeLimit: { h: 0, m: 0 },
  isInstructionTimeLimit: false,
};

export const initProctoringSettings = {
  aiStrickness: 1,
  monitorForbiddenObjectsEnabled: true,
  monitorIdCustomText: '',
  monitorIdEnabled: true,
  monitorPeopleEnabled: true,
  monitorPersonCustomText: '',
  monitorPersonEnabled: true,
  monitorRoomCustomText: '',
  monitorRoomEnabled: true,
  monitorScreenEnabled: true,
  monitorSoundEnabled: true,
  monitorVideoQualityEnabled: true,
  privacyCustomText: '',
  processCustomText: '',
  processEnabled: true,
  rulesCustomText: '',
};

const preparePayload = (exam, proctoring) => {
  return {
    examSettings: {
      ...exam,
      startDayUtc: exam.startDay?.toISOString(),
      endDayUtc: exam.endDay?.toISOString(),
      resilienceTimeHours: exam.isResilienceTime ? exam.resilienceTime.h : null,
      resilienceTimeMinutes: exam.isResilienceTime
        ? exam.resilienceTime.m
        : null,
      testTimeLimitHours: exam.isTestTimeLimit ? exam.testTimeLimit.h : null,
      testTimeLimitMinutes: exam.isTestTimeLimit ? exam.testTimeLimit.m : null,
      instructionTimeLimitHours: exam.isInstructionTimeLimit
        ? exam.instructionTimeLimit.h
        : null,
      instructionTimeLimitMinutes: exam.isInstructionTimeLimit
        ? exam.instructionTimeLimit.m
        : null,
    },
    proctoringSettings: proctoring,
  };
};

const PublishEventModal = () => {
  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState([]);
  const errors = {
    examName: <FormattedMessage {...messages.errorMessageExamName} />,
    startDay: <FormattedMessage {...messages.errorMessageStartDay} />,
    endDay: <FormattedMessage {...messages.errorMessageEndDay} />,
    endDayBeforeStartDay: (
      <FormattedMessage {...messages.errorMessageEndDayBeforeStartDay} />
    ),
  };
  const locale = useSelector(getLocale);

  // Stepper
  const [progress, setProgress] = useState({
    allowContinue: false,
    currentStep: 0,
  });
  const { allowContinue, currentStep } = progress;
  const { component, title } = steps[currentStep];
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;
  const isMiddleStep = currentStep === 1;
  const stepper = currentStep > 0 && <Stepper />;

  // Exam settings
  const [exam, setExam] = useState(initExamSettings);

  // Proctoring settings
  const [isWithProctoring, setIsWithProctoring] = useState(true);
  const [proctoring, setProctoring] = useState(initProctoringSettings);

  // Classroom helper state
  const [currentClassroom, setCurrentClassroom] = useState(null);

  // Proctoring helper state
  const [instructionId, setInstructionId] = useState(null);

  // Create event
  const [createEvent, result] = apiSlice[EVENT.CREATE.MUTATION]();

  // Fetch organization examinees
  const { data: allExaminees } = apiSlice[
    EXAMINEES.GET_ORGANIZATION_EXAMINEES.QUERY
  ]({
    skip: 0,
    take: 1000,
  });

  // Next or Create Event (last step)
  const handleNextOrSave = () => {
    if (!validateInput()) return;
    if (isLastStep)
      createEvent(preparePayload(exam, isWithProctoring ? proctoring : null));
    else
      setProgress({
        ...progress,
        allowContinue: false,
        currentStep: currentStep + 1,
      });
  };

  const validateInput = () => {
    if (!isMiddleStep) return true;
    let validate = true;
    const errorArray = [];
    if (isEmpty(exam.examName)) {
      errorArray.push({ name: 'examName', message: errors.examName });
      validate = false;
    }
    if (!exam.startDay) {
      errorArray.push({ name: 'startDay', message: errors.startDay });
      validate = false;
    }
    if (!exam.endDay) {
      errorArray.push({ name: 'endDay', message: errors.endDay });
      validate = false;
    }
    if (exam.startDay >= exam.endDay) {
      errorArray.push({
        name: 'endDayBeforeStartDay',
        message: errors.endDayBeforeStartDay,
      });
      validate = false;
    }
    if (validate) {
      setErrorMessages([]);
    } else {
      setErrorMessages(errorArray);
    }
    return validate;
  };

  // Go back
  const handleOnClose = isFirstStep
    ? null
    : () => setProgress({ allowContinue: true, currentStep: currentStep - 1 });

  useEffect(() => {
    const cleanup = async () => {
      // Reload events
      await dispatch(
        endpoints[EVENTS.GET.ENDPOINT].initiate(GRID.DEFAULT_DATA_STATE)
      );

      // Exit modal
      dispatch(closeModal());
    };

    if (result.isSuccess) cleanup();
  }, [dispatch, result.isSuccess]);

  return (
    <Context.Provider
      value={{
        allExaminees,
        currentClassroom,
        currentStep,
        exam,
        errorMessages,
        handleNextOrSave,
        idTest: (exam && exam?.idTest) || null,
        instructionId,
        isWithProctoring,
        locale,
        proctoring,
        progress,
        setCurrentClassroom,
        setExam,
        setInstructionId,
        setIsWithProctoring,
        setProctoring,
        setProgress,
        steps,
      }}
    >
      <ModalTemplate
        className="create-update-event-modal"
        closeBtnTitle={
          <FormattedMessage {...messages[isFirstStep ? 'cancel' : 'goBack']} />
        }
        isSaveBtnDisabled={!allowContinue}
        navigation={stepper}
        onClose={handleOnClose}
        onSave={handleNextOrSave}
        saveBtnTitle={
          <FormattedMessage
            {...messages[isLastStep ? 'finishSetup' : 'continue']}
          />
        }
        title={<FormattedMessage {...messages[title]} />}
      >
        {component}
      </ModalTemplate>
    </Context.Provider>
  );
};

export default PublishEventModal;
