import { Button } from '@progress/kendo-react-buttons';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { showModal } from '../../store/modals/modalsSlice.js';
import messages from '../../intl/messages';

const CreateButton = (type) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <Button className="primary" onClick={() => dispatch(showModal(type))}>
      {intl.formatMessage(messages.create)}
    </Button>
  );
};

export default CreateButton;
