import AddNewButton from './AddNewButton';
import messages from '../../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { Context } from './TestDetail';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import NoRecordFound from '../../Events/NoRecordFound';

const Instructions = () => {
  const intl = useIntl();
  const { deleteInstructions, instructions, isDone } = useContext(Context);

  return instructions ? (
    <ul>
      <li key={`CreateUpdateTest-Content-question-${'0001'}`}>
        <div className="display-flex">
          <div className="display-flex gap-12">
            <img alt="" src="/images/pageDescription.svg" />
            {intl.formatMessage(messages.instructions)}
          </div>
          <Button
            className="plain"
            imageUrl="/images/trashDarker.svg"
            icon="delete"
            title={intl.formatMessage(messages.delete)}
            onClick={deleteInstructions}
            disabled={isDone}
          />
        </div>
        <br />
        <div
          className="item"
          dangerouslySetInnerHTML={{
            __html: instructions,
          }}
        />
      </li>
    </ul>
  ) : (
    // No questions found
    <NoRecordFound
      className="no-record-found"
      actionButton={<AddNewButton isInstruction={true} isDone={isDone} />}
      icon="/images/noTestFound.svg"
      intlKey={'instruction'}
    />
  );
};

export default Instructions;
