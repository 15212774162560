import { USER, USERS } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';
import { API, API_SEGMENT } from '../../consts/API';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create admin user
    [USER.CREATE.ADMIN.ENDPOINT]: builder.mutation({
      query: (payload) => ({
        url: API[API_SEGMENT.ORGANIZATION].USERS_ADMIN,
        method: 'POST',
        body: payload,
      }),
    }),
    // Create user
    [USER.CREATE.ENDPOINT]: builder.mutation({
      query: (payload) => ({
        url: API[API_SEGMENT.ORGANIZATION].USERS,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Users'],
    }),
    // Delete user
    [USER.DELETE.ENDPOINT]: builder.mutation({
      query: (id) => ({
        url: `${API[API_SEGMENT.ORGANIZATION].USERS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    // Get users by organization
    [USERS.GET_BY_ORGANIZATION.ENDPOINT]: builder.query({
      query: (organizationId) =>
        `${API[API_SEGMENT.ORGANIZATION].USERS}/${organizationId}`,
      providesTags: ['Users'],
    }),
    // Update user
    [USER.UPDATE.ENDPOINT]: builder.mutation({
      query: ({ id, isActive, name, surname, roles }) => ({
        url: `${API[API_SEGMENT.ORGANIZATION].USERS}/${id}`,
        method: 'PUT',
        body: { isActive, name, surname, roles },
      }),
    }),
  }),
});

export const endpoints = usersApiSlice.endpoints;
