import { useIntl } from 'react-intl';
import messages from '../../intl/messages';

const Required = () => {
  const intl = useIntl();

  return (
    <span
      className="cursor-default"
      title={intl.formatMessage(messages.requiredField)}
    >
      *
    </span>
  );
};

export default Required;
