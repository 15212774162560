import './OrganizationDetail.scss';
import Search from '../Common/Search';
import messages from '../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { ColumnMenu } from '../Common/ColumnFilter';
import { GRID } from '../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { MODAL_TYPES } from '../Modals/ModalsRoot';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { acronym, formatDate } from '../../utils';
import { getOrganization } from '../../store/organizations/organizationsSlice';
import { showModal } from '../../store/modals/modalsSlice';
import { termsMock } from '../../mockData';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const OrganizationDetail = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Data
  const { id } = useParams();
  const { name: organizationName } = useSelector(getOrganization(id));
  const terms = Object.values(termsMock);

  // Filter
  const [search, setSearch] = useState('');
  const termsFiltered = terms.filter(({ name }) =>
    name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  // Paging
  const [page, setPage] = useState({
    skip: 0,
    take: GRID.DEFAULT_QUERY_LIMIT,
  });

  // Tabs
  const [selectedTab, setSelectedTab] = useState(0);
  const mockTabs = [
    `${intl.formatMessage(messages.tests)} <span class="label">${
      terms.length
    }</span>`,
    `${intl.formatMessage(messages.questions)} <span class="label">${
      terms.length
    }</span>`,
    `${intl.formatMessage(messages.events)} <span class="label">${
      terms.length
    }</span>`,
  ];

  return (
    <section className="organization-detail">
      <header>
        {/* Organization info */}
        <h2>
          {intl.formatMessage(messages.organizationInfo)}
          <Button
            className="float-right primary"
            onClick={() =>
              dispatch(
                showModal({
                  props: { id },
                  type: MODAL_TYPES.UPDATE_ORGANIZATION,
                })
              )
            }
            title={intl.formatMessage(messages.updateOrganization)}
          >
            {intl.formatMessage(messages.updateOrganization)}
          </Button>
        </h2>
        <div>
          <div>
            <div>{intl.formatMessage(messages.organizationName)}</div>
            {organizationName}
          </div>
          <div>
            <div>{intl.formatMessage(messages.pricingPlan)}</div>
            Premium
          </div>
          <div>
            <div>{intl.formatMessage(messages.administratorEmail)}</div>
            admin@scio.cz
          </div>
          <div>
            <div>{intl.formatMessage(messages.location)}</div>
            Melbourne, Australia
          </div>
        </div>
      </header>
      <br />
      <br />
      {/* Tabs */}
      <TabStrip
        selected={selectedTab}
        onSelect={(e) => setSelectedTab(e.selected)}
      >
        {mockTabs.map((title, i) => (
          <TabStripTab
            key={`Organizations-Organization-Detail-${title}-${i}`}
            title={<div dangerouslySetInnerHTML={{ __html: title }} />}
          >
            {/* Search by name */}
            <Search
              className="plain search k-floating-label-container"
              label={intl.formatMessage(messages.searchByName)}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            {/* Grid */}
            <Grid
              data={termsFiltered.slice(page.skip, page.take + page.skip)}
              onPageChange={(e) => setPage(e.page)}
              pageable={true}
              skip={page.skip}
              sortable={true}
              take={page.take}
              total={termsFiltered.length}
            >
              <GridColumn
                cell={({ dataItem }) => (
                  <td className="grid-cell-two-lines">
                    {dataItem.name}
                    <div>Updated Jun 12, 2022</div>
                  </td>
                )}
                columnMenu={ColumnMenu}
                field="name"
                title={intl.formatMessage(messages.name)}
                width="70%"
              />
              <GridColumn
                cell={({ dataItem }) => (
                  <td className="creator">
                    <span className="label circle">
                      {acronym(dataItem.creatorName)}
                    </span>
                    {dataItem.creatorName}
                    <div>{dataItem.creatorEmail}</div>
                  </td>
                )}
                columnMenu={ColumnMenu}
                field="creatorName"
                title={intl.formatMessage(messages.creator)}
                width="20%"
              />
              <GridColumn
                cell={({ dataItem }) => (
                  <td>{formatDate(dataItem.createdAt)}</td>
                )}
                columnMenu={ColumnMenu}
                field="createdAt"
                title={intl.formatMessage(messages.created)}
                width="10%"
              />
            </Grid>
          </TabStripTab>
        ))}
      </TabStrip>
      <br />
    </section>
  );
};

export default OrganizationDetail;
