import { isEmpty } from 'ramda';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../store/modals/modalsSlice';
import { MODAL_TYPES } from '../../../Modals/ModalsRoot';

const Identification = ({ currentProctoringExam }) => {
  const idUrl = currentProctoringExam?.urls?.idImageUrl;
  const faceUrl = currentProctoringExam?.urls?.faceImageUrl;
  const dispatch = useDispatch();

  return (
    <div className="identification">
      {!isEmpty(idUrl) && (
        <img
          alt="Id"
          src={idUrl}
          onClick={() =>
            dispatch(
              showModal({
                props: { item: { imageSrc: idUrl } },
                type: MODAL_TYPES.ZOOM_IMAGE,
              })
            )
          }
        />
      )}
      {!isEmpty(faceUrl) && (
        <img
          alt="Face"
          src={faceUrl}
          onClick={() =>
            dispatch(
              showModal({
                props: { item: { imageSrc: faceUrl } },
                type: MODAL_TYPES.ZOOM_IMAGE,
              })
            )
          }
        />
      )}
    </div>
  );
};

export default Identification;
