import messages from '../../../../intl/messages';
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from '../../../Common/ColumnFilter';
import { EXAM } from '../../../../consts/RTK';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { QUESTION_TYPE_ICONS } from '../../../../consts/DESIGN';
import { examApiSlice } from '../../../../store/exam/examApiSlice';
import { invertObj } from 'ramda';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useContext, useState } from 'react';
import {
  EXAM_EVALUATION,
  QUESTION_EVALUATION,
} from '../../../../consts/ORGANIZATOR';
import { Context } from '../Results';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { process } from '@progress/kendo-data-query';
import { capitalize } from '../../../../utils';
import KendoLocalizator from '../../../../layout/KendoLocalizator';

const QuestionsAnswers = () => {
  const intl = useIntl();
  const { idExamInstance } = useParams();
  const { currentExamInstance } = useContext(Context);
  const {
    finalViolationMessage,
    finalReview,
    maxScore,
    score,
    spentTime,
    timeLimit,
  } = currentExamInstance;
  const isExamPassed = finalReview === EXAM_EVALUATION.PASS;

  const initialFilter = {
    logic: 'and',
    filters: [],
  };

  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState([]);

  // Data
  const { data } = examApiSlice[EXAM.GET.QUERY](idExamInstance, {
    refetchOnMountOrArgChange: true,
  });
  const questions = data?.questions ?? [];

  const labels = Object.values(invertObj(QUESTION_EVALUATION));
  const labelsExtended = invertObj(QUESTION_EVALUATION);
  const labelsCapitalized = labels.map((label) => {
    return {
      itemEvaluationResultLabel: capitalize(label),
    };
  });
  const questionsExtended = questions.map((item) => ({
    ...item,
    itemEvaluationResultLabel: labelsExtended[item.itemEvaluationResult],
  }));

  const result = process(questionsExtended, {
    sort: sort,
    filter: filter,
  });

  return (
    <section className="questions-answers">
      <div className="display-flex">
        {/* Test evaluation */}
        {!finalReview && (
          <div className={`${isExamPassed && 'passed'} status`}>
            {finalViolationMessage || <br />}
            <span>
              <strong>
                {score} / {maxScore}
              </strong>
            </span>
            <ProgressBar
              className="percentage"
              value={Math.round((100 / maxScore) * score)}
            />
          </div>
        )}
        {/* Spent / Total time */}
        {!spentTime && (
          <div>
            <h4>
              {intl.formatMessage(messages.spentTime)} /{' '}
              {intl.formatMessage(messages.testTimeLimit)}
            </h4>
            {/* TODO */}
            {intl.formatMessage(messages.spentTimeText)}
            <span>
              <strong>{spentTime}</strong> / {timeLimit}
            </span>
            <ProgressBar
              className="percentage"
              value={Math.round((100 / maxScore) * score)}
            />
          </div>
        )}
      </div>
      <br />

      {/* Questions / Answers */}
      <KendoLocalizator>
        <Grid
          data={result?.data}
          filter={filter}
          onFilterChange={(e) => setFilter(e.filter)}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          {/* Name */}
          <GridColumn
            cell={({ dataItem }) => (
              <td className="display-flex">
                <img
                  alt={QUESTION_TYPE_ICONS[dataItem.itemType].alt}
                  src={QUESTION_TYPE_ICONS[dataItem.itemType].src}
                />
                {dataItem.itemName}
              </td>
            )}
            columnMenu={ColumnMenu}
            field="itemName"
            title={intl.formatMessage(messages.title)}
            filterable={false}
            width={'40%'}
          />

          {/* Status */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>
                <div
                  className={`label bulletpoint ${
                    dataItem.itemEvaluationResult ===
                    QUESTION_EVALUATION.CORRECT
                      ? 'success'
                      : 'error'
                  }`}
                >
                  {dataItem.itemEvaluationResultLabel}
                </div>
              </td>
            )}
            columnMenu={(props) =>
              ColumnMenuCheckboxFilter(props, labelsCapitalized)
            }
            field="itemEvaluationResultLabel"
            title={intl.formatMessage(messages.status)}
            width={'10%'}
          />

          {/* Score */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>
                {dataItem.score}/{dataItem.maxScore}
              </td>
            )}
            columnMenu={ColumnMenu}
            field="score"
            title={intl.formatMessage(messages.score)}
            filter={'numeric'}
            width={'5%'}
          />
        </Grid>
      </KendoLocalizator>
    </section>
  );
};

export default QuestionsAnswers;
