import ModalTemplate from './ModalTemplate';
import messages from '../../intl/messages';
import { CLASSROOM } from '../../consts/RTK';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from '@progress/kendo-react-inputs';
import { apiSlice } from '../../store/api/apiSlice';
import { closeModal } from '../../store/modals/modalsSlice';
import { getClassroom } from '../../store/classrooms/classroomsSlice';
import { omit } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { validateAndSubmit } from '../../utils';

/**
 * If classroomId provided, the modal would update
 * an existing classroom, otherwise it creates a new one
 */
const CreateUpdateClassroomModal = ({ id }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classroom = useSelector(getClassroom(id));

  const [state, setState] = useState({
    idGroup: id,
    name: classroom?.name ?? '',
    notification: null,
  });

  const { name } = state;

  const [createUpdateClassroom, result] =
    apiSlice[
      id ? CLASSROOM.UPDATE.CLASSROOM_NAME.MUTATION : CLASSROOM.CREATE.MUTATION
    ]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  const handleSave = () => {
    const preparedState = omit(['notification'], state);

    validateAndSubmit(
      intl,
      () => createUpdateClassroom(preparedState),
      preparedState,
      setState
    );
  };

  return (
    <ModalTemplate
      title={
        <FormattedMessage
          {...messages[id ? 'updateClassroom' : 'createClassroom']}
        />
      }
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={handleSave}
    >
      {/* Title */}
      <strong>{intl.formatMessage(messages.classroomTitle)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', name: e.target.value })
        }
        placeholder={intl.formatMessage(messages.addClassroomTitle)}
        type="text"
        value={name}
      />
      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default CreateUpdateClassroomModal;
