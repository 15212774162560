import EventsList from './EventsList';
import Search from '../../Common/Search';
import NoRecordFound from './NoRecordFound';
import messages from '../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { EVENT } from '../../../consts/ORGANIZATOR';
import { EVENTS } from '../../../consts/RTK';
import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { apiSlice } from '../../../store/api/apiSlice';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { showModal } from '../../../store/modals/modalsSlice';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { capitalize } from '../../../utils';

export const ScheduleEventButton = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <Button
      className="primary"
      onClick={() =>
        dispatch(showModal({ type: MODAL_TYPES.CREATE_UPDATE_EVENT }))
      }
    >
      {intl.formatMessage(messages.scheduleEvent)}
    </Button>
  );
};

const Events = () => {
  const intl = useIntl();
  const [filter, setFilter] = useState({});
  const [tabState, setTabState] = useState(0);

  const { data: eventsCount } = apiSlice[EVENTS.GET_COUNT.QUERY]({
    refetchOnMountOrArgChange: true,
  });

  // Filter
  const handleSearch = (e) => {
    setFilter({
      filter: {
        filters: [
          { field: 'name', operator: 'contains', value: e.target.value },
        ],
      },
    });
  };

  const customTab = (state) => {
    return (
      <TabStripTab
        key={`Events-${EVENT.STAGE[state === 'NONE' ? 'ACTIVE' : state]}`}
        title={intl.formatMessage(
          messages[
            `eventStage${state === 'NONE' ? 'Active' : capitalize(state)}`
          ]
        )}
      >
        {state === 'NONE' ? (
          <NoRecordFound
            actionButton={<ScheduleEventButton />}
            icon="/images/noEventFound.svg"
            intlKey="event"
          />
        ) : (
          <>
            <EventsList eventStage={EVENT.STAGE[state]} filter={filter} />
            {state === 'ACTIVE' && (
              <EventsList eventStage={EVENT.STAGE.UPCOMING} filter={filter} />
            )}
          </>
        )}
      </TabStripTab>
    );
  };

  return (
    <section>
      {/* Title + controls */}
      <h2>
        {intl.formatMessage(messages.events)}
        <ScheduleEventButton />
      </h2>

      {/* Search by name */}
      {tabState !== 0 && (
        <Search
          className="plain search k-floating-label-container"
          label={intl.formatMessage(messages.searchByName)}
          onChange={(e) => handleSearch(e)}
        />
      )}

      {/* Tabs */}
      <TabStrip
        keepTabsMounted={true}
        onSelect={(e) => setTabState(e.selected)}
        selected={tabState}
      >
        {eventsCount === 0 ? customTab('NONE') : customTab('ACTIVE')}
        {customTab('PAST')}
      </TabStrip>
    </section>
  );
};

export default Events;
