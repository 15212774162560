import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { API, API_SEGMENT } from '../../consts/API';
import { TEST, TESTS } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const testsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create test
    [TEST.CREATE.ENDPOINT]: builder.mutation({
      query: ({ name, categories }) => ({
        body: { name, categories },
        method: 'POST',
        url: API[API_SEGMENT.AUTHORING].TEST,
      }),
      invalidatesTags: ['TestsCount'],
    }),
    // Create test categories
    [TEST.CREATE_CATEGORIES.ENDPOINT]: builder.mutation({
      query: (body) => ({
        body,
        method: 'POST',
        url: API[API_SEGMENT.AUTHORING].TEST_CATEGORIES,
      }),
    }),
    // Delete test
    [TEST.DELETE.ENDPOINT]: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `${API[API_SEGMENT.AUTHORING].TEST}/?id=${id}`,
      }),
      invalidatesTags: ['Tests', 'TestsCount'],
    }),
    // Get test
    [TEST.GET.ENDPOINT]: builder.query({
      query: (id) => ({
        url: `${API[API_SEGMENT.AUTHORING].TEST}?id=${id}`,
      }),
    }),
    // Get all categories / get test categories (if id provided)
    [TEST.GET_CATEGORIES.ENDPOINT]: builder.query({
      query: (id) => ({
        url: `${API[API_SEGMENT.AUTHORING].TEST_CATEGORIES}${
          id ? '?idTest=' + id : ''
        }`,
      }),
    }),
    // Update test content
    [TEST.UPDATE.TEST_CONTENT.ENDPOINT]: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: 'PUT',
        url: API[API_SEGMENT.AUTHORING].TEST_CONTENT,
      }),
    }),
    // Update test name
    [TEST.UPDATE.TEST_NAME.ENDPOINT]: builder.mutation({
      query: ({ idTest, name }) => ({
        body: { idTest, name },
        method: 'PUT',
        url: API[API_SEGMENT.AUTHORING].TEST_NAME,
      }),
    }),
    // Update test state
    [TEST.UPDATE.TEST_STATE.ENDPOINT]: builder.mutation({
      query: ({ idTest, state }) => ({
        body: { idTest, state },
        method: 'PUT',
        url: API[API_SEGMENT.AUTHORING].TEST_STATE,
      }),
    }),
    // Get tests / get tests filtered by category
    [TESTS.GET.ENDPOINT]: builder.query({
      query: ({ dataState, categoryIds }) => ({
        url: `${API[API_SEGMENT.AUTHORING].TESTS}?${toDataSourceRequestString(
          dataState
        )}${
          categoryIds
            ? '&idCategories=' + categoryIds.join('&idCategories=')
            : ''
        }`,
      }),
      providesTags: ['Tests'],
    }),
    // Get tests count
    [TESTS.GET_COUNT.ENDPOINT]: builder.query({
      query: () => ({
        url: API[API_SEGMENT.AUTHORING].TESTS_COUNT,
      }),
      providesTags: ['TestsCount'],
    }),
    // Get tests done / get tests done filtered by category
    [TESTS.GET_DONE.ENDPOINT]: builder.query({
      query: ({ dataState, categoryIds }) => ({
        url: `${
          API[API_SEGMENT.AUTHORING].TESTS_DONE
        }?${toDataSourceRequestString(dataState)}${
          categoryIds
            ? '&idCategories=' + categoryIds.join('&idCategories=')
            : ''
        }`,
      }),
    }),
  }),
});

export const endpoints = testsApiSlice.endpoints;
