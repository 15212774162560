import './AddQuestionsModal.scss';
import LabelsCell from '../Common/LabelsCell';
import ModalTemplate from './ModalTemplate';
import Row from '../Common/SelectedGridRow.js';
import messages from '../../intl/messages';
import { ColumnMenu } from '../Common/ColumnFilter';
import { FormattedMessage, useIntl } from 'react-intl';
import { GRID } from '../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { QUESTIONS, TEST } from '../../consts/RTK';
import { addOrDel, formatDate, getIndex } from '../../utils';
import { apiSlice } from '../../store/api/apiSlice';
import { closeModal } from '../../store/modals/modalsSlice';
import { flatten, update } from 'ramda';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { QUESTION_TYPE_ICONS } from '../../consts/DESIGN';
import { Checkbox } from '@progress/kendo-react-inputs';

export const CheckboxHeaderCell = (props) => {
  const { data, selected, setSelected } = props;
  const all = data.map(({ idItem }) => idItem);

  const checkedQuestionsIndex = selected.map((questionId) => {
    return getIndex('idItem', questionId, data);
  });
  const checkedQuestionsFiltered = checkedQuestionsIndex.filter(
    (questionIndex) => questionIndex >= 0
  );
  const checked = checkedQuestionsFiltered.length === data.length;

  return (
    <span className="k-cell-inner">
      <span className="k-column-title">
        <Checkbox
          className="margin-0-checkbox"
          checked={checked}
          onChange={({ value }) => setSelected(value ? all : [])}
        />{' '}
      </span>
    </span>
  );
};

const AddQuestionsModal = ({
  content,
  currentPageId: pageId,
  idTest,
  pages,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [areQuestionsSeparated, setAreQuestionsSeparated] = useState(false);
  const [selected, setSelected] = useState(
    pages[pageId].items.map(({ idItem }) => idItem)
  );
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);

  // Fetch questions
  const { data } = apiSlice[QUESTIONS.GET_DONE.QUERY](dataState, {
    refetchOnMountOrArgChange: true,
  });
  const questions = data?.data ?? [];

  // Update test content
  const [updateTestContent, result] =
    apiSlice[TEST.UPDATE.TEST_CONTENT.MUTATION]();

  // Update questions
  const updateQuestions = () =>
    updateTestContent({
      idTest,
      content: {
        ...content,
        pages: areQuestionsSeparated
          ? flatten(
              update(
                pageId,
                selected.map((idItem) => ({
                  ...pages[pageId],
                  items: [{ idItem }],
                })),
                pages
              )
            )
          : update(
              pageId,
              {
                ...pages[pageId],
                items: selected.map((idItem) => ({ idItem })),
              },
              pages
            ),
      },
    });

  // Close modal
  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    data?.data && (
      <ModalTemplate
        className="add-questions-modal"
        title={<FormattedMessage {...messages.questions} />}
        saveBtnTitle={<FormattedMessage {...messages.save} />}
        onSave={updateQuestions}
      >
        <Grid
          data={questions}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageable={true}
          pageSize={dataState.take}
          rowRender={(row, { dataItem }) =>
            Row(row, selected.includes(dataItem.idItem))
          }
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={dataState.take}
          total={data?.total ?? 0}
        >
          {/* Checkbox */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>
                <Checkbox
                  checked={selected.includes(dataItem.idItem)}
                  onChange={() =>
                    setSelected(addOrDel(dataItem.idItem, selected))
                  }
                />
              </td>
            )}
            columnMenu={ColumnMenu}
            field="checkbox"
            headerCell={(p) => (
              <CheckboxHeaderCell
                {...p}
                data={questions}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            width="6%"
          />

          {/* Name */}
          <GridColumn
            cell={({ dataItem }) => (
              <td className="display-flex flex-align-center">
                <img
                  alt={QUESTION_TYPE_ICONS[dataItem.type].alt}
                  src={QUESTION_TYPE_ICONS[dataItem.type].src}
                />
                <div className="display-flex flex-column">
                  {dataItem.name}
                  <br />
                  <div>
                    {`${intl.formatMessage(messages.updated)}: ${
                      dataItem.dateUpdatedUtc
                        ? formatDate(dataItem.dateUpdatedUtc)
                        : ''
                    }`}
                  </div>
                </div>
              </td>
            )}
            columnMenu={ColumnMenu}
            field="name"
            title={intl.formatMessage(messages.title)}
            width="44%"
          />

          {/* Categories */}
          <GridColumn
            cell={LabelsCell}
            columnMenu={ColumnMenu}
            field="categories"
            title={intl.formatMessage(messages.category)}
            width="20%"
          />

          {/* Created */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>{formatDate(dataItem.dateCreatedUtc)}</td>
            )}
            columnMenu={ColumnMenu}
            field="dateCreatedUtc"
            title={intl.formatMessage(messages.created)}
            width="20%"
          />
        </Grid>
        <br />
        <div className="display-flex gap-12 flex-align-center">
          <Checkbox
            checked={areQuestionsSeparated}
            onChange={() => setAreQuestionsSeparated(!areQuestionsSeparated)}
          />
          {intl.formatMessage(messages.eachQuestionSeparatePage)}
        </div>
      </ModalTemplate>
    )
  );
};

export default AddQuestionsModal;
