import ExamPreview from './ExamPreview/ExamPreview';
import ResultsList from './ResultsList';
import TopBar from '../../Common/TopBar';
import styles from './Results.module.scss';
import { EVENTS, EXAM } from '../../../consts/RTK';
import { GRID } from '../../../consts/KENDO';
import { createContext, useEffect, useState } from 'react';
import { examApiSlice } from '../../../store/exam/examApiSlice';
import { useParams } from 'react-router';
import { eventsApiSlice } from '../../../store/events/eventsApiSlice';

export const Context = createContext();

const Results = () => {
  const { idExam, idExamInstance } = useParams();
  const { data: currentExam } = eventsApiSlice[EVENTS.GET_EXAM.QUERY](idExam);
  const [videoState, setVideoState] = useState('pause');
  const [videoSeek, setVideoSeek] = useState(0);
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);

  useEffect(() => {}, [currentExam]);
  const getExams = () => {
    const { data: results } = examApiSlice[EXAM.GET_LIST.QUERY](
      { idExam, queryConfig: dataState },
      {
        refetchOnMountOrArgChange: true,
      }
    );

    return results || [];
  };

  const results = getExams();
  const currentExamInstance =
    results?.data?.find(({ idExamInstance: _ }) => idExamInstance === _) || {};

  return (
    <Context.Provider
      value={{
        currentExamInstance,
        idExamInstance,
        dataState,
        results,
        setDataState,
        setVideoSeek,
        setVideoState,
        videoSeek,
        videoState,
      }}
    >
      <div className={styles['results']}>
        {/* Top bar */}
        <TopBar inputValue={currentExam?.name} />

        {/* Exam List / Preview */}
        {!idExamInstance ? <ResultsList /> : <ExamPreview />}
      </div>
    </Context.Provider>
  );
};

export default Results;
