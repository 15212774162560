import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { API, API_SEGMENT } from '../../consts/API';
import { EVENT, EVENTS, EXAM } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const eventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create event
    [EVENT.CREATE.ENDPOINT]: builder.mutation({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API[API_SEGMENT.TESTING].EVENT,
      }),
      invalidatesTags: ['EventsCount'],
    }),
    // Get events
    [EVENTS.GET.ENDPOINT]: builder.query({
      query: (queryConfig) => ({
        url: `${API[API_SEGMENT.TESTING].EVENTS}?${toDataSourceRequestString(
          queryConfig
        )}`,
      }),
    }),
    // Get events count
    [EVENTS.GET_COUNT.ENDPOINT]: builder.query({
      query: () => ({
        url: API[API_SEGMENT.TESTING].EVENTS_COUNT,
      }),
      providesTags: ['EventsCount'],
    }),
    // Get events
    [EVENTS.GET_PAST.ENDPOINT]: builder.query({
      query: (queryConfig) => ({
        url: `${
          API[API_SEGMENT.TESTING].EVENTS_PAST
        }?${toDataSourceRequestString(queryConfig)}`,
      }),
    }),
    // Get exam
    [EVENTS.GET_EXAM.ENDPOINT]: builder.query({
      query: (idExam) => ({
        url: `${API[API_SEGMENT.TESTING].EXAM}/?idExam=${idExam}`,
      }),
    }),
  }),
});

export const endpoints = eventsApiSlice.endpoints;
