import './OrganizationsList.scss';
import Search from '../Common/Search';
import messages from '../../intl/messages';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { ColumnMenu } from '../Common/ColumnFilter';
import { GRID } from '../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Link } from 'react-router-dom';
import { MODAL_TYPES } from '../Modals/ModalsRoot';
import { formatDate } from '../../utils';
import { getOrganizations } from '../../store/organizations/organizationsSlice';
import { showModal } from '../../store/modals/modalsSlice';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

const ActionsCell = ({ dataItem }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <td className="align-right">
      <Button
        onClick={() =>
          dispatch(
            showModal({
              props: { organization: dataItem },
              type: MODAL_TYPES.UPDATE_ORGANIZATION,
            })
          )
        }
        title={intl.formatMessage(messages.edit)}
      >
        <img alt="edit" src="/images/edit.svg" />
      </Button>
      <DropDownButton
        text={<img alt="Drop down" src="/images/horizontalDots.svg" />}
        items={[
          <Button
            icon={'user'}
            onClick={() =>
              dispatch(
                showModal({
                  props: { organizationId: dataItem.id },
                  type: MODAL_TYPES.CREATE_ADMIN_USER,
                })
              )
            }
          >
            {intl.formatMessage(messages.createAdminUser)}
          </Button>,
          <Button
            icon="delete"
            onClick={() =>
              dispatch(
                showModal({
                  props: { item: dataItem },
                  type: MODAL_TYPES.DELETE_ORGANIZATION,
                })
              )
            }
          >
            {intl.formatMessage(messages.delete)}
          </Button>,
        ]}
      />
    </td>
  );
};

const OrganizationsList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);

  // Filter
  const [search, setSearch] = useState('');
  const filteredOrganizations = Object.values(organizations).filter(
    ({ name }) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  // Paging
  const [page, setPage] = useState(GRID.DEFAULT_DATA_STATE);

  return (
    <section className="organizations-list">
      {/* Title + controls */}
      <h2>
        {intl.formatMessage(messages.listOfOrganizations)}
        <Button
          className="primary float-right"
          onClick={() =>
            dispatch(showModal({ type: MODAL_TYPES.CREATE_ORGANIZATION }))
          }
        >
          {intl.formatMessage(messages.create)}
        </Button>
      </h2>
      {/* Search by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByName)}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <br />
      <br />
      {/* Grid */}
      <Grid
        data={filteredOrganizations.slice(page.skip, page.take + page.skip)}
        onPageChange={(e) => setPage(e.page)}
        pageable={true}
        skip={page.skip}
        sortable={true}
        take={page.take}
        total={filteredOrganizations.length}
      >
        <GridColumn
          cell={({ dataItem }) => (
            <td className="gray-900 ff-public-sans-medium">
              <Link to={`${dataItem.id}`}>{dataItem.name}</Link>
            </td>
          )}
          columnMenu={ColumnMenu}
          field="name"
          title={intl.formatMessage(messages.organizationName)}
          width="634px"
        />
        <GridColumn
          columnMenu={ColumnMenu}
          field="userCount"
          title={intl.formatMessage(messages.users)}
          width="185px"
        />
        <GridColumn
          cell={({ dataItem }) => <td>{formatDate(dataItem.createdAt)}</td>}
          columnMenu={ColumnMenu}
          field="createdAt"
          title={intl.formatMessage(messages.created)}
          width="135px"
        />
        <GridColumn cell={ActionsCell} width="125px" />
      </Grid>
    </section>
  );
};
export default OrganizationsList;
