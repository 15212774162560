import { Input } from '@progress/kendo-react-inputs';
import { omit } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { closeModal } from '../../store/modals/modalsSlice';
import { apiSlice } from '../../store/api/apiSlice';
import { validateAndSubmit } from '../../utils';
import ModalTemplate from './ModalTemplate';
import { USER } from '../../consts/RTK';

const CreateAdminUserModal = ({ organizationId }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: '',
    name: '',
    notification: null,
    organizationId,
    surname: '',
  });

  const intl = useIntl();
  const [createAdminUser, result] = apiSlice[USER.CREATE.ADMIN.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.createAdminUser} />}
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={() =>
        validateAndSubmit(
          intl,
          () => createAdminUser(omit(['notification'], state)),
          state,
          setState
        )
      }
    >
      {/* Administrator name */}
      <strong>{intl.formatMessage(messages.administratorName)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', name: e.target.value })
        }
        placeholder={intl.formatMessage(messages.firstName)}
        type="text"
        value={state.name}
      />{' '}
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', surname: e.target.value })
        }
        placeholder={intl.formatMessage(messages.lastName)}
        type="text"
        value={state.surname}
      />
      <br />
      <br />
      {/* Administrator email */}
      <strong>{intl.formatMessage(messages.administratorEmail)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', email: e.target.value })
        }
        placeholder={intl.formatMessage(messages.email)}
        type="text"
        value={state.email}
      />
      <br />
      <br />
      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default CreateAdminUserModal;
