import CreateButton from '../../Common/CreateButton.js';
import DropDownButton from '../../Common/DropDownButton.js';
import LabelsCell from '../../Common/LabelsCell.js';
import NoRecordFound from '../Events/NoRecordFound.js';
import ROUTES from '../../../consts/ROUTES.js';
import Row from '../../Common/SelectedGridRow.js';
import Search from '../../Common/Search';
import ItemState from '../../Common/ItemState';
import messages from '../../../intl/messages';
import {
  ColumnMenu,
  ColumnMenuMultiselect,
  ColumnMenuCheckboxFilter,
} from '../../Common/ColumnFilter.js';
import { Context } from '../../Modals/PublishEventModal/PublishEventModal.js';
import { GRID } from '../../../consts/KENDO.js';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { MODAL_TYPES } from '../../Modals/ModalsRoot.js';
import { TEST, TESTS } from '../../../consts/RTK.js';
import { apiSlice } from '../../../store/api/apiSlice.js';
import { capitalize, formatDate } from '../../../utils.js';
import { showModal } from '../../../store/modals/modalsSlice.js';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { invertObj, omit } from 'ramda';
import { TEST_STATE } from '../../../consts/ORGANIZATOR';
import {
  handleCustomKendoFilter,
  createNameFilterDescriptor,
} from '../../../filters';
import KendoLocalizator from '../../../layout/KendoLocalizator.js';

const ActionsCell = ({ dataItem }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () =>
    dispatch(
      showModal({
        props: { item: dataItem },
        type: MODAL_TYPES.DELETE_TEST,
      })
    );

  const handleUpdate = () =>
    navigate(`${ROUTES.DASHBOARD.TESTS.PATH}/${dataItem.idTest}`);

  return (
    <td>
      <DropDownButton onDelete={handleDelete} onUpdate={handleUpdate} />
    </td>
  );
};

const handleSearch = (e, dataState, setDataState) => {
  const nameFilterDescriptor = createNameFilterDescriptor(e.target.value);

  handleCustomKendoFilter(
    dataState,
    setDataState,
    nameFilterDescriptor,
    GRID.QUESTIONS_TESTS_DATA_STATE
  );
};

const labels = Object.values(invertObj(omit(['DEACTIVATED'], TEST_STATE)));
const labelsCapitalized = labels.map((label) => {
  return { state: capitalize(label) };
});

const TestsList = ({ onTestSelected }) => {
  const { idTest } = useContext(Context) ?? { idTest: null };
  const intl = useIntl();
  const navigate = useNavigate();
  const [dataState, setDataState] = useState(GRID.QUESTIONS_TESTS_DATA_STATE);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const categoryIds =
    selectedCategories.length > 0
      ? selectedCategories.map((test) => test.idCategory)
      : undefined;

  const { data } = apiSlice[TESTS[onTestSelected ? 'GET_DONE' : 'GET'].QUERY](
    { dataState, categoryIds },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: testsCount } = apiSlice[TESTS.GET_COUNT.QUERY]({
    refetchOnMountOrArgChange: true,
  });
  const { data: allCategories } = apiSlice[TEST.GET_CATEGORIES.QUERY](null, {
    refetchOnMountOrArgChange: true,
  });
  const tests = data?.data ?? [];

  const handleOnTestSelected = (dataItem) =>
    !onTestSelected
      ? // Default - Go to test detail (CreateUpdateTest)
        navigate(`${ROUTES.DASHBOARD.TESTS.PATH}/${dataItem.idTest}`)
      : // Handle test selection explicitly
        onTestSelected(dataItem);

  // can't use Memo to make it unique every time
  const key = +new Date();

  return testsCount === 0 ? (
    // No test found
    <NoRecordFound
      actionButton={<CreateButton type={MODAL_TYPES.CREATE_UPDATE_TEST} />}
      icon="/images/noTestFound.svg"
      intlKey="test"
    />
  ) : (
    // Tests list
    <>
      {/* Search test by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByName)}
        onChange={(e) => handleSearch(e, dataState, setDataState)}
      />
      <KendoLocalizator>
        <Grid
          key={`TestsListGrid=${key}`}
          data={tests}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageSize={dataState.take}
          pageable={true}
          rowRender={(row, { dataItem }) =>
            Row(row, dataItem.idTest === idTest)
          }
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={dataState.take}
          total={data?.total ?? 0}
        >
          {/* Name */}
          <GridColumn
            cell={({ dataItem }) => (
              <td
                className="cursor-pointer grid-cell-two-lines"
                onClick={() => handleOnTestSelected(dataItem)}
              >
                {dataItem.name}
                <div>
                  {`${intl.formatMessage(messages.updated)}: ${
                    dataItem.dateUpdatedUtc
                      ? formatDate(dataItem.dateUpdatedUtc)
                      : ''
                  }`}
                </div>
              </td>
            )}
            field="name"
            filterable={false}
            sortable={false}
            title={intl.formatMessage(messages.title)}
            width={!!onTestSelected ? '15%' : '25%'}
          />

          {/* Questions */}
          <GridColumn
            cell={({ dataItem }) => <td>{dataItem?.totalQuestions ?? 0}</td>}
            columnMenu={ColumnMenu}
            field="totalQuestions"
            filter={'numeric'}
            title={intl.formatMessage(messages.questions)}
            width="10%"
          />

          {/* Status */}
          {!onTestSelected && (
            <GridColumn
              cell={({ dataItem }) => (
                <td>
                  <ItemState state={dataItem.state} />
                </td>
              )}
              columnMenu={(props) =>
                ColumnMenuCheckboxFilter(props, labelsCapitalized)
              }
              field="state"
              title={intl.formatMessage(messages.status)}
              width="10%"
            />
          )}

          {/* Categories */}
          <GridColumn
            cell={LabelsCell}
            columnMenu={() =>
              ColumnMenuMultiselect(
                allCategories,
                selectedCategories,
                setSelectedCategories
              )
            }
            filterable={false}
            sortable={false}
            title={intl.formatMessage(messages.category)}
            width="15%"
          />

          {/* Created at */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>{formatDate(dataItem.dateCreatedUtc)}</td>
            )}
            columnMenu={ColumnMenu}
            field="dateCreatedUtc"
            filter={'date'}
            title={intl.formatMessage(messages.created)}
            width="10%"
          />

          {/* Actions */}
          {!onTestSelected && <GridColumn cell={ActionsCell} width="5%" />}
        </Grid>
      </KendoLocalizator>
    </>
  );
};

export default TestsList;
