import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../intl/messages';
import { Context } from '../../Settings/Results/Results';
import VideoControlBar from './VideoControlBar';

const PIP_PLAYBACK_TIME = 'dualVideoOnLeavePipPlaybackTime';
const VIDEO_SEEK_FALLBACK = 2; // seconds
export const STREAM_TYPES = {
  SCREEN: 'screen',
  WEBCAM: 'webcam',
  ROOM: 'room',
};

// play() / pause() - Fix for DOMException: The play() request was interrupted - https://goo.gl/LdLk22
const play = (videoEl) => {
  const lsItem = 'dontInterruptVideo' + videoEl.id;

  localStorage.setItem(lsItem, 'true');
  videoEl.play().then(() => localStorage.removeItem(lsItem));
};

const pause = (videoEl) => {
  const lsItem = 'dontInterruptVideo' + videoEl.id;
  if (!localStorage.getItem(lsItem)) videoEl.pause();
};

const VideoBox = ({ activeTab, currentProctoringExam }) => {
  const { videoState, setVideoState, videoSeek } = useContext(Context);
  const { webcamVideoUrl, screenVideoUrl, roomVideoUrl } =
    currentProctoringExam?.urls || {};
  const { exam } = currentProctoringExam || {};
  const videoOriginal = useRef();
  const videoDual = useRef();
  const [isDualVideo, setIsDualVideo] = useState(false);
  const [isVideoControlBar, setIsVideoControlBar] = useState(false);

  const getSrc = useCallback(() => {
    switch (activeTab) {
      case STREAM_TYPES.SCREEN:
        return screenVideoUrl;
      case STREAM_TYPES.ROOM:
        return roomVideoUrl;
      default:
        return webcamVideoUrl;
    }
  }, [roomVideoUrl, screenVideoUrl, activeTab, webcamVideoUrl]);

  const [videoUrl, setVideoUrl] = useState(getSrc());
  const [prevVideoUrl, setPrevVideoUrl] = useState(getSrc());

  const [checkpointOffsets, setCheckpointOffsets] = useState([]);

  useEffect(() => {
    localStorage.removeItem(PIP_PLAYBACK_TIME);
  }, []);

  useEffect(() => {
    videoOriginal.current.onerror = (e) => console.error('Video Error: ', e);
    videoOriginal.current.onseeked = () => play(videoOriginal.current);
    videoOriginal.current.onpause = () => setVideoState('pause');
    videoOriginal.current.onplay = () => setVideoState('play');
    videoOriginal.current.onenterpictureinpicture = () => {
      setIsDualVideo(true);
      videoDual.current.playbackRate = videoOriginal.current.playbackRate;
    };
    videoOriginal.current.onleavepictureinpicture = (e) => {
      localStorage.setItem(PIP_PLAYBACK_TIME, e.target.currentTime);
      setIsDualVideo(false);
    };

    videoDual.current.onseeked = (e) => {
      videoOriginal.current.currentTime = e.target.currentTime;
      play(videoOriginal.current);
      play(videoDual.current);
    };
  }, [isDualVideo, setVideoState]);

  useEffect(() => {
    const seekInSecs = Math.floor(videoSeek / 1000);
    const seekPoint =
      seekInSecs - VIDEO_SEEK_FALLBACK < 0
        ? 0
        : seekInSecs - VIDEO_SEEK_FALLBACK;

    videoOriginal.current.currentTime = seekPoint || 0;
    videoDual.current.currentTime = seekPoint || 0;
  }, [videoSeek]);

  useEffect(() => {
    if (videoState === 'play') {
      play(videoOriginal.current);
      play(videoDual.current);
    } else {
      pause(videoOriginal.current);
      pause(videoDual.current);
    }
  }, [videoState]);

  useEffect(() => {
    setVideoUrl(getSrc());

    if (isDualVideo) {
      videoDual.current.currentTime = videoOriginal.current.currentTime;
      pause(videoOriginal.current);
      pause(videoDual.current);
    } else {
      setPrevVideoUrl(getSrc());
      setTimeout(() => {
        if (videoOriginal?.current)
          videoOriginal.current.currentTime =
            localStorage.getItem(PIP_PLAYBACK_TIME) || 0;
      }, 250);
    }
  }, [getSrc, isDualVideo, activeTab, videoUrl]);

  useEffect(() => {
    setVideoState('pause');
    setIsVideoControlBar(false);
    setTimeout(() => setIsVideoControlBar(true), 500);
  }, [setVideoState, activeTab]);

  useEffect(() => {
    if (exam) {
      const offsets = {
        id: {
          offset:
            Date.parse(exam.idCheckpointStartedAt) -
            Date.parse(exam.streamStartedAt),
          tooltip: <FormattedMessage {...messages.idCheckpointStartedAt} />,
        },
        face: {
          offset:
            Date.parse(exam.faceCheckpointStartedAt) -
            Date.parse(exam.streamStartedAt),
          tooltip: <FormattedMessage {...messages.faceCheckpointStartedAt} />,
        },
        room: {
          offset:
            Date.parse(exam.roomCheckpointStartedAt) -
            Date.parse(exam.streamStartedAt),
          tooltip: <FormattedMessage {...messages.roomCheckpointStartedAt} />,
        },
        exam: {
          offset:
            Date.parse(exam.examCheckpointStartedAt) -
            Date.parse(exam.streamStartedAt),
          tooltip: <FormattedMessage {...messages.examCheckpointStartedAt} />,
        },
      };

      if (
        activeTab === STREAM_TYPES.WEBCAM ||
        activeTab === STREAM_TYPES.SCREEN
      ) {
        setCheckpointOffsets([
          offsets.id,
          offsets.face,
          offsets.room,
          offsets.exam,
        ]);
      } else if (activeTab === STREAM_TYPES.ROOM)
        setCheckpointOffsets([offsets.id, offsets.face, offsets.room]);
    }
  }, [exam, activeTab]);

  useEffect(() => {
    const playPauseHandler = (event) => {
      const nodeName = event?.target?.nodeName;

      if (
        event.type === 'click' ||
        (event.code === 'Space' && nodeName === 'BODY')
      ) {
        event.preventDefault();
        setVideoState(videoOriginal.current.paused ? 'play' : 'pause');
      }
    };

    const videoContainer = document.getElementById('videoContainer');
    if (videoContainer) {
      videoContainer.onfullscreenchange = () => {
        if (document.fullscreenElement) {
          videoContainer.classList.add('fullscreen');
        } else {
          videoContainer.classList.remove('fullscreen');
        }
      };
    }

    window.addEventListener('keydown', playPauseHandler);

    let videoOriginalCopy, videoDualCopy;
    if (videoOriginal?.current) {
      videoOriginalCopy = videoOriginal.current;
      videoOriginal.current.addEventListener('click', playPauseHandler);
    }
    if (videoDual?.current) {
      videoOriginalCopy = videoDual.current;
      videoDual.current.addEventListener('click', playPauseHandler);
    }
    return () => {
      window.removeEventListener('keydown', playPauseHandler);

      if (videoOriginalCopy)
        videoOriginalCopy.removeEventListener('click', playPauseHandler);
      if (videoDualCopy)
        videoDualCopy.removeEventListener('click', playPauseHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="video-box">
      {/* video dual gets displayed if video original switches to PiP */}
      <video
        id="exam-playback-video-dual"
        disablePictureInPicture
        className={`video dual display-${isDualVideo ? 'block' : 'none'}`}
        src={videoUrl}
        controls={false}
        muted
        ref={videoDual}
      />
      <video
        id="exam-playback-video"
        className={`video original display-${!isDualVideo ? 'block' : 'none'}`}
        src={prevVideoUrl}
        controls={false}
        ref={videoOriginal}
      />
      <br />
      {isVideoControlBar && !isDualVideo && (
        <VideoControlBar
          video={videoOriginal.current}
          key={videoUrl}
          checkpointOffsets={
            activeTab !== STREAM_TYPES.SCREEN ? checkpointOffsets : []
          }
        />
      )}
      {isVideoControlBar && isDualVideo && (
        <VideoControlBar
          video={videoDual.current}
          originalVideo={videoOriginal.current}
          key={videoUrl + 'dual'}
          checkpointOffsets={
            activeTab !== STREAM_TYPES.SCREEN ? checkpointOffsets : []
          }
        />
      )}
    </div>
  );
};

export default VideoBox;
