import Header from '../components/Common/Header';
import './Public.scss';

const Public = (props) => {
  return (
    <div className="public">
      <Header />
      {props.children}
    </div>
  );
};

export default Public;
