import TestsList from '../../Dashboard/Tests/TestsList';
import { Context } from './PublishEventModal';
import { useContext } from 'react';

const SelectTest = () => {
  const { exam, handleNextOrSave, progress, setProgress, setExam } =
    useContext(Context);

  return (
    <TestsList
      onTestSelected={({ idTest, name: testName }) => {
        setExam({ ...exam, idTest, testName });
        setProgress({ ...progress, allowContinue: true });
        handleNextOrSave();
      }}
    />
  );
};

export default SelectTest;
