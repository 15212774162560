import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { closeModal } from '../../store/modals/modalsSlice';

const SaveCloseModalTemplate = (props) => {
  const dispatch = useDispatch();
  const closeDialog = () => dispatch(closeModal());

  const {
    className,
    children,
    closeBtnTitle,
    disableCloseDialog,
    isCloseBtnDisabled,
    isCloseBtnVisible = true,
    isSaveBtnDisabled,
    isSaveBtnVisible = true,
    navigation = null,
    noControls,
    onClose,
    onSave,
    saveBtnTitle,
    title,
  } = props;

  return (
    <Dialog
      className={`${!!navigation && 'with-navigation'} ${className}`}
      onClose={!disableCloseDialog ? closeDialog : () => {}}
      title={title}
    >
      {navigation}
      {children}
      {!noControls && (
        <DialogActionsBar>
          {isCloseBtnVisible && (
            <Button
              onClick={onClose || closeDialog}
              disabled={isCloseBtnDisabled}
            >
              {closeBtnTitle || <FormattedMessage {...messages.cancel} />}
            </Button>
          )}
          {isSaveBtnVisible && (
            <Button
              className="primary"
              onClick={onSave}
              disabled={isSaveBtnDisabled}
            >
              {saveBtnTitle || <FormattedMessage {...messages.save} />}
            </Button>
          )}
        </DialogActionsBar>
      )}
    </Dialog>
  );
};

export default SaveCloseModalTemplate;
