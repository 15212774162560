import messages from '../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import {
  CONTROLS,
  CONTROLS_BORN_DIGITAL,
  LANGUAGES,
  TEXT_CUSTOM_TYPES,
} from '../../../consts/PROCTORING';
import { Context } from './PublishEventModal';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { EDITOR_EXTENDED } from '../../../consts/EDITOR';
import { Editor } from '@tinymce/tinymce-react';
import { arrToObjIdAsKey } from '../../../utils';
import { getProctoringTexts } from '../../../store/proctoring/proctoringSlice';
import { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { invertObj, isEmpty } from 'ramda';

const ProctoringTexts = ({ language, setLanguage }) => {
  const { instructionId, proctoring, setInstructionId, setProctoring } =
    useContext(Context);
  const intl = useIntl();
  const editorRef = useRef();
  const texts = useSelector(getProctoringTexts(language));
  const content = !isEmpty(proctoring[instructionId + 'CustomText'] ?? '')
    ? proctoring[instructionId + 'CustomText']
    : texts
    ? arrToObjIdAsKey(texts)?.[
        CONTROLS_BORN_DIGITAL[invertObj(CONTROLS)?.[instructionId]] ??
          instructionId
      ]?.text ?? null
    : null;

  const saveInstruction = () => {
    const textType = TEXT_CUSTOM_TYPES[instructionId.toUpperCase()];

    setProctoring({
      ...proctoring,
      [textType ? textType : instructionId + 'CustomText']:
        editorRef.current?.editor.getContent(),
    });
    setInstructionId(null);
  };

  return (
    content && (
      <div className={`proctoring instruction`}>
        {/* TODO SC-964 read and understand how the whole message construction works,
        particulary the one below with instructionId in conjunction with CustomText suffix */}
        <h3>{intl.formatMessage(messages[instructionId + 'CustomText'])}</h3>

        {/* Language selector */}
        <DropDownList
          data={Object.values(LANGUAGES)}
          onChange={(e) => setLanguage(e.value)}
          value={language}
        />

        {/* Editor */}
        <Editor
          apiKey={EDITOR_EXTENDED.API_KEY}
          init={EDITOR_EXTENDED.INIT}
          ref={editorRef}
          initialValue={content}
        />

        {/* Controls */}
        <div className="controls">
          <Button className="primary" onClick={saveInstruction}>
            {intl.formatMessage(messages.save)}
          </Button>
          <Button onClick={() => setInstructionId(null)}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </div>
      </div>
    )
  );
};

export default ProctoringTexts;
