import Classroom, { InviteExamineeButton } from './Classroom';
import ClassroomsList from './ClassroomList';
import CreateButton from '../../Common/CreateButton';
import StudentsList from './StudentsList';
import messages from '../../../intl/messages';
import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

const StudentManagement = () => {
  const { idGroup } = useParams();
  const [tabState, setTabState] = useState(0);
  const intl = useIntl();

  return idGroup ? (
    <Classroom />
  ) : (
    <section>
      {/* Title + controls */}
      <h2>
        {intl.formatMessage(messages.studentManagement)}
        {!tabState ? (
          <CreateButton type={MODAL_TYPES.CREATE_UPDATE_CLASSROOM} />
        ) : (
          <InviteExamineeButton />
        )}
      </h2>
      <TabStrip
        keepTabsMounted={false}
        onSelect={(e) => setTabState(e.selected)}
        selected={tabState}
      >
        <TabStripTab title={intl.formatMessage(messages.classrooms)}>
          <ClassroomsList />
        </TabStripTab>
        <TabStripTab title={intl.formatMessage(messages.students)}>
          <StudentsList />
        </TabStripTab>
      </TabStrip>
    </section>
  );
};
export default StudentManagement;
