import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { curry } from 'ramda';
import { ORGANIZATION, ORGANIZATIONS } from '../../consts/RTK';
import { arrToObjIdAsKey } from '../../utils';
import { endpoints } from './organizationsApiSlice';

const initialState = {};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        endpoints[ORGANIZATION.DELETE.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          Reflect.deleteProperty(state, meta.arg.originalArgs);
        }
      )
      .addMatcher(
        endpoints[ORGANIZATIONS.GET.ENDPOINT].matchFulfilled,
        (state, { payload }) => arrToObjIdAsKey(payload)
      )
      .addMatcher(
        isAnyOf(
          endpoints[ORGANIZATION.CREATE.ENDPOINT].matchFulfilled,
          endpoints[ORGANIZATION.UPDATE.ENDPOINT].matchFulfilled
        ),
        (state, { payload }) => ({
          ...state,
          [payload.id]: { userCount: 1, ...payload },
        })
      );
  },
});

export default organizationsSlice.reducer;

export const getOrganization = curry(
  (organizationId, state) => state.organizations?.[organizationId] ?? {}
);
export const getOrganizationName = curry(
  (organizationId, state) => state.organizations?.[organizationId]?.name
);
export const getOrganizations = (state) => state.organizations;
