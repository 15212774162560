import Menu from './Menu';
import Footer from './Footer';
import SwitchOrganization from './SwitchOrganization';
import './LeftBarTemplate.scss';

const LeftBarTemplate = ({ content }) => {
  return (
    <div className="left-bar-template">
      <div>
        <SwitchOrganization />
        <Menu />
        <Footer />
      </div>

      {/* Content */}
      {content}
    </div>
  );
};

export default LeftBarTemplate;
