export const CALCULATOR = [
  { id: 0, messageKey: 'calculatorVersionNone' },
  { id: 1, messageKey: 'calculatorVersionBasic' },
  { id: 2, messageKey: 'calculatorVersionScientific' },
];

export const EMPTY_TEST_PAGE = {
  pageSettings: {
    pageAlignment: 1,
  },
  instructions: {},
  items: [],
};

export const EVENT = {
  TYPE: [
    { id: 1, messageKey: 'testTypeStandard' },
    { id: 2, messageKey: 'testTypeForwardOnly' },
  ],
  STAGE: { ACTIVE: 'ACTIVE', PAST: 'PAST', UPCOMING: 'UPCOMING' },
  EXAM: {
    RULES: {
      ALLOW_COPY_PASTE: 'allowCopyPaste',
      ALLOW_PRINT_SCREEN: 'allowPrintScreen',
      ALLOW_SEARCHING: 'allowSearching',
      HIDE_DISTRACTOR_KEYS: 'hideDistractorKeys',
    },
    TOOLS: {
      ALLOW_NOTEPAD: 'allowNotepad',
      ALLOW_HIGHLIGHTER: 'allowHighlighter',
    },
  },
};

export const EXAM_EVALUATION = { PASS: 1, FAIL: 0 };

export const EXAM_STATE = { 1: 'pending', 2: 'running', 3: 'finished' };

export const PAGE_ALIGNMENT = {
  HORIZONTAL: 2,
  VERTICAL: 1,
};

export const QUESTION_EVALUATION = { CORRECT: 1, INCORRECT: 2 };

export const QUESTION_TYPE = {
  1: 'multipleChoice',
  2: 'dropdown',
  3: 'openText',
  4: 'text',
};

export const QUESTION_STATE = { DEACTIVATED: 0, DRAFT: 1, DONE: 2 };
export const TEST_STATE = { DEACTIVATED: 0, DRAFT: 1, DONE: 2 };

export const EXAMINEE_STATE = {
  DEFAULT: 0,
  PENDING: 1,
  ACCEPTED: 2,
  DECLINED: 3,
  DELETED: 4,
};

export const EXAM_INSTANCE_STATE = {
  ASSIGNED: 0,
  RUNNING: 1,
  FINISHED: 4,
  EVALUATED: 5,
  CREATED: 6,
};

export const CONTENT_WRAPPER = (content = '') => `<Root>${content}</Root>`;
