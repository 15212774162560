import CheckEmail from './CheckEmail';
import ForgottenPassword from './ForgottenPassword';
import LoginForm from './LoginForm';
import ROUTES from '../../consts/ROUTES';
import { useLocation } from 'react-router';
import { useState } from 'react';

const Login = () => {
  const [resetPassEmail, setResetPassEmail] = useState('');
  const { pathname } = useLocation();

  return (
    <div className="content">
      {/* LOGIN FORM */}
      {pathname === ROUTES.PUBLIC.LOGIN.PATH && <LoginForm />}

      {/* FORGOTTEN PASSWORD */}
      {pathname === ROUTES.PUBLIC.FORGOTTEN_PASSWORD.PATH && (
        <ForgottenPassword
          resetPassEmail={resetPassEmail}
          setResetPassEmail={setResetPassEmail}
        />
      )}

      {/* CHECK EMAIL */}
      {pathname === ROUTES.PUBLIC.CHECK_EMAIL.PATH && (
        <CheckEmail resetPassEmail={resetPassEmail} />
      )}
    </div>
  );
};

export default Login;
