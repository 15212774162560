import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';

const Faq = () => {
  return (
    <div className="content">
      <FormattedMessage {...messages.faqText} />
    </div>
  );
};

export default Faq;
