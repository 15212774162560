import CreateButton from '../../Common/CreateButton';
import DropDownButton from '../../Common/DropDownButton';
import NoRecordFound, { isNoRecordFound } from '../Events/NoRecordFound';
import Search from '../../Common/Search';
import messages from '../../../intl/messages';
import { CLASSROOMS } from '../../../consts/RTK';
import { GRID } from '../../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Link } from 'react-router-dom';
import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { apiSlice } from '../../../store/api/apiSlice';
import { showModal } from '../../../store/modals/modalsSlice';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useState } from 'react';

const ActionsCell = ({ dataItem }) => {
  const dispatch = useDispatch();

  const handleDelete = () =>
    dispatch(
      showModal({
        props: { item: dataItem },
        type: MODAL_TYPES.DELETE_CLASSROOM,
      })
    );

  const handleUpdate = () =>
    dispatch(
      showModal({
        props: { id: dataItem.idGroup },
        type: MODAL_TYPES.CREATE_UPDATE_CLASSROOM,
      })
    );

  return (
    <td>
      <DropDownButton onDelete={handleDelete} onUpdate={handleUpdate} />
    </td>
  );
};

const ClassroomList = () => {
  const intl = useIntl();
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);

  const { data } = apiSlice[CLASSROOMS.GET.QUERY](dataState, {
    refetchOnMountOrArgChange: true,
  });
  const classrooms = data?.data ?? [];

  // Search
  const handleSearch = (e, dataState, setDataState) => {
    setDataState({
      ...GRID.DEFAULT_DATA_STATE,
      filter: {
        ...dataState.filter,
        filters: [
          { field: 'name', operator: 'contains', value: e.target.value },
        ],
      },
    });
  };

  return (
    <div>
      {/* Create new classroom button or Classrooms list */}
      {isNoRecordFound(classrooms, dataState) ? (
        <NoRecordFound
          actionButton={
            <CreateButton type={MODAL_TYPES.CREATE_UPDATE_CLASSROOM} />
          }
          icon="/images/noClassroomFound.svg"
          intlKey="classroom"
        />
      ) : (
        <div>
          <br />
          <br />
          {/* Search test by name */}
          <Search
            className="plain search k-floating-label-container"
            label={intl.formatMessage(messages.searchByName)}
            onChange={(e) => handleSearch(e, dataState, setDataState)}
            value={dataState?.filter?.filters?.[0]?.value ?? ''}
          />
          <Grid
            data={classrooms}
            filter={dataState.filter}
            onDataStateChange={(e) => setDataState(e.dataState)}
            pageSize={dataState.take}
            pageable={true}
            skip={dataState.skip}
            sort={dataState.sort}
            sortable={true}
            take={dataState.take}
            total={data?.total ?? 0}
            className="classrooms-list"
          >
            <GridColumn
              cell={({ dataItem }) => (
                <td>
                  <Link to={`${dataItem.idGroup}`}>
                    {dataItem.name}
                    <br />
                    {`${intl.formatMessage(messages.students)}: ${
                      dataItem.membersCount || 0
                    }`}
                  </Link>
                </td>
              )}
              field="name"
              title={intl.formatMessage(messages.title)}
              width="500px"
            />
            <GridColumn
              cell={ActionsCell}
              title={intl.formatMessage(messages.actions)}
              width="60px"
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ClassroomList;
