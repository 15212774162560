export const CONTROLS = {
  ID: 'monitorId',
  PERSON: 'monitorPerson',
  PROCESS: 'process',
  ROOM: 'monitorRoom',
};

export const LANGUAGES = {
  CZ: 'cz',
  EN: 'en',
  SK: 'sk',
  UA: 'ua',
};

export const TEXT_CUSTOM_TYPES = {
  GDPR: 'privacyCustomText',
  RULES: 'rulesCustomText',
};

export const TEXT_TYPES_BD = {
  DESKTOP: 'desktop',
  FACE: 'face',
  GDPR: 'gdpr',
  IDENTIFICATION: 'identification',
  ROOM: 'room',
  RULES: 'rules',
};

// To sync apis
export const CONTROLS_BORN_DIGITAL = {
  GDPR: TEXT_CUSTOM_TYPES.GDPR,
  ID: TEXT_TYPES_BD.IDENTIFICATION,
  PERSON: TEXT_TYPES_BD.FACE,
  PROCESS: TEXT_TYPES_BD.DESKTOP,
  ROOM: TEXT_TYPES_BD.ROOM,
  RULES: TEXT_CUSTOM_TYPES.RULES,
};

export const PREVIEW = {
  TAB: {
    CAMERA: 'camera',
    IDENTIFICATION: 'identification',
    ROOM: 'room',
    SCREEN: 'screen',
  },
};

export const RULES = {
  FORBIDDEN_OBJECTS: 'monitorForbiddenObjects',
  PEOPLE: 'monitorPeople',
  SCREEN: 'monitorScreen',
  SOUND: 'monitorSound',
  VIDEO_QUALITY: 'monitorVideoQuality',
};

export const SENSITIVITY = {
  LOW: 0,
  MODERATE: 1,
  STRICT: 2,
};

export const FINAL_REVIEW = {
  FAIL: 2,
  PASS: 1,
};

export const EXAM_REVIEW = {
  PASS: 'PASS',
  FAIL: 'FAIL',
};
