import './Footer.scss';
import Svg from '../Common/Svg';
import messages from '../../intl/messages';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { FormattedMessage } from 'react-intl';
import { LOCALE } from '../../consts/INTL';
import { getLocale, setLocale } from '../../store/ui/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../consts/ROUTES';

const Footer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = useSelector(getLocale);

  return (
    <div className="footer" {...props}>
      <div>
        © {new Date().getFullYear()} Sciolink.{' '}
        <FormattedMessage {...messages.allRightsReserved} />
      </div>
      <ul>
        <li className="language">
          <img alt="language" src="/images/globe.svg" />
          <FormattedMessage {...messages[locale]} />
          <DropDownButton
            text={<Svg name="chevronDown" />}
            items={[
              <Button onClick={() => dispatch(setLocale(LOCALE.EN))}>
                <FormattedMessage {...messages.en} />
              </Button>,
              <Button onClick={() => dispatch(setLocale(LOCALE.CS))}>
                <FormattedMessage {...messages.cs} />
              </Button>,
            ]}
          />
        </li>
        <li onClick={() => navigate(ROUTES.PUBLIC.TERMS.PATH)}>
          <FormattedMessage {...messages.terms} />
        </li>
        <li onClick={() => navigate(ROUTES.PUBLIC.PRIVACY.PATH)}>
          <FormattedMessage {...messages.privacy} />
        </li>
        <li onClick={() => navigate(ROUTES.PUBLIC.COOKIES.PATH)}>
          <FormattedMessage {...messages.cookies} />
        </li>
      </ul>
    </div>
  );
};

export default Footer;
