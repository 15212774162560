import messages from '../../../intl/messages';
import styles from './OrganizationSettings.module.scss';
import { Button } from '@progress/kendo-react-buttons';
import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { ORGANIZATION, PROCTORING } from '../../../consts/RTK';
import { apiSlice } from '../../../store/api/apiSlice';
import { getProctoringTexts } from '../../../store/proctoring/proctoringSlice';
import { showModal } from '../../../store/modals/modalsSlice';
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from 'string-strip-html';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import {
  CONTROLS,
  CONTROLS_BORN_DIGITAL,
  LANGUAGES,
  TEXT_TYPES_BD,
} from '../../../consts/PROCTORING';
import { getUserId } from '../../../store/auth/authSlice';
import SaveCancelInput from '../../SaveCancelInput';

const OrganizationSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [proctoringLang] = useState(LANGUAGES.EN);
  const textsArr = useSelector(getProctoringTexts(proctoringLang)) || [];
  const texts = {};

  for (const { id, text } of textsArr) {
    texts[id] = text;
  }
  // Get proctoring texts from BE
  apiSlice[PROCTORING.GET.TEXTS.QUERY](proctoringLang, {
    refetchOnMountOrArgChange: true,
  });

  // Get organization from BE
  const userId = useSelector(getUserId);
  const { data: organization } = apiSlice[ORGANIZATION.GET.QUERY](userId);
  const [updateOrganization] = apiSlice[ORGANIZATION.UPDATE.MUTATION]();
  const updateOrganizationPayload = {
    organizationName: organization?.name || '',
    address: organization?.address || '',
    city: organization?.city || '',
    postalCode: organization?.postalCode || '',
  };

  const handleUpdateOrganization = (value, key) => {
    updateOrganization({
      id: organization.id,
      body: { ...updateOrganizationPayload, [key]: value },
    });
  };

  return (
    organization && (
      <section className={styles['organization-settings']}>
        {/* Organization Setting */}
        <h2>{intl.formatMessage(messages.organizationSettings)}</h2>

        <h3>{intl.formatMessage(messages.companySettings)}</h3>

        {/* Company */}
        <div className="display-flex">
          <div>
            {intl.formatMessage(messages.company)}
            <p> {intl.formatMessage(messages.companyProfileName)}</p>
          </div>
          <SaveCancelInput
            onSave={(value) =>
              handleUpdateOrganization(value, 'organizationName')
            }
            value={organization?.name || ''}
            placeholder={intl.formatMessage(messages.company)}
          />
        </div>

        <div>
          <hr />
        </div>

        {/* Address */}
        <div className="display-flex" style={{ marginBottom: '5px' }}>
          <div>
            {intl.formatMessage(messages.companyAddress)}
            <p> {intl.formatMessage(messages.companyAddressInfo)}</p>
          </div>
          <SaveCancelInput
            onSave={(value) => handleUpdateOrganization(value, 'address')}
            value={organization?.address || ''}
            placeholder={intl.formatMessage(messages.companyAddressStreet)}
          />
        </div>
        <div className="display-flex" style={{ margin: 0 }}>
          <div></div>
          <div className="display-flex" style={{ marginTop: 0 }}>
            <SaveCancelInput
              onSave={(value) => handleUpdateOrganization(value, 'city')}
              value={organization?.city || ''}
              placeholder={intl.formatMessage(messages.companyAddressCity)}
            />
            <SaveCancelInput
              onSave={(value) => handleUpdateOrganization(value, 'postalCode')}
              value={organization?.postalCode || ''}
              placeholder={intl.formatMessage(
                messages.companyAddressPostalCode
              )}
            />
          </div>
        </div>

        <div>
          <hr />
        </div>

        {/* Company logo TODO
      <div className="display-flex">
        <div>
          {intl.formatMessage(messages.companyLogo)}
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit sit amet
            consectetur.
          </p>
        </div>
        <div className="display-flex flex-align-center">
          <img alt="" src="" />
          <Button imageUrl="/images/trash.svg" />
        </div>
      </div> 

      <div>
        <hr />
      </div>*/}

        {/* Timezone 
      <div className="display-flex">
        <div>{intl.formatMessage(messages.timezone)}</div>
        <Input value="Pacific Standard Time (PST)" />
      </div>

      <div>
        <hr />
      </div>

      {/* Language 
      <div className="display-flex">
        <div>{intl.formatMessage(messages.language)}</div>
        <Input value="English" />
      </div>*/}

        {/* Proctoring Settings */}
        <h3>{intl.formatMessage(messages.proctoringSettings)}</h3>

        {/* Privacy */}
        <div className="display-flex">
          <div>
            {intl.formatMessage(messages.privacy)}
            <p className="overflow-elipsis">
              {stripHtml(texts?.[TEXT_TYPES_BD.GDPR] ?? '').result}
            </p>
          </div>
          <Button
            onClick={() =>
              dispatch(
                showModal({
                  props: {
                    item: {
                      language: proctoringLang,
                      textKey: TEXT_TYPES_BD.GDPR,
                      texts,
                    },
                  },
                  type: MODAL_TYPES.CONFIRM_PROCTORING_TEXT,
                })
              )
            }
          >
            {intl.formatMessage(messages.editInstructions)}
          </Button>
        </div>

        <div>
          <hr />
        </div>

        {/* Controls */}
        <div className="display-flex">
          <div>
            {intl.formatMessage(messages.controls)}
            <p>{intl.formatMessage(messages.controlsText)}</p>
          </div>
          <div>
            {Object.entries(CONTROLS).map(([key, control], i) => {
              const desc = stripHtml(
                texts[CONTROLS_BORN_DIGITAL[key]] || ''
              ).result;

              return (
                <div
                  className="display-flex flex-justify-between"
                  key={`OrganizationSettings-Proctoring-controls-${control}-${i}`}
                >
                  <img alt="" src={`/images/${key.toLowerCase()}.svg`} />
                  <div>
                    {intl.formatMessage(messages[key.toLowerCase()])}
                    <span className="overflow-elipsis">
                      {stripHtml(desc).result}
                    </span>
                  </div>
                  <Button
                    onClick={() =>
                      dispatch(
                        showModal({
                          props: {
                            item: {
                              language: proctoringLang,
                              textKey: CONTROLS_BORN_DIGITAL[key],
                              texts,
                            },
                          },
                          type: MODAL_TYPES.CONFIRM_PROCTORING_TEXT,
                        })
                      )
                    }
                  >
                    {intl.formatMessage(messages.editInstructions)}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <hr />
        </div>

        {/* Process / Desktop */}
        <div className="display-flex">
          <div>
            {intl.formatMessage(messages.rules)}
            <p className="overflow-elipsis">
              {stripHtml(texts?.[TEXT_TYPES_BD.RULES] ?? '').result}
            </p>
          </div>
          <Button
            onClick={() =>
              dispatch(
                showModal({
                  props: {
                    item: {
                      language: proctoringLang,
                      textKey: TEXT_TYPES_BD.RULES,
                      texts,
                    },
                  },
                  type: MODAL_TYPES.CONFIRM_PROCTORING_TEXT,
                })
              )
            }
          >
            {intl.formatMessage(messages.editInstructions)}
          </Button>
        </div>
      </section>
    )
  );
};

export default OrganizationSettings;
