import { apiSlice } from '../../store/api/apiSlice';
import { QUESTION } from '../../consts/RTK';
import ModalTemplate from './ModalTemplate';
import '../../styles/item.scss';

const PreviewModal = ({ item, inputValue: itemName }) => {
  const { data } = apiSlice[QUESTION.GET_HTML.QUERY](item, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <ModalTemplate noControls={true} title={itemName}>
      <div
        className="item"
        dangerouslySetInnerHTML={{ __html: data?.contentHtml }}
      />
    </ModalTemplate>
  );
};

export default PreviewModal;
