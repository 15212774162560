import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH, ORGANIZATIONS } from '../../consts/RTK';
import { apiSlice } from '../../store/api/apiSlice';
import { getIsSuperadmin, getUser, logOut } from '../../store/auth/authSlice';
import { getOrganization } from '../../store/organizations/organizationsSlice';
import './SwitchOrganization.scss';

const SwitchOrganization = () => {
  const dispatch = useDispatch();
  const { id: userId, organizationId } = useSelector(getUser);
  const isSuperadmin = useSelector(getIsSuperadmin);
  const skip = !userId && !isSuperadmin;
  const [login] = apiSlice[AUTH.LOGIN_TO_ORGANIZATION.MUTATION]();
  const { data: organizations } = apiSlice[ORGANIZATIONS.GET.QUERY](userId, {
    skip,
  });
  const { name: organizationName } = useSelector(
    getOrganization(organizationId)
  );

  const handleSwitch = (id) => {
    dispatch(logOut());
    login(id);
  };

  return (
    organizations && (
      <div className="switch-organization">
        <div>
          <img alt={organizationName} src="/images/mockLogo.svg" />
          <div>
            {organizationName}
            <div>Team plan</div>
          </div>
        </div>
        <DropDownButton
          text={<img alt="Organizations" src="/images/expand.svg" />}
          items={organizations.map(({ id, name }) => {
            const isActive = organizationId === id;

            return (
              <Button disabled={isActive} onClick={() => handleSwitch(id)}>
                {name}
              </Button>
            );
          })}
        />
      </div>
    )
  );
};

export default SwitchOrganization;
