import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: null,
  props: null,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState: initialState,
  reducers: {
    closeModal: () => initialState,
    showModal: (state, { payload }) => payload,
  },
});

export const { closeModal, showModal } = modalsSlice.actions;

export default modalsSlice.reducer;

export const getModalType = (state) => state.modals.type;
export const getModalProps = (state) => state.modals.props;
