import DropDownButton from '../Common/DropDownButton';
import LabelsCell from '../Common/LabelsCell';
import Search from '../Common/Search';
import messages from '../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
  ColumnMenuMultiselect,
} from '../Common/ColumnFilter';
import { GRID } from '../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { MODAL_TYPES } from '../Modals/ModalsRoot';
import { USERS } from '../../consts/RTK';
import { acronym, capitalize } from '../../utils';
import { apiSlice } from '../../store/api/apiSlice';
import { getUserOrganizationId } from '../../store/auth/authSlice';
import { omit } from 'ramda';
import { filterBy } from '@progress/kendo-data-query';
import { showModal } from '../../store/modals/modalsSlice';
import {
  handleCustomKendoFilter,
  createFullNameOrEmailFilterDescriptor,
} from '../../filters';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { MEMBER_ROLES, USER_STATUS } from '../../consts/API';
import KendoLocalizator from '../../layout/KendoLocalizator';

const ActionsCell = ({ dataItem }) => {
  const dispatch = useDispatch();

  const handleDelete = () =>
    dispatch(
      showModal({
        props: { item: dataItem },
        type: MODAL_TYPES.DELETE_USER,
      })
    );

  const handleUpdate = () =>
    dispatch(
      showModal({
        props: dataItem,
        type: MODAL_TYPES.UPDATE_USER,
      })
    );

  return (
    <td>
      <DropDownButton onDelete={handleDelete} onUpdate={handleUpdate} />
    </td>
  );
};

const NameCell = ({ dataItem, dataIndex }) => {
  const colors = ['primary', 'success', 'warning'];

  return (
    <td className="display-flex flex-align-center">
      <span className={`label circle ${colors[dataIndex % 3]}`}>
        {acronym(dataItem.fullName)}
      </span>
      <div>
        {dataItem.fullName}
        <br />
        {dataItem.email}
      </div>
    </td>
  );
};

const NameHeaderCell = (props) => (
  <span className="k-cell-inner">
    <span className="k-link">
      <span
        {...omit(
          ['columnMenuWrapperProps', 'selectionChange', 'selectionValue'],
          props
        )}
        className="k-column-title"
      >
        {props.title}
        {props.children}
      </span>
    </span>
  </span>
);

const Members = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const userOrganizationId = useSelector(getUserOrganizationId);

  // Fetch users
  const { data } =
    apiSlice[USERS.GET_BY_ORGANIZATION.QUERY](userOrganizationId);

  const users = data ?? [];

  const [dataState, setDataState] = useState({
    ...GRID.DEFAULT_DATA_STATE,
    sort: [],
  });

  const allRoles = Object.values(MEMBER_ROLES).map((role, index) => ({
    idCategory: index,
    name: role,
  }));

  // Paging
  const [page, setPage] = useState(GRID.DEFAULT_DATA_STATE);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const roleNames = selectedRoles.length
    ? selectedRoles.map(({ name }) => name)
    : [];

  // can't use Memo to make it unique every time
  const key = +new Date();

  const membersExtended = users.map((user) => ({
    ...user,
    fullName: user.name + ' ' + user.surname,
    status: user.isActive ? USER_STATUS.ACTIVE : USER_STATUS.DEACTIVATED,
  }));

  const membersRolesFiltered = membersExtended.filter((user) =>
    user.roles.some((userRole) =>
      roleNames
        .map(
          (role) =>
            role.charAt(0).toLowerCase() + role.slice(1).replace(/ +/g, '')
        )
        .includes(userRole)
    )
  );

  // Filter
  const membersFiltered = filterBy(
    selectedRoles.length ? membersRolesFiltered : membersExtended,
    dataState.filter
  );

  const handleRolesFilter = (roles) => {
    setSelectedRoles(roles);
    setPage(GRID.DEFAULT_DATA_STATE);
  };

  // Search
  const handleSearch = (e, dataState, setDataState) => {
    const nameOrEmailFilterDescriptor = createFullNameOrEmailFilterDescriptor(
      e.target.value
    );
    handleCustomKendoFilter(
      dataState,
      setDataState,
      nameOrEmailFilterDescriptor,
      GRID.DEFAULT_DATA_STATE
    );
    setPage(GRID.DEFAULT_DATA_STATE);
  };

  const statuses = [USER_STATUS.ACTIVE, USER_STATUS.DEACTIVATED];
  const statusesCapitalized = statuses.map((status) => {
    return {
      status: capitalize(status),
    };
  });

  return (
    <section>
      {/* Title + controls */}
      <h2>
        {intl.formatMessage(messages.members)}
        <span>
          <Button
            className="primary"
            onClick={() =>
              dispatch(showModal({ type: MODAL_TYPES.CREATE_USER }))
            }
            title={intl.formatMessage(messages.inviteTeamMember)}
          >
            {intl.formatMessage(messages.inviteTeamMember)}
          </Button>
        </span>
      </h2>
      {/* Search by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByNameOrEmail)}
        onChange={(e) => handleSearch(e, dataState, setDataState)}
      />
      <KendoLocalizator>
        <Grid
          // force to clear React cache and recalculate categories
          key={`QuestionsListGrid=${key}`}
          data={membersFiltered.slice(page.skip, page.take + page.skip)}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          onPageChange={(e) => setPage(e.page)}
          pageSize={dataState.take}
          pageable={true}
          skip={page.skip}
          sort={page.sort}
          sortable={true}
          take={page.take}
          total={membersFiltered.length}
        >
          <GridColumn
            cell={(p) => <NameCell {...p} />}
            columnMenu={ColumnMenu}
            field="fullName"
            headerCell={(p) => <NameHeaderCell {...p} />}
            title={intl.formatMessage(messages.name)}
            width="50%"
          />
          <GridColumn
            cell={({ dataItem }) => (
              <td>
                {intl.formatMessage(messages[dataItem.status.toLowerCase()])}
              </td>
            )}
            columnMenu={(props) =>
              ColumnMenuCheckboxFilter(props, statusesCapitalized)
            }
            field="status"
            title={intl.formatMessage(messages.status)}
            width="20%"
          />
          <GridColumn
            cell={LabelsCell}
            columnMenu={() =>
              ColumnMenuMultiselect(allRoles, selectedRoles, handleRolesFilter)
            }
            title={intl.formatMessage(messages.roles)}
            width="23%"
          />
          <GridColumn cell={ActionsCell} width="7%" />
        </Grid>
      </KendoLocalizator>
    </section>
  );
};

export default Members;
