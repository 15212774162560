import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { API, API_SEGMENT } from '../../consts/API';
import { CLASSROOM, CLASSROOMS } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const classroomsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create classroom
    [CLASSROOM.CREATE.ENDPOINT]: builder.mutation({
      query: ({ name }) => ({
        body: { name },
        method: 'POST',
        url: API[API_SEGMENT.TESTING].CLASSROOM,
      }),
      invalidatesTags: ['ClassroomsGet'],
    }),
    // Delete classroom
    [CLASSROOM.DELETE.ENDPOINT]: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `${API[API_SEGMENT.TESTING].CLASSROOM}/?idGroup=${id}`,
      }),
      invalidatesTags: ['ClassroomsGet'],
    }),
    // Delete classroom examinee
    [CLASSROOM.DELETE_EXAMINEE.ENDPOINT]: builder.mutation({
      query: ({ idExaminee, idGroup }) => ({
        method: 'DELETE',
        url: API[API_SEGMENT.TESTING].CLASSROOM_EXAMINEE,
        body: { idGroup, idExaminee },
      }),
    }),
    // Get classroom
    [CLASSROOM.GET.ENDPOINT]: builder.query({
      query: (id) => ({
        url: `${API[API_SEGMENT.TESTING].CLASSROOM}?idGroup=${id}`,
      }),
    }),
    // Get classroom examinees
    [CLASSROOM.GET_EXAMINEES.ENDPOINT]: builder.query({
      query: ({ queryConfig, idGroup }) => ({
        url: `${
          API[API_SEGMENT.TESTING].CLASSROOM_EXAMINEES
        }?${toDataSourceRequestString(queryConfig)}&idGroup=${idGroup}`,
      }),
    }),
    // Get classrooms
    [CLASSROOMS.GET.ENDPOINT]: builder.query({
      query: (queryConfig) => ({
        url: `${
          API[API_SEGMENT.TESTING].CLASSROOMS
        }?${toDataSourceRequestString(queryConfig)}`,
      }),
      providesTags: ['ClassroomsGet'],
    }),
    // Update classroom name
    [CLASSROOM.UPDATE.CLASSROOM_NAME.ENDPOINT]: builder.mutation({
      query: ({ idGroup, name }) => ({
        body: { idGroup, name },
        method: 'PATCH',
        url: API[API_SEGMENT.TESTING].CLASSROOM,
      }),
    }),
  }),
});

export const endpoints = classroomsApiSlice.endpoints;
