import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getIsSuperadmin, getUserId } from '../../store/auth/authSlice';
import { apiSlice } from '../../store/api/apiSlice';
import OrganizationDetail from './OrganizationDetail';
import OrganizationsList from './OrganizationsList';
import { ORGANIZATIONS } from '../../consts/RTK';
import Customers from './Customers';

const Organizations = () => {
  const isSuperadmin = useSelector(getIsSuperadmin);
  const userId = useSelector(getUserId);
  const { id } = useParams();

  // Fetch organizations
  const { isLoading } = apiSlice[ORGANIZATIONS.GET.QUERY](
    isSuperadmin ? null : userId
  );

  return isLoading ? null : id ? (
    <OrganizationDetail />
  ) : (
    <>
      <Customers />
      <OrganizationsList />
    </>
  );
};

export default Organizations;
