import { omit } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { closeModal } from '../../store/modals/modalsSlice';
import { apiSlice } from '../../store/api/apiSlice';
import { validateAndSubmit, isoDateWithoutTimeZone } from '../../utils';
import ModalTemplate from './ModalTemplate';
import { EXAMINEE } from '../../consts/RTK';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';

const UpdateExamineeModal = ({
  idExaminee,
  firstName,
  lastName,
  birthDate,
}) => {
  const dispatch = useDispatch();

  birthDate = new Date(birthDate);
  const [state, setState] = useState({
    idExaminee,
    firstName,
    lastName,
    birthDate,
  });

  const intl = useIntl();
  const [updateExaminee, result] = apiSlice[EXAMINEE.UPDATE.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.updateExaminee} />}
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={() =>
        validateAndSubmit(
          intl,
          () =>
            updateExaminee(
              omit(['notification'], {
                ...state,
                birthDate: isoDateWithoutTimeZone(state.birthDate),
              })
            ),
          state,
          setState
        )
      }
    >
      {/* User name */}
      <strong>{intl.formatMessage(messages.userName)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', firstName: e.target.value })
        }
        placeholder={intl.formatMessage(messages.firstName)}
        type="text"
        value={state.firstName}
      />{' '}
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', lastName: e.target.value })
        }
        placeholder={intl.formatMessage(messages.lastName)}
        type="text"
        value={state.lastName}
      />
      <br />
      <br />
      {/* Date of Birth */}
      <strong>{intl.formatMessage(messages.dateOfBirth)}</strong>
      <br />
      <DatePicker
        onChange={(e) =>
          setState({
            ...state,
            notification: '',
            birthDate: e.value,
          })
        }
        value={!!state.birthDate ? new Date(state.birthDate) : null}
        placeholder={intl.formatMessage(messages.dateOfBirth)}
      />
      <br />
      <br />
      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default UpdateExamineeModal;
