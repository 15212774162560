import { API, API_SEGMENT } from '../../consts/API';
import { AUTH } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [AUTH.LOGIN.ENDPOINT]: builder.mutation({
      query: (credentials) => ({
        url: API[API_SEGMENT.ORGANIZATION].LOGIN,
        method: 'POST',
        body: { ...credentials },
      }),
      invalidatesTags: ['QuestionsCount', 'TestsCount', 'EventsCount'],
    }),
    [AUTH.LOGIN_TO_ORGANIZATION.ENDPOINT]: builder.mutation({
      query: (organizationId) => ({
        url: `${API[API_SEGMENT.ORGANIZATION].LOGIN}/${organizationId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const endpoints = authApiSlice.endpoints;
