import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { concat, omit, without } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { closeModal } from '../../store/modals/modalsSlice';
import { apiSlice } from '../../store/api/apiSlice';
import { validateAndSubmit } from '../../utils';
import ModalTemplate from './ModalTemplate';
import { USER } from '../../consts/RTK';
import { USER_ROLES } from '../../consts/API';

const UpdateUserModal = ({ id, isActive, name, roles, surname }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    id,
    isActive,
    name: name?.match(/[^\s]+/)[0],
    notification: null,
    roles,
    surname: surname,
  });

  const intl = useIntl();
  const [updateUser, result] = apiSlice[USER.UPDATE.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  const handleSelectRole = (selected, role) => {
    setState({
      ...state,
      roles: selected
        ? concat([role], state.roles)
        : without([role], state.roles),
    });
  };

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.updateUser} />}
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={() =>
        validateAndSubmit(
          intl,
          () => updateUser(omit(['notification'], state)),
          state,
          setState
        )
      }
    >
      {/* User name */}
      <strong>{intl.formatMessage(messages.userName)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', name: e.target.value })
        }
        placeholder={intl.formatMessage(messages.firstName)}
        type="text"
        value={state.name}
      />{' '}
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', surname: e.target.value })
        }
        placeholder={intl.formatMessage(messages.lastName)}
        type="text"
        value={state.surname}
      />
      <br />
      <br />
      {/* Roles */}
      <strong>{intl.formatMessage(messages.roles)}</strong>
      <br />
      <div className="margin-top-8">
        {Object.values(omit(['SUPERADMIN'], USER_ROLES)).map((role, i) => (
          <Checkbox
            className="margin-right-30"
            key={`UpdateUserModal-${role}-${i}`}
            label={intl.formatMessage(messages[role])}
            value={state.roles.includes(role)}
            onChange={(e) => handleSelectRole(e.value, role)}
          />
        ))}
      </div>
      <br />
      <br />
      {/* Status */}
      <strong>{intl.formatMessage(messages.status)}</strong>
      <br />
      <div className="margin-top-8">
        <Checkbox
          label={intl.formatMessage(messages.active)}
          value={state.isActive}
          onChange={() => setState({ ...state, isActive: !state.isActive })}
        />
      </div>
      <br />
      <br />
      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default UpdateUserModal;
