import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';

const Privacy = () => {
  return (
    <div className="content">
      <FormattedMessage {...messages.privacyText} />
    </div>
  );
};

export default Privacy;
