import { EXAMINEE_STATE } from '../../consts/ORGANIZATOR';
import { useIntl } from 'react-intl';
import { invertObj } from 'ramda';
import messages from '../../intl/messages';

const ExamineeState = ({ state }) => {
  const intl = useIntl();
  let stateCssClass = '';

  switch (state) {
    case EXAMINEE_STATE.ACCEPTED:
      stateCssClass = 'success';
      break;
    case EXAMINEE_STATE.PENDING:
      stateCssClass = 'warning';
      break;
    case EXAMINEE_STATE.DECLINED:
      stateCssClass = 'error';
      break;
    default:
    // TODO add catching error
  }

  return (
    <div className={`label bulletpoint ${stateCssClass}`}>
      {typeof state !== 'undefined' &&
        intl.formatMessage(
          messages[invertObj(EXAMINEE_STATE)[state].toLowerCase()]
        )}
    </div>
  );
};

export default ExamineeState;
