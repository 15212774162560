import { useContext } from 'react';
import { msToTime } from '../../../../utils';
import { Context } from '../Results';

const getViolationTime = (createdAt, streamStartedAt) =>
  new Date(createdAt).getTime() - new Date(streamStartedAt).getTime();

const ViolationsList = ({ activeTab, currentProctoringExam }) => {
  const { setVideoSeek } = useContext(Context);
  const violations =
    currentProctoringExam?.violations?.filter(
      (violation) => violation.tab === activeTab
    ) || [];

  return (
    <div className="violations-list">
      <ul>
        {violations.map(({ comment, createdAt }, i) => {
          const time = getViolationTime(
            createdAt,
            currentProctoringExam?.exam.streamStartedAt
          );
          return (
            <li
              key={`ExamPreview-Violations${i}${time}`}
              onClick={() => setVideoSeek(time)}
            >
              <span className="time">{msToTime(time)}</span>
              <span className="label error">
                <img alt="" src="/images/violation.svg" />
                {comment}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ViolationsList;
