import messages from '../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import {
  CONTROLS,
  LANGUAGES,
  RULES,
  SENSITIVITY,
  TEXT_TYPES_BD,
} from '../../../consts/PROCTORING';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Context } from './PublishEventModal';
import { PROCTORING } from '../../../consts/RTK';
import { apiSlice } from '../../../store/api/apiSlice';
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from 'string-strip-html';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ProctoringTexts from './ProctoringTexts';
import { isEmpty } from 'ramda';

const Proctoring = () => {
  const {
    instructionId,
    isWithProctoring,
    proctoring,
    progress,
    setInstructionId,
    setIsWithProctoring,
    setProctoring,
    setProgress,
  } = useContext(Context);
  const intl = useIntl();
  const [language, setLanguage] = useState(LANGUAGES.EN);

  // Get proctoring texts from BE
  const { data: texts } = apiSlice[PROCTORING.GET.TEXTS.QUERY](language);

  // Set default proctoring texts
  useEffect(() => {
    if (isEmpty(proctoring.privacyCustomText) && texts) {
      setProctoring({
        ...proctoring,
        monitorIdCustomText: texts.identification,
        monitorPersonCustomText: texts.face,
        monitorRoomCustomText: texts.room,
        privacyCustomText: texts.gdpr,
        processCustomText: texts.desktop,
        rulesCustomText: texts.rules,
      });
    }
  }, [proctoring, setProctoring, texts]);

  const toggleProctoring = () => setIsWithProctoring(!isWithProctoring);

  // Enable continue button
  useEffect(() => {
    if (!progress.allowContinue)
      setProgress({ ...progress, allowContinue: true });
  }, [progress, setProgress]);

  return instructionId ? (
    <ProctoringTexts language={language} setLanguage={setLanguage} />
  ) : (
    <div className={`proctoring ${!isWithProctoring && 'fadeOut'}`}>
      {/* On / Off */}
      <div>
        <div className="display-flex switch">
          <Button
            className={isWithProctoring ? 'active' : 'inactive'}
            onClick={toggleProctoring}
          >
            {intl.formatMessage(messages.active)}
          </Button>
          <Button
            className={!isWithProctoring ? 'active' : 'inactive'}
            onClick={toggleProctoring}
          >
            {intl.formatMessage(messages.off)}
          </Button>
        </div>
      </div>

      {/* AI sensitivity */}
      <div className="display-flex flex-column">
        <div>
          {intl.formatMessage(messages.ai)}
          <p>{intl.formatMessage(messages.aiText)}</p>
        </div>
        <div className="display-flex switch">
          {Object.values(SENSITIVITY).map((sensitivity, i) => (
            <Button
              key={`PublishEventModal-Proctoring-aiStrickness-${sensitivity}-${i}`}
              onClick={() =>
                setProctoring({ ...proctoring, aiStrickness: sensitivity })
              }
              className={
                proctoring?.aiStrickness === sensitivity ? 'active' : 'inactive'
              }
            >
              {intl.formatMessage(messages['aiSensitivity' + sensitivity])}
            </Button>
          ))}
        </div>
      </div>

      <div>
        <hr />
      </div>

      {/* Privacy */}
      <div className="display-flex">
        <div>
          {intl.formatMessage(messages.privacy)}
          <p className="overflow-elipsis proctoring-text">
            {stripHtml(proctoring.privacyCustomText || '').result}
          </p>
        </div>
        <Button onClick={() => setInstructionId(TEXT_TYPES_BD.GDPR)}>
          {intl.formatMessage(messages.editInstructions)}
        </Button>
      </div>

      <div>
        <hr />
      </div>

      {/* Controls */}
      <div className="display-flex">
        <div>
          {intl.formatMessage(messages.controls)}
          <p>{intl.formatMessage(messages.controlsText)}</p>
        </div>
      </div>

      {Object.entries(CONTROLS).map(([key, control], i) => (
        <div
          className="display-flex with-image"
          key={`PublishEventModal-Proctoring-controls-${control}-${i}`}
        >
          <img alt="" src={`/images/${key.toLowerCase()}.svg`} />
          <div>
            {intl.formatMessage(messages[key.toLowerCase()])}
            <p className="overflow-elipsis proctoring-text">
              {stripHtml(proctoring[control + 'CustomText'] || '').result}
            </p>
          </div>
          <Button
            onClick={() => setInstructionId(control)}
            disabled={!proctoring[control + 'Enabled']}
          >
            {intl.formatMessage(messages.editInstructions)}
          </Button>
          <Checkbox
            checked={proctoring[control + 'Enabled']}
            onClick={({ target }) =>
              setProctoring({
                ...proctoring,
                [control + 'Enabled']: target.checked,
              })
            }
          />
        </div>
      ))}

      <div>
        <hr />
      </div>

      {/* Process / Desktop */}
      <div className="display-flex">
        <div>
          {intl.formatMessage(messages.rules)}
          <p className="overflow-elipsis">
            {stripHtml(proctoring.rulesCustomText).result}
          </p>
        </div>
        <Button onClick={() => setInstructionId(TEXT_TYPES_BD.RULES)}>
          {intl.formatMessage(messages.editInstructions)}
        </Button>
      </div>

      {/* Rules */}
      {Object.entries(RULES).map(([key, control], i) => (
        <div
          className="display-flex with-image"
          key={`PublishEventModal-Proctoring-controls-${control}-${i}`}
        >
          <img alt="" src={`/images/${key.toLowerCase()}.svg`} />
          <div>{intl.formatMessage(messages[key.toLowerCase()])}</div>
          <Checkbox
            checked={proctoring[control + 'Enabled']}
            onClick={({ target }) =>
              setProctoring({
                ...proctoring,
                [control + 'Enabled']: target.checked,
              })
            }
          />
        </div>
      ))}
    </div>
  );
};

export default Proctoring;
