import { cloneElement } from 'react';

const SelectedGridRow = (row, isSelected) =>
  cloneElement(
    row,
    { className: `${row.props.className} ${isSelected && 'selected'}` },
    row.props.children
  );

export default SelectedGridRow;
