import Content from './Content';
import LeftBar from './LeftBar';
import RightBar from './RightBar';
import TopBar from '../../../Common/TopBar';
import styles from './TestDetail.module.scss';
import { EMPTY_TEST_PAGE, TEST_STATE } from '../../../../consts/ORGANIZATOR';
import { TEST } from '../../../../consts/RTK';
import { apiSlice } from '../../../../store/api/apiSlice';
import { createContext, useState } from 'react';
import { getTest } from '../../../../store/tests/testsSlice';
import { remove, update } from 'ramda';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Context = createContext();

const TestDetail = () => {
  const { id: idTest } = useParams();
  const test = useSelector(getTest(idTest));
  const { categories, content, name, state } = test || {};
  const pages = content?.pages ?? [];
  const [currentPageId, setCurrentPageId] = useState(-2);
  const [viewToggle, setViewToggle] = useState(true);
  const isDone = state === TEST_STATE.DONE;

  // Fetch test content
  apiSlice[TEST.GET.QUERY](idTest, {
    refetchOnMountOrArgChange: true,
  });

  // Update test content
  const [updateTestContent] = apiSlice[TEST.UPDATE.TEST_CONTENT.MUTATION]();

  // Update test name
  const [updateTestName] = apiSlice[TEST.UPDATE.TEST_NAME.MUTATION]();
  const handleUpdateTestName = (name) => {
    updateTestName({ idTest, name });
  };

  // Delete instructions
  const deleteInstructions = (questions) =>
    updateTestContent({
      idTest,
      content: {
        ...content,
        instructions: null,
      },
    });

  // Update questons
  const updateQuestions = (questions) =>
    updateTestContent({
      idTest,
      content: {
        ...content,
        pages: update(
          currentPageId,
          {
            ...pages[currentPageId],
            items: questions,
          },
          pages
        ),
      },
    });

  // Create / Delete page
  const assocDissocPage = (pageId) => {
    let pagesBody = pages ? [...pages, EMPTY_TEST_PAGE] : [EMPTY_TEST_PAGE];

    if (pageId || pageId === 0) {
      pagesBody = remove(pageId, 1, pages);
    }

    updateTestContent({
      idTest,
      content: {
        ...content,
        pages: pagesBody,
      },
    });

    setCurrentPageId(pagesBody?.length - 1 ?? 0);
  };

  // Update pages
  const updatePages = async (pages, targetPageId) => {
    await updateTestContent({
      idTest,
      content: {
        ...content,
        pages,
      },
    });

    setCurrentPageId(targetPageId);
  };

  // Update supporting materials
  const updateSupportingMaterials = (supportingMaterials) =>
    updateTestContent({
      idTest,
      content: {
        ...content,
        supportingMaterials,
      },
    });

  return (
    test && (
      <Context.Provider
        value={{
          assocDissocPage,
          categories,
          content,
          currentPageId,
          currentQuestions: pages?.[currentPageId]?.items ?? [],
          deleteInstructions,
          idTest,
          instructions: content?.instructions?.content,
          isDone,
          isSupportingMaterialsPage: currentPageId === -1,
          isWelcomePage: currentPageId === -2,
          pages,
          setCurrentPageId,
          setViewToggle,
          state,
          supportingMaterials: content?.supportingMaterials,
          updatePages,
          updateQuestions,
          updateSupportingMaterials,
          updateTestContent,
          viewToggle,
        }}
      >
        <div className={styles['test-detail']}>
          <TopBar inputValue={name} onInputSave={handleUpdateTestName} />

          <div className="main">
            {/* Left bar - Pages */}
            <LeftBar />

            {/* Content */}
            <Content />

            {/* Right bar - Test info */}
            <RightBar />
          </div>
        </div>
      </Context.Provider>
    )
  );
};

export default TestDetail;
