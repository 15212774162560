import ModalTemplate from './ModalTemplate';
import SelectExamineeClassrooms from '../Dashboard/StudentManagement/SelectExamineeClassrooms';
import messages from '../../intl/messages';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { EXAMINEE } from '../../consts/RTK';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from '@progress/kendo-react-inputs';
import { apiSlice } from '../../store/api/apiSlice';
import { closeModal } from '../../store/modals/modalsSlice';
import { createContext, useEffect, useState } from 'react';
import { endpoints } from '../../store/examinees/examineesApiSlice';
import {
  isEmailValid,
  validateAndSubmit,
  isoDateWithoutTimeZone,
} from '../../utils';
import { isEmpty, omit } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { getClassroom } from '../../store/classrooms/classroomsSlice';

export const Context = createContext();

const InviteExamineeModal = ({ idGroup }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentClassroom = useSelector(getClassroom(idGroup));

  const [examineeExists, setExamineeExists] = useState(undefined);
  const displayForm = examineeExists !== undefined;
  const [state, setState] = useState({
    birthDate: '',
    email: '',
    firstName: '',
    idGroups: [],
    lastName: '',
    notification: null,
  });

  // Fetch examinee
  const fetchExaminee = async () => {
    const examinee = await dispatch(
      endpoints[EXAMINEE.GET.ENDPOINT].initiate(state.email)
    );
    const examineeExists = examinee.isSuccess;

    if (!examineeExists) {
      setExamineeExists(false);
      setState({
        ...state,
        idGroups: isEmpty(currentClassroom) ? [] : [currentClassroom],
      });
      return;
    }

    setState({
      ...state,
      ...examinee.data,
      idGroups: examinee.data.groups,
      birthDate: new Date(examinee.data.birthDate),
    });

    setExamineeExists(examineeExists);
  };

  // Invite examinee
  const [inviteExaminee, result] = apiSlice[EXAMINEE.INVITE.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  // Invite examinee
  const onSave = () => {
    !displayForm
      ? fetchExaminee()
      : validateAndSubmit(
          intl,
          () =>
            inviteExaminee(
              omit(['notification', 'groups'], {
                ...state,
                birthDate: isoDateWithoutTimeZone(state.birthDate),
                idGroup,
                idGroups: state.idGroups.map(({ idGroup }) => idGroup),
              })
            ),
          state,
          setState
        );
  };

  // Close modal
  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.inviteExaminee} />}
      saveBtnTitle={
        <FormattedMessage
          {...messages[!displayForm ? 'continue' : 'inviteExaminee']}
        />
      }
      isSaveBtnDisabled={!isEmailValid(state.email)}
      onSave={onSave}
    >
      <Context.Provider value={{ idGroup, state, setState }}>
        {/* Email */}
        <strong>{intl.formatMessage(messages.email)}</strong>
        <br />
        <Input
          disabled={displayForm}
          onChange={(e) =>
            setState({ ...state, notification: '', email: e.target.value })
          }
          placeholder={intl.formatMessage(messages.email)}
          type="text"
          value={state.email}
        />

        {displayForm && (
          <div>
            <br />
            {!examineeExists && (
              // Examinee doesn't exists - fill up examinee datails
              <>
                <div className="display-flex gap-12">
                  {/* First Name */}
                  <div>
                    <strong>{intl.formatMessage(messages.firstName)}</strong>
                    <Input
                      onChange={(e) =>
                        setState({
                          ...state,
                          notification: '',
                          firstName: e.target.value,
                        })
                      }
                      placeholder={intl.formatMessage(messages.firstName)}
                      type="text"
                      value={state.firstName}
                    />
                  </div>
                  <div>
                    {/* Last Name */}
                    <strong>{intl.formatMessage(messages.lastName)}</strong>
                    <Input
                      onChange={(e) =>
                        setState({
                          ...state,
                          notification: '',
                          lastName: e.target.value,
                        })
                      }
                      placeholder={intl.formatMessage(messages.lastName)}
                      type="text"
                      value={state.lastName}
                    />
                  </div>
                </div>
                <br />

                {/* Date of Birth */}
                <strong>{intl.formatMessage(messages.dateOfBirth)}</strong>
                <br />
                <DatePicker
                  onChange={(e) =>
                    setState({
                      ...state,
                      notification: '',
                      birthDate: e.value,
                    })
                  }
                  value={!!state.birthDate ? new Date(state.birthDate) : null}
                  placeholder={intl.formatMessage(messages.dateOfBirth)}
                />
                <br />
                <br />
              </>
            )}

            {/* Classrooms */}
            <div className="section">
              <strong title={intl.formatMessage(messages.classrooms)}>
                {intl.formatMessage(messages.classrooms)}
              </strong>
              <SelectExamineeClassrooms />
            </div>

            <br />
            <br />
          </div>
        )}
        <br />
        {/* Notifications */}
        {state.notification}
        {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
      </Context.Provider>
    </ModalTemplate>
  );
};

export default InviteExamineeModal;
