import { Stepper as KendoStepper } from '@progress/kendo-react-layout';
import { remove } from 'ramda';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../../intl/messages';
import { Context } from './PublishEventModal';

const Stepper = () => {
  const { currentStep, steps: items } = useContext(Context);
  const intl = useIntl();

  return (
    <KendoStepper
      value={currentStep - 1}
      items={remove(0, 1, items)?.map((item) => ({
        ...item,
        label: intl.formatMessage(messages[item.title]),
        text: ' ',
      }))}
      orientation="vertical"
    />
  );
};

export default Stepper;
