import DropDownButton from '../../Common/DropDownButton';
import Search from '../../Common/Search';
import messages from '../../../intl/messages';
import { EXAMINEES } from '../../../consts/RTK';
import { GRID } from '../../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { apiSlice } from '../../../store/api/apiSlice';
import { capitalize, formatDate } from '../../../utils';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { NameCell } from '../../Dashboard/StudentManagement/NameCell.js';
import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/modals/modalsSlice';
import ExamineeState from '../../Common/ExamineeState';
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from '../../Common/ColumnFilter.js';
import { EXAMINEE_STATE } from '../../../consts/ORGANIZATOR';
import { invertObj, omit } from 'ramda';
import {
  handleCustomKendoFilter,
  createFullNameOrEmailFilterDescriptor,
} from '../../../filters';
import KendoLocalizator from '../../../layout/KendoLocalizator';

const ActionsCell = ({ dataItem }) => {
  const dispatch = useDispatch();

  const handleUpdate = () =>
    dispatch(
      showModal({
        props: dataItem,
        type: MODAL_TYPES.UPDATE_EXAMINEE,
      })
    );

  return (
    <td>
      <DropDownButton onUpdate={handleUpdate} />
    </td>
  );
};

const labels = Object.values(
  invertObj(omit(['DELETED', 'DEFAULT'], EXAMINEE_STATE))
);
const labelsCapitalized = labels.map((label) => {
  return { accountState: capitalize(label) };
});

const StudentsList = () => {
  const intl = useIntl();
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);

  // Fetch examinees
  const { data } = apiSlice[EXAMINEES.GET.QUERY](dataState, {
    refetchOnMountOrArgChange: true,
  });
  const examinees = data?.data ?? [];

  const handleSearch = (e, dataState, setDataState) => {
    const fullNameOrEmailFilterDescriptor =
      createFullNameOrEmailFilterDescriptor(e.target.value);

    handleCustomKendoFilter(
      dataState,
      setDataState,
      fullNameOrEmailFilterDescriptor,
      GRID.DEFAULT_DATA_STATE
    );
  };

  return (
    <div>
      <br />
      <br />
      {/* Search test by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByNameOrEmail)}
        onChange={(e) => handleSearch(e, dataState, setDataState)}
      />

      {/* List */}
      <KendoLocalizator>
        <Grid
          data={examinees}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageSize={dataState.take}
          pageable={true}
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={dataState.take}
          total={data?.total ?? 0}
        >
          <GridColumn
            cell={(p) => <NameCell {...p} />}
            field="fullName"
            title={intl.formatMessage(messages.fullName)}
            filterable={false}
            width="50%"
          />

          {/* Status */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>
                <ExamineeState state={dataItem.accountState} />
              </td>
            )}
            columnMenu={(props) =>
              ColumnMenuCheckboxFilter(props, labelsCapitalized)
            }
            field="accountState"
            title={intl.formatMessage(messages.status)}
            width="20%"
          />

          {/* Created at */}
          <GridColumn
            cell={({ dataItem }) => <td>{formatDate(dataItem.birthDate)}</td>}
            columnMenu={ColumnMenu}
            field="birthDate"
            title={intl.formatMessage(messages.dateOfBirth)}
            width="30%"
            filter="date"
          />

          {/* Actions */}
          <GridColumn cell={ActionsCell} width="13%" />
        </Grid>
      </KendoLocalizator>
    </div>
  );
};

export default StudentsList;
