import LabelsCell from '../Common/LabelsCell';
import ModalTemplate from './ModalTemplate';
import Row from '../Common/SelectedGridRow.js';
import messages from '../../intl/messages';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ColumnMenu } from '../Common/ColumnFilter';
import { FormattedMessage, useIntl } from 'react-intl';
import { GRID } from '../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { QUESTIONS, TEST } from '../../consts/RTK';
import { QUESTION_TYPE_ICONS } from '../../consts/DESIGN';
import { apiSlice } from '../../store/api/apiSlice';
import { closeModal } from '../../store/modals/modalsSlice';
import { formatDate } from '../../utils';
import { isEmpty } from 'ramda';
import { questionsApiSlice } from '../../store/questions/questionsApiSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

export const CheckboxHeaderCell = (props) => {
  const { data, selected, setSelected, title } = props;
  const all = data.map(({ idItem, name }) => ({
    idItem,
    name,
  }));

  return (
    <span className="k-cell-inner">
      <span className="k-column-title">
        <Checkbox
          checked={selected.length === data.length}
          onChange={({ value }) => setSelected(value ? all : [])}
        />{' '}
        {title}
      </span>
    </span>
  );
};

const CheckBoxCell = (props) => {
  const { dataItem, selected, setSelected } = props;
  const intl = useIntl();
  const checked = isEmpty(
    selected.filter(({ idItem }) => dataItem.idItem === idItem)
  );

  return (
    <td className="display-flex flex-align-center">
      <Checkbox
        checked={!checked}
        onChange={() =>
          setSelected(
            !checked
              ? selected.filter(({ idItem }) => dataItem.idItem !== idItem)
              : [...selected, { idItem: dataItem.idItem, name: dataItem.name }]
          )
        }
      />
      <img
        alt={QUESTION_TYPE_ICONS[dataItem.type].alt}
        src={QUESTION_TYPE_ICONS[dataItem.type].src}
      />
      <div className="display-flex flex-column">
        {dataItem.name}
        <br />
        <div>
          {`${intl.formatMessage(messages.updated)}: ${
            dataItem.dateUpdatedUtc ? formatDate(dataItem.dateUpdatedUtc) : ''
          }`}
        </div>
      </div>
    </td>
  );
};

const AddSupportingMaterialsModal = ({
  content,
  currentPageId: pageId,
  idTest,
}) => {
  const { supportingMaterials } = content;
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selected, setSelected] = useState(supportingMaterials);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: GRID.DEFAULT_QUERY_LIMIT,
  });

  // Fetch supporting materials
  const { data } = questionsApiSlice[QUESTIONS.GET_SUPPORTING_MATERIALS.QUERY](
    dataState,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // Update test content
  const [updateTestContent, result] =
    apiSlice[TEST.UPDATE.TEST_CONTENT.MUTATION]();

  // Update supporting materials
  const updateSupportingMaterials = () =>
    updateTestContent({
      idTest,
      content: {
        ...content,
        supportingMaterials: selected,
      },
    });

  // Close modal
  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    data?.data && (
      <ModalTemplate
        title={<FormattedMessage {...messages.supportingMaterials} />}
        saveBtnTitle={<FormattedMessage {...messages.save} />}
        onSave={updateSupportingMaterials}
      >
        <Grid
          data={data.data}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageable={true}
          pageSize={dataState.take}
          rowRender={(row, { dataItem }) =>
            Row(row, selected.includes(dataItem.idItem))
          }
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={dataState.take}
          total={data?.total ?? 0}
        >
          {/* Name */}
          <GridColumn
            cell={(p) => (
              <CheckBoxCell
                {...p}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            columnMenu={ColumnMenu}
            field="name"
            headerCell={(p) => (
              <CheckboxHeaderCell
                {...p}
                data={data.data}
                selected={selected}
                setSelected={setSelected}
                title={intl.formatMessage(messages.name)}
              />
            )}
            title={intl.formatMessage(messages.name)}
            width="50%"
          />

          {/* Categories */}
          <GridColumn
            cell={LabelsCell}
            columnMenu={ColumnMenu}
            field="categories"
            title={intl.formatMessage(messages.category)}
            width="20%"
          />

          {/* Created */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>{formatDate(dataItem.dateCreatedUtc)}</td>
            )}
            columnMenu={ColumnMenu}
            field="dateCreatedUtc"
            title={intl.formatMessage(messages.created)}
            width="20%"
          />
        </Grid>
      </ModalTemplate>
    )
  );
};

export default AddSupportingMaterialsModal;
