export const NameCell = ({ dataItem, dataIndex, onClick }) => {
  const colors = ['primary', 'success', 'warning'];

  return (
    <td className="display-flex">
      <span className={`label circle ${colors[dataIndex % 3]}`}>
        {dataItem.firstName[0]}
        {dataItem.lastName[0]}
      </span>
      <div className={onClick && 'cursor-pointer'} onClick={onClick}>
        {dataItem.firstName} {dataItem.lastName}
        <br />
        {dataItem.email}
      </div>
    </td>
  );
};
