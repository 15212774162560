import { Input } from '@progress/kendo-react-inputs';
import './Search.scss';

const Search = (props) => (
  <div className="search">
    <img
      alt="search"
      src="/images/search.svg"
      className="k-floating-label-container"
    />
    <Input {...props} />
  </div>
);

export default Search;
