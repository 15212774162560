import { createIntl, createIntlCache } from 'react-intl';
import { LOCALE } from '../consts/INTL';
import cs from './cs';
import en from './en';
import messages from './messages';

export const INTL = createIntl(
  { locale: LOCALE.EN, messages },
  createIntlCache()
);
export { messages };
export const translations = { [LOCALE.CS]: cs, [LOCALE.EN]: en };
