import Search from '../../Common/Search';
import messages from '../../../intl/messages';
import { CLASSROOMS } from '../../../consts/RTK';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Context } from './PublishEventModal';
import { GRID } from '../../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { apiSlice } from '../../../store/api/apiSlice';
import { arrToObjIdAsKey, usePrevious } from '../../../utils';
import { equals, intersection, isEmpty } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const CheckboxCell = ({ dataItem, setTabState }) => {
  const { allExaminees, exam, setCurrentClassroom, setExam } =
    useContext(Context);
  const { idGroup, membersCount, name } = dataItem;
  const intl = useIntl();
  const classroomExaminees =
    arrToObjIdAsKey(allExaminees || [], 'idGroup')[idGroup]?.examinees || [];
  const checked = !isEmpty(intersection(exam.examinees, classroomExaminees));

  const assocDissocExaminees = async () => {
    setExam({
      ...exam,
      examinees: checked
        ? exam.examinees.filter(
            (examinee) => !classroomExaminees.includes(examinee)
          )
        : [...exam.examinees, ...classroomExaminees],
    });
  };

  const showClassroomDetail = () => {
    setCurrentClassroom(dataItem);
    setTabState(1);
  };

  return (
    <td className="display-flex">
      <Checkbox checked={checked} onClick={assocDissocExaminees} />
      <div className="cursor-pointer" onClick={showClassroomDetail}>
        <span>{name}</span>
        <br />
        {`${intl.formatMessage(messages.students)}: ${membersCount}`}
      </div>
    </td>
  );
};

const ClassroomList = ({ setTabState }) => {
  const { exam, progress, setProgress } = useContext(Context);
  const intl = useIntl();
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);
  const prevClassrooms = usePrevious(exam.classrooms);

  // Fetch classrooms
  const { data } = apiSlice[CLASSROOMS.GET.QUERY](dataState, {
    refetchOnMountOrArgChange: true,
  });
  const classrooms = data?.data ?? [];

  // Search
  const handleSearch = (e, dataState, setDataState) => {
    setDataState({
      ...GRID.DEFAULT_DATA_STATE,
      filter: {
        ...dataState.filter,
        filters: [
          { field: 'name', operator: 'contains', value: e.target.value },
        ],
      },
    });
  };

  // Enable continue button
  useEffect(() => {
    if (!equals(prevClassrooms, exam.classrooms)) {
      setProgress({ ...progress, allowContinue: !isEmpty(exam.classrooms) });
    }
  }, [progress, exam.classrooms, prevClassrooms, setProgress]);

  return (
    <div className="classroom-list">
      {/* Search test by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByName)}
        onChange={(e) => handleSearch(e, dataState, setDataState)}
        value={dataState?.filter?.filters?.[0]?.value ?? ''}
      />
      <Grid
        data={classrooms}
        filter={dataState.filter}
        onDataStateChange={(e) => setDataState(e.dataState)}
        pageSize={dataState.take}
        pageable={true}
        skip={dataState.skip}
        sort={dataState.sort}
        sortable={true}
        take={dataState.take}
        total={data?.total ?? 0}
      >
        <GridColumn
          cell={(prop) => <CheckboxCell {...prop} setTabState={setTabState} />}
          field="name"
          title={intl.formatMessage(messages.title)}
        />
      </Grid>
    </div>
  );
};

export default ClassroomList;
