import { useSelector } from 'react-redux';
import { getUserEmail, getUserFullName } from '../../store/auth/authSlice';
import LogOutButton from '../Common/LogOutButton';
import './Footer.scss';

const Footer = () => {
  const fullName = useSelector(getUserFullName);
  const email = useSelector(getUserEmail);

  return (
    <div className="footer">
      <LogOutButton name={fullName} />
      <div>
        {fullName}
        <div>{email}</div>
      </div>
    </div>
  );
};

export default Footer;
