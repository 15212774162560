import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_SEGMENT } from '../../consts/API';
import { logOut } from '../auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  prepareHeaders(headers) {
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
      // If 401 then try to refresh token
      const tokenRefresh = await baseQuery(
        `/${API_SEGMENT.ORGANIZATION}/auth/token`,
        api,
        extraOptions
      );

      if (tokenRefresh?.data) {
        // Retry the original query
        result = await baseQuery(args, api, extraOptions);
      } else {
        // If token refresh failed dispatch logout
        api.dispatch(logOut());
      }
    }

    return result;
  },
  endpoints: (builder) => ({}),
});

export const endpoints = apiSlice.endpoints;
