export const QUESTION_TYPE_ICONS = [
  {
    alt: '',
    src: '',
  },
  {
    alt: 'White circle with a tick icon inside on a blue background',
    src: '/images/questionTypeMultipleChoice.svg',
  },
  {
    alt: 'White chevron pointing down on a green background',
    src: '/images/questionTypeDropdown.svg',
  },
  {
    alt: 'White pen icon on an orange background',
    src: '/images/questionTypeOpenText.svg',
  },
  {
    alt: 'White capital letter H on a red background',
    src: '/images/questionTypeText.svg',
  },
];
