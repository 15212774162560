import ROUTES from '../../../consts/ROUTES.js';
import Search from '../../Common/Search';
import messages from '../../../intl/messages';
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from '../../Common/ColumnFilter.js';
import { Button } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { GRID } from '../../../consts/KENDO.js';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { invertObj } from 'ramda';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { useContext, useState, useEffect, useCallback } from 'react';
import { NameCell } from '../../Dashboard/StudentManagement/NameCell.js';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { FINAL_REVIEW } from '../../../consts/PROCTORING.js';
import { Context } from './Results.js';
import { EXAM } from '../../../consts/RTK';
import { useDispatch } from 'react-redux';
import { endpoints } from '../../../store/exam/examApiSlice';
import { API } from '../../../consts/API';
import CSVButton from '../../Common/CSVButton';
import { EXAM_INSTANCE_STATE } from '../../../consts/ORGANIZATOR.js';
import {
  createFullNameOrEmailFilterDescriptor,
  handleCustomKendoFilter,
} from '../../../filters.js';
import { capitalize } from '../../../utils.js';
import KendoLocalizator from '../../../layout/KendoLocalizator.js';

const ResultsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [suspicionDetectionAvailable, setSuspicionDetectionAvailable] =
    useState(false); // default state - button is disabled
  const { idExam } = useParams();
  const intl = useIntl();
  const { results, dataState, setDataState } = useContext(Context);
  const { data } = results || {};
  const examsData = data || [];

  const labels = Object.values(invertObj(EXAM_INSTANCE_STATE));
  const finalReview = Object.values(invertObj(FINAL_REVIEW));
  const labelsExtended = invertObj(EXAM_INSTANCE_STATE);
  const finalReviewExtended = invertObj(FINAL_REVIEW);
  const labelsCapitalized = labels.map((label) => {
    return {
      state: capitalize(label),
    };
  });

  const finalReviewCapitalized = finalReview.map((rev) => {
    return {
      finalReview: capitalize(rev),
    };
  });

  const handleSearch = (e, dataState, setDataState) => {
    const fullNameOrEmailFilterDescriptor =
      createFullNameOrEmailFilterDescriptor(e.target.value);

    handleCustomKendoFilter(
      dataState,
      setDataState,
      fullNameOrEmailFilterDescriptor,
      GRID.DEFAULT_DATA_STATE
    );
  };

  const isSuspicionDetectionAvailable = useCallback(async () => {
    try {
      const response = await dispatch(
        endpoints[EXAM.GET_SUSPICION_DETECTION.ENDPOINT].initiate(idExam)
      );
      if (response?.error) {
        console.error('response error =', response.error);
      } else {
        setSuspicionDetectionAvailable(true);
      }
    } catch (error) {
      console.error('internal error =', error);
    }
  }, [dispatch, idExam]);

  // TODO finalize the feature - what to do on success, show results, show error etc.
  const handleSuspicionDetection = async () => {
    try {
      const response = await dispatch(
        endpoints[EXAM.SUSPICION_DETECTION.ENDPOINT].initiate(idExam)
      );

      if (response?.error) {
        console.error('response error =', response.error);
      } else {
        console.log('response =', response.data);
      }
    } catch (error) {
      console.error('internal error =', error);
    }
  };

  const handleClickResults = (idExamInstance) => {
    navigate(
      ROUTES.SETTINGS.EXAM_PREVIEW.PATH.replace(':idExam', idExam).replace(
        ':idExamInstance',
        idExamInstance
      )
    );
  };
  useEffect(() => {
    if (!suspicionDetectionAvailable) {
      isSuspicionDetectionAvailable();
    }
  }, [suspicionDetectionAvailable, isSuspicionDetectionAvailable]);

  return (
    <section>
      {/* Title + controls */}
      <h1 className="display-flex flex-justify-between">
        {intl.formatMessage(messages.results)}
        <div className="result-buttons">
          {/* Export */}
          <CSVButton
            endpoint={API.marking.EXAM_LIST_CSV}
            idExam={idExam}
            text="exportResults"
          />
          <CSVButton
            endpoint={API.marking.EXAM_LIST_DETAIL_CSV}
            idExam={idExam}
            text="exportAnswers"
          />
          <Tooltip position="bottom" parentTitle={true}>
            <Button
              className="primary"
              onClick={() => handleSuspicionDetection()}
              disabled={!suspicionDetectionAvailable}
              title={
                !suspicionDetectionAvailable
                  ? intl.formatMessage(messages.cheatingAnalysisDisabledTitle)
                  : ''
              }
            >
              {intl.formatMessage(messages.cheatingAnalysis)}
            </Button>
          </Tooltip>
        </div>
      </h1>

      <div className="display-flex">
        {/* Search by name and email */}
        <Search
          className="plain search k-floating-label-container"
          label={intl.formatMessage(messages.searchByNameOrEmail)}
          onChange={(e) => handleSearch(e, dataState, setDataState)}
        />
      </div>

      <div className="display-flex">
        <KendoLocalizator>
          <Grid
            data={examsData}
            filter={dataState.filter}
            onDataStateChange={(e) => setDataState(e.dataState)}
            pageSize={dataState.take}
            pageable={true}
            skip={dataState.skip}
            sort={dataState.sort}
            take={dataState.take}
            total={data?.total ?? 0}
            sortable={true}
          >
            {/* Select, Name, Email */}
            <GridColumn
              cell={(p) => (
                <NameCell
                  {...p}
                  onClick={
                    p.dataItem.state === EXAM_INSTANCE_STATE.FINISHED ||
                    p.dataItem.state === EXAM_INSTANCE_STATE.EVALUATED
                      ? () => handleClickResults(p.dataItem.idExamInstance)
                      : undefined
                  }
                />
              )}
              field="fullName"
              title={intl.formatMessage(messages.fullName)}
              filterable={false}
              width="30%"
            />

            {/* State */}
            <GridColumn
              cell={({ dataItem }) => (
                <td>
                  <div
                    className={`label bulletpoint ${
                      dataItem.state === 2
                        ? 'warning'
                        : dataItem.state === 3 && 'success'
                    }`}
                  >
                    {intl.formatMessage(
                      messages[labelsExtended[dataItem?.state].toLowerCase()]
                    )}
                  </div>
                </td>
              )}
              columnMenu={(props) =>
                ColumnMenuCheckboxFilter(props, labelsCapitalized)
              }
              field="state"
              title={intl.formatMessage(messages.status)}
              width="15%"
            />

            {/* Proctoring Evaluations */}
            <GridColumn
              cell={({ dataItem }) => (
                <td>
                  {dataItem.aiViolationsCount ===
                  null ? null : dataItem.aiViolationsCount === 0 ? (
                    <span className="label success bulletpoint">OK</span>
                  ) : (
                    <span className="label error bulletpoint">
                      {dataItem.aiViolationsCount}
                    </span>
                  )}
                </td>
              )}
              columnMenu={ColumnMenu}
              field="aiViolationsCount"
              filter={'numeric'}
              title={intl.formatMessage(messages.proctoringEvaluations)}
              width="9%"
            />

            {/* Score */}
            <GridColumn
              cell={({ dataItem }) => {
                if (dataItem?.netSuccessRate) {
                  const netSuccessRatePercent = Math.round(
                    100 * dataItem.netSuccessRate
                  );
                  return (
                    <td>
                      <ProgressBar
                        className="percentage"
                        value={netSuccessRatePercent}
                      />
                      {`${netSuccessRatePercent} % (${dataItem.score}/${dataItem.maxScore})`}
                    </td>
                  );
                }
                return <td></td>;
              }}
              columnMenu={ColumnMenu}
              filter={'numeric'}
              field="score"
              title={intl.formatMessage(messages.score)}
              width="15%"
            />

            {/* Time */}
            <GridColumn
              cell={({ dataItem }) => (
                <td>{dataItem?.spentTime?.split('.')[0]}</td>
              )}
              filterable={false}
              field="spentTime"
              title={intl.formatMessage(messages.time)}
              width="8%"
            />

            {/* Final review */}
            <GridColumn
              cell={({ dataItem }) => (
                <td>
                  {dataItem.finalReview && (
                    <div
                      className={`label bulletpoint ${
                        dataItem.finalReview === 1 ? 'success' : 'error'
                      }`}
                    >
                      {intl.formatMessage(
                        messages[
                          finalReviewExtended[
                            dataItem?.finalReview
                          ].toLowerCase()
                        ]
                      )}
                    </div>
                  )}
                </td>
              )}
              columnMenu={(props) =>
                ColumnMenuCheckboxFilter(props, finalReviewCapitalized)
              }
              field="finalReview"
              title={intl.formatMessage(messages.finalReview)}
              width="13%"
            />
            {/* Supicion percentile */}
            <GridColumn
              cell={({ dataItem }) => {
                if (dataItem?.suspicionPercentile) {
                  return (
                    <td>{`${
                      dataItem.suspicionPercentile
                        ? Math.round(dataItem.suspicionPercentile)
                        : ''
                    } %`}</td>
                  );
                }
                return <td></td>;
              }}
              columnMenu={ColumnMenu}
              field="suspicionPercentile"
              filter={'numeric'}
              title={intl.formatMessage(messages.suspicionPercentile)}
              width="9%"
            />
          </Grid>
        </KendoLocalizator>
      </div>
    </section>
  );
};

export default ResultsList;
