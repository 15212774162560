import Identification from './Identification';
import VideoBox from '../../../Common/VideoPlayer/VideoBox';
import ViolationsList from './ViolationsList';
import messages from '../../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { MODAL_TYPES } from '../../../Modals/ModalsRoot';
import { EXAM_REVIEW, PREVIEW } from '../../../../consts/PROCTORING';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { showModal } from '../../../../store/modals/modalsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useContext, useState } from 'react';
import { Context } from '../Results';
import { getUser } from '../../../../store/auth/authSlice';
import { endpoints } from '../../../../store/proctoring/proctoringApiSlice';
import { PROCTORING } from '../../../../consts/RTK';
import Svg from '../../../Common/Svg';

const Proctoring = ({ currentProctoringExam }) => {
  const [tabState, setTabState] = useState(0);
  const dispatch = useDispatch();
  const { id: userId } = useSelector(getUser);
  const { idExamInstance } = useContext(Context);
  const intl = useIntl();
  const { TAB } = PREVIEW;
  const tabs = [TAB.IDENTIFICATION, TAB.ROOM, TAB.SCREEN, TAB.CAMERA];

  const finalReview = currentProctoringExam?.exam?.finalReview;

  const handleExamReview = async (idExamInstance, body) => {
    let response;
    try {
      response = await dispatch(
        endpoints[PROCTORING.CREATE.REVIEW.ENDPOINT].initiate({
          idExamInstance,
          body,
        })
      );

      if (response?.error) {
        console.error('response error =', response.error);
      } else {
        console.log('response =', response.data);
      }
    } catch (error) {
      console.error('internal error =', error);
    }
    return response;
  };

  const isTabDisabled = (tab, currentProctoringExam) => {
    const {
      webcamVideoUrl,
      screenVideoUrl,
      roomVideoUrl,
      idImageUrl,
      faceImageUrl,
    } = currentProctoringExam?.urls || {};

    if (tab === TAB.IDENTIFICATION && !idImageUrl && !faceImageUrl) {
      return true;
    } else if (tab === TAB.ROOM && !roomVideoUrl) {
      return true;
    } else if (tab === TAB.SCREEN && !screenVideoUrl) {
      return true;
    } else return tab === TAB.CAMERA && !webcamVideoUrl;
  };

  const Title = ({ tab, count }) => {
    return (
      <>
        <span className="margin-right-8">
          {tab.charAt(0).toUpperCase() + tab.slice(1)}
        </span>
        <span className="label">{count}</span>
      </>
    );
  };

  return (
    <div>
      <div className="display-flex flex-justify-end">
        <Button
          onClick={() => {
            handleExamReview(idExamInstance, {
              reviewedBy: userId,
              review: EXAM_REVIEW.PASS,
              finalViolationMessage: '-',
            });
          }}
          className={finalReview === EXAM_REVIEW.PASS && 'pass-button'}
        >
          <div className="flex-and-align-center">
            <Svg
              name={
                finalReview === EXAM_REVIEW.PASS ? 'thumbsUpWhite' : 'thumbsUp'
              }
            />
            <span className="margin-left-10">
              {intl.formatMessage(messages.pass)}
            </span>
          </div>
        </Button>
        <Button
          onClick={() =>
            dispatch(
              showModal({
                props: {
                  idExamInstance,
                  userId,
                  violations: currentProctoringExam?.violations || [],
                  handleExamReview,
                },
                type: MODAL_TYPES.REJECT_EXAM,
              })
            )
          }
          className={finalReview === EXAM_REVIEW.FAIL && 'fail-button'}
        >
          <div className="flex-and-align-center">
            <Svg
              name={
                finalReview === EXAM_REVIEW.FAIL
                  ? 'thumbsDownWhite'
                  : 'thumbsDown'
              }
            />
            <span className="margin-left-10">
              {intl.formatMessage(messages.fail)}
            </span>
          </div>
        </Button>
      </div>

      {/* Tabs */}
      <TabStrip onSelect={(e) => setTabState(e.selected)} selected={tabState}>
        {tabs.map((tab, i) => (
          <TabStripTab
            key={`ExamPreview-Proctoring-tab-${tab}-${i}`}
            title={
              <Title
                tab={tab}
                count={
                  (
                    currentProctoringExam?.violations?.filter(
                      (violation) => violation.tab === tab
                    ) || []
                  ).length
                }
              />
            }
            disabled={isTabDisabled(tab, currentProctoringExam)}
          >
            <div className="proctoring">
              {tab === TAB.IDENTIFICATION ? (
                <Identification currentProctoringExam={currentProctoringExam} />
              ) : (
                <span className="proctoring">
                  <VideoBox
                    activeTab={tabs[tabState]}
                    currentProctoringExam={currentProctoringExam}
                  />
                  <ViolationsList
                    activeTab={tabs[tabState]}
                    currentProctoringExam={currentProctoringExam}
                  />
                </span>
              )}
            </div>
          </TabStripTab>
        ))}
      </TabStrip>
    </div>
  );
};

export default Proctoring;
