import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { API, API_SEGMENT } from '../../consts/API';
import { EXAM } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const examApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get exam
    [EXAM.GET.ENDPOINT]: builder.query({
      query: (idExamInstance) => ({
        url: `${
          API[API_SEGMENT.MARKING].EXAM
        }/?idExamInstance=${idExamInstance}`,
      }),
    }),
    // Get exam list
    [EXAM.GET_LIST.ENDPOINT]: builder.query({
      query: ({ idExam, queryConfig }) => ({
        url: `${API[API_SEGMENT.MARKING].EXAM_LIST}?${toDataSourceRequestString(
          queryConfig
        )}&idExam=${idExam}`,
      }),
      providesTags: ['GetExamList'],
    }),
    // Get suspicion detection
    [EXAM.GET_SUSPICION_DETECTION.ENDPOINT]: builder.query({
      query: (idExam) => ({
        url: `${
          API[API_SEGMENT.MARKING].EXAM_SUSPICION_DETECTION
        }/?idExam=${idExam}`,
      }),
    }),
    // Suspicion detection
    [EXAM.SUSPICION_DETECTION.ENDPOINT]: builder.mutation({
      query: (idExam) => ({
        body: { idExam },
        method: 'POST',
        url: `${API[API_SEGMENT.MARKING].EXAM_SUSPICION_DETECTION}`,
      }),
    }),
  }),
});

export const endpoints = examApiSlice.endpoints;
