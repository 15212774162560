import {
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
} from '@progress/kendo-react-grid';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import messages from '../../intl/messages';
import { getLocale } from '../../store/ui/uiSlice';
import { LOCALE } from '../../consts/INTL';

export const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
};

/*
TODO - task SC-960 - this method uses a bit hacky way to get around
Kendo's GridColumnMenuCheckboxFilter component inability to set label
apart from data. It should be replaced by proper method in the future,
given the creators of the component will release the new version which
will allow that.
*/
export const ColumnMenuCheckboxFilter = (props, labelsCapitalized) => {
  const intl = useIntl();
  const locale = useSelector(getLocale);
  const isNotEnglish = locale !== LOCALE.EN;
  const labelKey = Object.keys(labelsCapitalized[0]);

  const handleExpandChange = () => {
    if (isNotEnglish) {
      setTimeout(() => {
        const labels = document.querySelectorAll('.k-checkbox-label');
        const [, ...rest] = labels;
        rest.forEach((filter, index) => {
          if (filter.textContent === labelsCapitalized[index][labelKey]) {
            rest[index].textContent = intl.formatMessage(
              messages[labelsCapitalized[index][labelKey].toLowerCase()]
            );
          }
        });
      }, 250);
    }
  };

  return (
    <div>
      <GridColumnMenuCheckboxFilter
        {...props}
        data={labelsCapitalized}
        expanded={true}
        searchBox={() => null}
        onExpandChange={handleExpandChange()}
      />
    </div>
  );
};

export const ColumnMenuMultiselect = (
  allCategories,
  selectedCategories,
  setSelectedCategories
) => {
  const intl = useIntl();
  const [listOfCategories, setListOfCategories] = useState(allCategories);
  const [categories, setCategories] = useState(selectedCategories);

  const handleFilterChange = (event) => {
    setListOfCategories(filterBy(allCategories.slice(), event.filter));
  };

  return (
    <>
      <MultiSelect
        data={listOfCategories}
        dataItemKey="idCategory"
        textField="name"
        value={categories}
        filterable={true}
        onChange={(e) => setCategories(e.value)}
        onFilterChange={(e) => handleFilterChange(e)}
      />
      <div className="display-flex margin-top-8 gap-12">
        <Button
          className="primary margin-left-auto"
          onClick={() => setSelectedCategories([])}
        >
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button
          className="primary"
          onClick={() => setSelectedCategories(categories)}
        >
          {intl.formatMessage(messages.done)}
        </Button>
      </div>
    </>
  );
};
