export const GRID = {
  DEFAULT_DATA_STATE: {
    skip: 0,
    take: 7,
    filter: {
      filters: [],
      logic: 'and',
    },
    sort: [],
  },
  DEFAULT_DATA_STATE_1000: {
    skip: 0,
    take: 1000,
  },
  QUESTIONS_TESTS_DATA_STATE: {
    skip: 0,
    sort: [
      {
        field: 'dateCreatedUtc',
        dir: 'desc',
      },
    ],
    take: 7,
    filter: {
      logic: 'and',
      filters: [],
    },
  },
};

export const KENDO_CONSTANTS = {
  PAGING: {
    buttonCount: 5,
  },
  LANGUAGE: { en: 'en-EN', cs: 'cs-CZ' },
  DATE_TIME_PICKER_FORMAT: { en: 'MM/dd/yyyy hh:mm a', cs: 'd.M.yyyy HH:mm' },
};
