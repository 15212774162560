import { Input } from '@progress/kendo-react-inputs';
import { omit } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { closeModal } from '../../store/modals/modalsSlice';
import { apiSlice } from '../../store/api/apiSlice';
import { validateAndSubmit } from '../../utils';
import ModalTemplate from './ModalTemplate';
import { ORGANIZATION } from '../../consts/RTK';

const UpdateOrganizationModal = ({ organization }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    id: organization.id,
    notification: null,
    body: {
      organizationName: organization?.name || '',
      address: organization?.address || '',
      city: organization?.city || '',
      postalCode: organization?.postalCode || '',
    },
  });

  const intl = useIntl();
  const [updateOrganization, result] = apiSlice[ORGANIZATION.UPDATE.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.updateOrganization} />}
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={() =>
        validateAndSubmit(
          intl,
          () => updateOrganization(omit(['notification'], state)),
          state,
          setState
        )
      }
    >
      {/* Organization name */}
      <strong>{intl.formatMessage(messages.organizationName)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({
            ...state,
            notification: '',
            body: { ...state.body, organizationName: e.target.value },
          })
        }
        placeholder={intl.formatMessage(messages.company)}
        type="text"
        value={state.body.organizationName}
      />
      <br />
      <br />

      {/* Address */}
      <strong>{intl.formatMessage(messages.companyAddress)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({
            ...state,
            notification: '',
            body: { ...state.body, address: e.target.value },
          })
        }
        placeholder={intl.formatMessage(messages.companyAddressStreet)}
        type="text"
        value={state.body.address}
        style={{ marginBottom: '10px' }}
      />
      <br />
      <div className="display-flex">
        <Input
          onChange={(e) =>
            setState({
              ...state,
              notification: '',
              body: { ...state.body, city: e.target.value },
            })
          }
          placeholder={intl.formatMessage(messages.companyAddressCity)}
          type="text"
          value={state.body.city}
        />
        <div style={{ width: '30px' }}></div>
        <Input
          onChange={(e) =>
            setState({
              ...state,
              notification: '',
              body: { ...state.body, postalCode: e.target.value },
            })
          }
          placeholder={intl.formatMessage(messages.companyAddressPostalCode)}
          type="text"
          value={state.body.postalCode}
        />
      </div>
      <br />

      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default UpdateOrganizationModal;
