import Search from '../../Common/Search';
import messages from '../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { CLASSROOM, EXAMINEES } from '../../../consts/RTK';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ColumnMenu } from '../../Common/ColumnFilter';
import { Context } from './PublishEventModal';
import { GRID, KENDO_CONSTANTS } from '../../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { endpoints as classroomsEndpoints } from '../../../store/classrooms/classroomsApiSlice';
import { endpoints as examineesEndpoints } from '../../../store/examinees/examineesApiSlice';
import { formatDate } from '../../../utils';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { without } from 'ramda';
import {
  handleCustomKendoFilter,
  createFullNameOrEmailFilterDescriptor,
} from '../../../filters';
import KendoLocalizator from '../../../layout/KendoLocalizator';

const CheckboxCell = ({ dataItem }) => {
  const { exam, setExam } = useContext(Context);
  const { idExaminee } = dataItem;
  const checked = exam.examinees.includes(idExaminee);

  return (
    <td className="display-flex">
      <Checkbox
        checked={checked}
        onClick={() =>
          setExam({
            ...exam,
            examinees: checked
              ? without([idExaminee], exam.examinees)
              : [idExaminee, ...exam.examinees],
          })
        }
      />
      <span>
        {dataItem.firstName[0]}
        {dataItem.lastName[0]}
      </span>
      <div>
        {dataItem.firstName} {dataItem.lastName}
        <br />
        {dataItem.email}
      </div>
    </td>
  );
};

const CheckboxHeaderCell = ({ students }) => {
  const { exam, setExam } = useContext(Context);
  let checked = true;

  for (const student of students) {
    if (!exam.examinees.includes(student)) checked = false;
  }

  return (
    <span className="k-cell-inner">
      <span className="k-column-title">
        <Checkbox
          checked={checked}
          onClick={() =>
            setExam({
              ...exam,
              examinees: checked
                ? without(students, exam.examinees)
                : [...exam.examinees, ...students],
            })
          }
        />
      </span>
    </span>
  );
};

const StudentsList = ({ setTabState }) => {
  const dispatch = useDispatch();
  const { currentClassroom } = useContext(Context);
  const intl = useIntl();
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);
  const [students, setStudents] = useState([]);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await dispatch(
        currentClassroom
          ? classroomsEndpoints[CLASSROOM.GET_EXAMINEES.ENDPOINT].initiate({
              queryConfig: dataState,
              idGroup: currentClassroom.idGroup,
            })
          : examineesEndpoints[EXAMINEES.GET.ENDPOINT].initiate(dataState)
      );

      setStudents(data.data);
      setTotalCount(data.total);
    })();
  }, [currentClassroom, dataState, dispatch]);

  const handleSearch = (e, dataState, setDataState) => {
    const fullNameOrEmailFilterDescriptor =
      createFullNameOrEmailFilterDescriptor(e.target.value);

    handleCustomKendoFilter(
      dataState,
      setDataState,
      fullNameOrEmailFilterDescriptor,
      GRID.DEFAULT_DATA_STATE
    );
  };

  return (
    <div className="StudentsList">
      {/* Search test by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByNameOrEmail)}
        onChange={(e) => handleSearch(e, dataState, setDataState)}
      />

      {/* Classroom name */}
      {currentClassroom && (
        <h3>
          <Button
            className="plain"
            imageUrl="/images/arrowLeft.svg"
            onClick={() => setTabState(0)}
          />
          {currentClassroom?.name}
        </h3>
      )}

      {/* List */}
      <KendoLocalizator>
        <Grid
          data={students}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageSize={dataState.take}
          pageable={{ buttonCount: KENDO_CONSTANTS.PAGING.buttonCount }}
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={dataState.take}
          total={totalCount}
        >
          <GridColumn
            cell={(p) => <CheckboxCell {...p} />}
            filterable={false}
            headerCell={(p) => (
              <CheckboxHeaderCell
                {...p}
                students={students.map(({ idExaminee }) => idExaminee)}
              />
            )}
            width="50%"
          />

          {/* Created at */}
          <GridColumn
            cell={({ dataItem }) => <td>{formatDate(dataItem.birthDate)}</td>}
            columnMenu={ColumnMenu}
            field="birthDate"
            filter={'date'}
            title={intl.formatMessage(messages.dateOfBirth)}
            width="40%"
          />
        </Grid>
      </KendoLocalizator>
    </div>
  );
};

export default StudentsList;
