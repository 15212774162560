import { LOCALE } from '../../consts/INTL';

// Extended set - Question instruction and distractors
const pluginsExtended = [
  'anchor',
  'autolink',
  'charmap',
  'code',
  'link',
  'lists',
  'nonbreaking',
  'table',
  'wordcount',
];

const toolbarExtended =
  'undo redo | ' +
  'boldText italicText underlineText | ' +
  'alignment | ' +
  'bullist numlist | ' +
  'insertMathTerm insertChemTerm | ' +
  'link unlink | ' +
  'superscript subscript | ' +
  'table code nonbreaking charmap pastetext insertCzechQuotes insertEnglishQuotes';

/*
  TODO look into <math> element which support in Chrome was reinstated after 10 years!!
  Rewriting the default display type for now
*/
const styleExtended = `
  body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }
  boldtext { font-weight: bold; }
  italictext { font-style: italic; }
  underlinetext { text-decoration: underline; }
  math { font-family: 'Times New Roman', Times, serif; font-style: italic; font-weight: bold; font-size: 120%; display: inline; }
  chem { font-family: 'Times New Roman', Times, serif; font-size: 120%; }
`;

const extended = {
  entity_encoding: 'raw',
  height: 300,
  menubar: false,
  language: LOCALE.EN,
  plugins: pluginsExtended,
  toolbar: toolbarExtended,
  toolbar_mode: 'wrap',
  nonbreaking_wrap: false,
  table_use_colgroups: false,
  link_default_target: '_blank',
  content_style: styleExtended,
  icons_url: '/images/ScioLinkCloud/icons.js',
  icons: 'ScioLinkCloud',
  extended_valid_elements: 'boldtext,italictext,underlinetext,math,chem',
  custom_elements: '~boldtext,~italictext,~underlinetext,~math,~chem',
  cleanup_on_startup: false,
  cleanup: false,
  formats: {
    boldtext: { inline: 'boldtext' },
    italictext: { inline: 'italictext' },
    underlinetext: { inline: 'underlinetext' },
    math: { inline: 'math' },
    chem: { inline: 'chem' },
    alignleft: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'left' },
    },
    aligncenter: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'center' },
    },
    alignright: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'right' },
    },
    alignjustify: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'justify' },
    },
  },
  setup: (editor) => {
    editor.ui.registry.addGroupToolbarButton('alignment', {
      icon: 'align-left',
      tooltip: 'Alignment',
      items: 'alignleft aligncenter alignright alignjustify',
    });
    const testElements = (testedElement, exclude) => {
      const elements = [
        'boldtext',
        'italictext',
        'underlinetext',
        'math',
        'chem',
      ];
      const filteredElements = elements.filter((item) => {
        return item !== exclude;
      });
      return !filteredElements.includes(testedElement);
    };
    const customAddButton = (buttonName, icon, tooltip) => {
      editor.ui.registry.addButton(buttonName, {
        icon: icon,
        // TODO translation
        tooltip: tooltip,
        onAction: () => {
          const selectedText = editor.selection.getContent();
          editor.insertContent(`“${selectedText}”`);
        },
      });
    };
    const customAddToggleButton = (buttonName, icon, tooltip, elementName) => {
      editor.ui.registry.addToggleButton(buttonName, {
        icon: icon,
        enabled: true,
        // TODO translation
        tooltip: tooltip,
        onAction: (_) => {
          editor.execCommand('mceToggleFormat', false, elementName);
        },
        onSetup: (buttonApi) => {
          buttonApi.setActive(editor.formatter.match(elementName));
          const changed = editor.formatter.formatChanged(elementName, (state) =>
            buttonApi.setActive(state)
          );
          const editorEventCallback = (eventApi) => {
            const element = eventApi.element.nodeName.toLowerCase();
            buttonApi.setEnabled(testElements(element, elementName));
          };
          editor.on('NodeChange', editorEventCallback);

          return () => {
            editor.off('NodeChange', editorEventCallback);
            changed.unbind();
          };
        },
      });
    };
    customAddButton('insertCzechQuotes', 'quotes-czech', 'Insert Czech quotes');
    customAddButton(
      'insertEnglishQuotes',
      'quotes-english',
      'Insert English quotes'
    );
    customAddToggleButton('boldText', 'bold', 'Bold', 'boldtext');
    customAddToggleButton('italicText', 'italic', 'Italic', 'italictext');
    customAddToggleButton(
      'underlineText',
      'underline',
      'Underline',
      'underlinetext'
    );
    customAddToggleButton('insertMathTerm', 'math', 'Insert math term', 'math');
    customAddToggleButton('insertChemTerm', 'chem', 'Insert chem term', 'chem');
  },
};

export default extended;
