import {
  getIsAdmin,
  getIsOrganizator,
  getIsSuperadmin,
  getUserRoles,
} from './store/auth/authSlice';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ModalRoot from './components/Modals/ModalsRoot';
import Notifications from './components/Notifications';
// import Preloader from './components/Preloader/Preloader';
import { translations } from './intl/intl';
import { useEffect } from 'react';
import { filterRoutesByUserRoles } from './utils';
import TopBarSuper from './components/TopBarSuper/TopBarSuper';
import ROUTES, { loginRedirectionPath } from './consts/ROUTES';
import { omit } from 'ramda';
import { getLocale } from './store/ui/uiSlice';
import './styles/globals.scss';
import { AppTitle } from './components/Common/AppTitle';
import LeftBarTemplate from './components/LeftBarTemplate/LeftBarTemplate';
import AccessForbidden from './components/StaticPages/AccessForbidden';
import Footer from './components/Common/Footer';

const App = () => {
  const isAdmin = useSelector(getIsAdmin);
  const locale = useSelector(getLocale);
  const isOrganizator = useSelector(getIsOrganizator);
  const isSuperadmin = useSelector(getIsSuperadmin);
  const userRoles = useSelector(getUserRoles);
  const isLoggedIn = !!isAdmin || !!isOrganizator || !!isSuperadmin;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLoginPath = pathname === ROUTES.PUBLIC.LOGIN.PATH;
  const isPublicPath = Object.values(omit(['LOGIN'], ROUTES.PUBLIC))
    .map(({ PATH }) => PATH)
    .includes(pathname);

  useEffect(() => {
    if (!isLoggedIn && !isPublicPath)
      // Unauthorized -> Go to Login from anywhere
      navigate(ROUTES.PUBLIC.LOGIN.PATH);
    else if (isLoginPath || pathname === '/')
      // Authorized -> Go to user specific location (if you are still on login page)
      navigate(loginRedirectionPath(userRoles));
  }, [isLoggedIn, isLoginPath, isPublicPath, navigate, pathname, userRoles]);

  const filteredRoutes = filterRoutesByUserRoles(ROUTES, userRoles);
  const filteredPaths = filteredRoutes.map((item) => {
    let path = item.PATH;
    const index = path.indexOf(':');
    if (index !== -1) {
      path = path.slice(0, path.indexOf(':'));
    }
    return pathname.includes(path);
  });
  const hasAccess = filteredPaths.includes(true);

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <AppTitle />
      <ModalRoot />
      <Notifications />
      {/* Preloader temporarily removed - task SC-759 */}
      {/* <Preloader /> */}
      {isSuperadmin && <TopBarSuper />}
      <Routes>
        {hasAccess ? (
          filteredRoutes.map(({ PATH, COMPONENT }, i) => (
            <Route path={PATH} element={COMPONENT} key={`App-${PATH}-${i}`} />
          ))
        ) : pathname !== '/' ? (
          <Route
            path={pathname}
            element={<LeftBarTemplate content={<AccessForbidden />} />}
          />
        ) : null}
      </Routes>
      <Footer className="footer fixed" />
    </IntlProvider>
  );
};

export default App;
