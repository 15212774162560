import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { logOut } from '../../store/auth/authSlice';
import { acronym } from '../../utils';
import './LogOutButton.scss';

const LogOutButton = ({ name }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const handleLogOut = () => {
    dispatch(logOut());
    navigate('/');
  };

  return (
    <DropDownButton
      className="log-out-button"
      text={acronym(name)}
      items={[
        <Button icon="logout" onClick={() => handleLogOut()}>
          {intl.formatMessage(messages.logout)}
        </Button>,
      ]}
    />
  );
};

export default LogOutButton;
