import { useRef, useLayoutEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../intl/messages';

const LabelsCell = ({ dataItem }) => {
  const intl = useIntl();
  const key = useMemo(() => +new Date(), []);
  const cellRef = useRef([]);
  const hiddenCountWidth = 32;
  const cellPadding = 24;
  const margin = 4;

  // TODO: Switch here if needed
  const labels = dataItem.categories
    ? dataItem?.categories?.map(({ name }) => name) ?? []
    : dataItem?.roles?.map((name) => intl.formatMessage(messages[name])) ?? [];

  useLayoutEffect(() => {
    const cellWidth =
      cellRef.current.getBoundingClientRect().width - cellPadding;
    const children = cellRef.current.children;
    const hiddenLabels = [];
    let childIndex = 0;

    if (children.length > 0) {
      let fits = true;
      let totalElementsLength = 0;
      const childrenArray = Array.from(children);

      childrenArray.forEach((child, index) => {
        const elementLength = child.getBoundingClientRect().width + margin;
        totalElementsLength += elementLength;

        if (fits) {
          if (
            totalElementsLength + (index > 0 ? hiddenCountWidth : 0) >
            cellWidth
          ) {
            // check if there are more elements?
            if (children.length > 1) {
              // there are more elements
              // insert hidden counter
              childIndex = index;
              hiddenLabels.push(child.innerText);
            } else {
              // need to cut the text length
              child.innerText = child.innerText.slice(0, -5) + '...';
            }
            fits = false;
          }
        } else {
          // hide all other elements
          hiddenLabels.push(child.innerText);
          child.style.display = 'none';
        }
      });
      if (childIndex > 0) {
        childrenArray[childIndex].className += ' cursor-pointer margin-0';
        childrenArray[childIndex].title = hiddenLabels.join(', ');
        childrenArray[childIndex].innerText = `+${hiddenLabels.length}`;
      }
    }
  }, [dataItem?.idItem, dataItem?.idTest, dataItem?.id]);

  return (
    <td ref={cellRef}>
      {labels.map((name, i) => {
        return (
          <span key={`LabelsCell-${i}=${key}`} className="label">
            {name}
          </span>
        );
      })}
    </td>
  );
};

export default LabelsCell;
