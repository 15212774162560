import { Checkbox, RadioButton } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { CONTENT_WRAPPER } from '../../../../consts/ORGANIZATOR';
import { MODAL_TYPES } from '../../../Modals/ModalsRoot';
import { findIndex, propEq, remove } from 'ramda';
import { showModal } from '../../../../store/modals/modalsSlice';
import messages from '../../../../intl/messages';
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from 'string-strip-html';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { produce } from 'immer';
import './Distractors.scss';

const Distractors = ({
  content,
  id,
  sections,
  settings,
  updateQuestionContent,
  wrapContentWithPolymorphFields,
  disabled,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDistractorCheck = (
    sectionIndex,
    distractorIndex,
    isCorrect,
    elementName
  ) => {
    const elementNameRoot = elementName.split('-')[0];
    const sectionsCopy = produce(content.sections, (draft) => {
      const distractors = draft[sectionIndex].distractors;
      if (elementNameRoot === 'radio') {
        const lastIndex = findIndex(propEq('isCorrect', true))(distractors);
        if (lastIndex >= 0) distractors[lastIndex].isCorrect = false;
      }
      distractors[distractorIndex].isCorrect = !isCorrect;
    });
    const body = {
      ...content,
      sections: sectionsCopy,
    };
    updateQuestionContent({ idItem: id, content: body });
  };

  const handleDistractorAdd = (sectionIndex) => {
    const distractorsCopy = [...content.sections[sectionIndex].distractors];
    distractorsCopy.push({
      content: CONTENT_WRAPPER(),
      isCorrect: false,
    });
    const sectionsCopy = [...content.sections];
    sectionsCopy[sectionIndex] = {
      ...sectionsCopy[sectionIndex],
      distractors: distractorsCopy,
    };
    const body = {
      ...content,
      sections: sectionsCopy,
    };
    updateQuestionContent({ idItem: id, content: body });
  };

  const handleDistractorEdit = (item) =>
    dispatch(
      showModal({
        props: {
          updateQuestionContent,
          wrapContentWithPolymorphFields,
          content,
          idItem: id,
          ...item,
        },
        type: MODAL_TYPES.UPDATE_DISTRACTOR,
      })
    );

  const handleDistractorDelete = (sectionIndex, distractorIndex) => {
    const sectionsCopy = produce(content.sections, (draft) => {
      draft[sectionIndex].distractors = remove(
        distractorIndex,
        1,
        draft[sectionIndex].distractors
      );
    });
    const body = {
      ...content,
      sections: sectionsCopy,
    };
    const payload = wrapContentWithPolymorphFields(body);
    dispatch(
      showModal({
        props: { item: payload },
        type: MODAL_TYPES.DELETE_DISTRACTOR,
      })
    );
  };

  const handleDistractorSort = (sectionIndex, distractorIndex, sorting) => {
    const sectionsCopy = produce(content.sections, (draft) => {
      const distractors = draft[sectionIndex].distractors;
      const currentDistractor = distractors[distractorIndex];
      if (sorting === 'ascending') {
        distractors[distractorIndex] = distractors[distractorIndex - 1];
        distractors[distractorIndex - 1] = currentDistractor;
      }
      if (sorting === 'descending') {
        distractors[distractorIndex] = distractors[distractorIndex + 1];
        distractors[distractorIndex + 1] = currentDistractor;
      }
    });
    const body = {
      ...content,
      sections: sectionsCopy,
    };
    updateQuestionContent({ idItem: id, content: body });
  };

  const distractorContentDescription = (content) => {
    const strippedContent = stripHtml(content).result;

    if (strippedContent.length === 0)
      return intl.formatMessage(messages.answerPlaceholder);
    if (strippedContent.length > 50) {
      return strippedContent.substring(0, 50) + '...';
    }
    return strippedContent;
  };

  return (
    <>
      {sections.map((answerSection, answerSectionIndex) => (
        <div
          key={`Distractors-sections-${answerSectionIndex}`}
          className="sections"
          disabled={disabled}
        >
          {answerSection.distractors.map((distractor, distractorIndex) => (
            <div
              key={`Distractors-distractor-${distractorIndex}`}
              className="distractor display-flex"
            >
              {settings.multipleSelection ? (
                <Checkbox
                  name={`checkbox-distractor-${distractorIndex}`}
                  onChange={(e) =>
                    handleDistractorCheck(
                      answerSectionIndex,
                      distractorIndex,
                      distractor.isCorrect,
                      e.target.name
                    )
                  }
                  checked={distractor.isCorrect}
                />
              ) : (
                <RadioButton
                  name={`radio-distractor-${distractorIndex}`}
                  onClick={(e) => {
                    if (!distractor.isCorrect) {
                      handleDistractorCheck(
                        answerSectionIndex,
                        distractorIndex,
                        distractor.isCorrect,
                        e.target.name
                      );
                    }
                  }}
                  checked={distractor.isCorrect}
                />
              )}
              <div
                className={`distractor-text display-flex margin-0 ${
                  distractor.content ? '' : 'default'
                }`}
              >
                {distractorContentDescription(distractor.content)}
              </div>
              <Button
                size={null}
                imageUrl={'/images/editDarker.svg'}
                imageAlt="pen on a sheet of paper icon"
                onClick={(e) =>
                  handleDistractorEdit({
                    answerId: answerSectionIndex,
                    distractorId: distractorIndex,
                  })
                }
              ></Button>
              <Button
                size={null}
                imageUrl={'/images/arrowUp.svg'}
                imageAlt="arrow pointing up icon"
                onClick={() =>
                  handleDistractorSort(
                    answerSectionIndex,
                    distractorIndex,
                    'ascending'
                  )
                }
                disabled={distractorIndex === 0}
              ></Button>
              <Button
                size={null}
                imageUrl={'/images/arrowDown.svg'}
                imageAlt="arrow pointing down icon"
                className="padding-8"
                onClick={() =>
                  handleDistractorSort(
                    answerSectionIndex,
                    distractorIndex,
                    'descending'
                  )
                }
                disabled={
                  distractorIndex === answerSection.distractors.length - 1
                }
              ></Button>
              <Button
                size={null}
                imageUrl={'/images/trashDarker.svg'}
                imageAlt="thrash bin icon"
                onClick={() =>
                  handleDistractorDelete(answerSectionIndex, distractorIndex)
                }
              ></Button>
            </div>
          ))}
          <Button
            className="margin-top-20"
            onClick={() => handleDistractorAdd(answerSectionIndex)}
            imageUrl={'/images/message.svg'}
            imageAlt="message icon"
          >
            {intl.formatMessage(messages.addAnswer)}
          </Button>
        </div>
      ))}
    </>
  );
};

export default Distractors;
