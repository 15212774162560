import basic from './editors/basic';
import extended from './editors/extended';
import proctoring from './editors/proctoring';

const apiKey = 'd6djood1er54dyga1y3a5nou8lchrkub15be6hcvbscjvwi4';

const EDITOR = {
  API_KEY: apiKey,
  INIT: basic,
};

const EDITOR_EXTENDED = {
  API_KEY: apiKey,
  INIT: extended,
};

const EDITOR_PROCTORING = {
  API_KEY: apiKey,
  INIT: proctoring,
};

export { EDITOR, EDITOR_EXTENDED, EDITOR_PROCTORING };
