import messages from '../../../intl/messages';
import { useIntl } from 'react-intl';
import styles from './NoRecordFound.module.scss';
import { isEmpty } from 'ramda';

export const isNoRecordFound = (list, dataState) =>
  isEmpty(list) && isEmpty(dataState?.filter?.filters?.[0]?.value ?? '');

const NoRecordFound = ({ actionButton, className, icon, intlKey }) => {
  const intl = useIntl();

  return (
    <div className={`${styles['no-record-found']} ${className}`}>
      {/* Icon */}
      <img alt="" src={icon} />

      {/* Title */}
      <h4>
        {intl.formatMessage(messages.noWhateverFound, {
          type: intl.formatMessage(messages[intlKey]).toLowerCase(),
        })}
      </h4>

      {/* Info text */}
      <p>{intl.formatMessage(messages.getStartedWithWhatever)}</p>

      {/* Action button */}
      {actionButton}
    </div>
  );
};

export default NoRecordFound;
