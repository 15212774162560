import AddNewButton from './AddNewButton';
import NoRecordFound from '../../Events/NoRecordFound';
import messages from '../../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { Context } from './TestDetail';
import { equals, findIndex, isEmpty, move, propEq, update } from 'ramda';
import { getTest } from '../../../../store/tests/testsSlice';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { usePrevious } from '../../../../utils';
import { useSelector } from 'react-redux';
import {
  ListBox,
  processListBoxDragAndDrop,
} from '@progress/kendo-react-listbox';

const Question = (props) => {
  const { dataItem, selected, ...rest } = props;
  const {
    currentPageId,
    currentQuestions,
    isDone,
    updateQuestions,
    updateTestContent,
  } = useContext(Context);
  const intl = useIntl();
  const { id } = useParams();
  const test = useSelector(getTest(id));
  const { content } = test || {};
  const i = findIndex(propEq('idItem', dataItem.idItem))(currentQuestions) + 1;

  // Remove question
  const removeQuestion = (questionId) => {
    updateTestContent({
      idTest: id,
      content: {
        ...content,
        pages: update(
          currentPageId,
          {
            ...content.pages[currentPageId],
            items: content.pages[currentPageId].items.filter(
              ({ idItem }) => idItem !== questionId
            ),
          },
          content.pages
        ),
      },
    });
  };

  return (
    <li key={`question-item-${i}`} {...rest}>
      <div className="display-flex gap-12">
        <div>
          <span className="label">{i}</span> Question ({dataItem.idItem})
        </div>
        {!isDone && (
          <div className="display-flex gap-12">
            <Button
              className="plain"
              imageUrl="/images/arrowUp.svg"
              onClick={() =>
                updateQuestions(move(i - 1, i - 2, currentQuestions))
              }
              title={intl.formatMessage(messages.moveUp)}
              disabled={isDone}
            />
            <Button
              className="plain"
              imageUrl="/images/arrowDown.svg"
              onClick={() => updateQuestions(move(i, i - 1, currentQuestions))}
              title={intl.formatMessage(messages.moveDown)}
              disabled={isDone}
            />
            <Button
              className="plain"
              imageUrl="/images/trashDarker.svg"
              onClick={() => removeQuestion(dataItem.idItem)}
              title={intl.formatMessage(messages.delete)}
              disabled={isDone}
            />
            <span className="label">{dataItem.score}</span>
          </div>
        )}
      </div>
      <br />
      <div
        className="item"
        dangerouslySetInnerHTML={{ __html: dataItem.content }}
      />
    </li>
  );
};

const QuestionsList = () => {
  const { currentQuestions, isDone, updateQuestions } = useContext(Context);
  const [listState, setListState] = useState({
    questions: currentQuestions,
    draggedItem: {},
  });
  const prevCurrentQuestions = usePrevious(currentQuestions);

  useEffect(() => {
    if (!equals(prevCurrentQuestions, currentQuestions)) {
      setListState({ ...listState, questions: currentQuestions });
    }
  }, [currentQuestions, listState, prevCurrentQuestions]);

  const onDragStart = ({ dataItem }) =>
    setListState({ ...listState, draggedItem: dataItem });

  const onDrop = ({ dataItem }) => {
    const { listBoxOneData: questions } = processListBoxDragAndDrop(
      [...listState.questions],
      [],
      listState.draggedItem,
      dataItem,
      'idItem'
    );

    updateQuestions(questions);
  };

  if (isEmpty(listState.questions)) {
    // No questions found
    return (
      // No questions found
      <NoRecordFound
        className="no-record-found"
        actionButton={<AddNewButton isDone={isDone} />}
        icon="/images/noTestFound.svg"
        intlKey="question"
      />
    );
  } else {
    return (
      <ListBox
        data={listState.questions}
        textField="idItem"
        item={Question}
        draggable={isDone ? false : true}
        onDragStart={isDone ? undefined : onDragStart}
        onDrop={isDone ? undefined : onDrop}
      />
    );
  }
};

export default QuestionsList;
