import messages from '../../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { MODAL_TYPES } from '../../../Modals/ModalsRoot';
import { showModal } from '../../../../store/modals/modalsSlice';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import { Context } from './TestDetail';

const AddNewButton = ({ isInstruction, isSupportMaterial, isDone }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { content, currentPageId, idTest, pages } = useContext(Context);

  return isSupportMaterial || isInstruction ? (
    <Button
      disabled={isDone}
      onClick={() =>
        dispatch(
          showModal({
            props: {
              content,
              idTest,
              currentPageId,
              pages,
            },
            type: MODAL_TYPES[
              isInstruction ? 'ADD_INSTRUCTIONS' : 'ADD_SUPPORTING_MATERIALS'
            ],
          })
        )
      }
    >
      {intl.formatMessage(
        messages[isInstruction ? 'addInstruction' : 'addSupportingMaterials']
      )}
    </Button>
  ) : (
    <Button
      disabled={isDone}
      onClick={() =>
        dispatch(
          showModal({
            props: {
              content,
              idTest,
              currentPageId,
              pages,
            },
            type: MODAL_TYPES.ADD_QUESTIONS,
          })
        )
      }
    >
      {intl.formatMessage(messages.addNewQuestion)}
    </Button>
  );
};

export default AddNewButton;
