import {
  concat,
  findIndex,
  intersection,
  isEmpty,
  mapObjIndexed,
  nthArg,
  propEq,
  sort,
  without,
} from 'ramda';
import { useEffect, useRef } from 'react';
import messages from './intl/messages';

/************** MISC */

/**
 * Adds or deletes array item
 */
export const addOrDel = (item, arr) =>
  !arr.includes(item) ? concat([item], arr) : without([item], arr);

/**
 * Creates acronym from string
 *
 * @param {str} str - String to be converted into acronym
 * @example
 * acronym('Nikolas Casar'); => NC
 */
export const acronym = (str) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .split(/\b(?=[a-z])/gi)
    .map((token) => token[0])
    .join('')
    .toUpperCase();

/**
 * Reverts array structure to object structure
 *
 * @param {array} arr - Array to be converted into object
 * @param {string} key - Key to be assigned to each array item, default is 'id'
 * @example
 * arrToObjIdAsKey([{ id: 1, name: 'Michal' }, { id: 2, name: 'Martin' }], 'id');
 * => { 1: { id: 1, name: 'Michal' }, 2: { id: 2, name: 'Martin' } };
 */
export const arrToObjIdAsKey = (arr, key = 'id') => {
  const obj = {};
  for (const item of arr) {
    obj[item[key]] = item;
  }
  return obj;
};

const deferred = (ms) => {
  let cancel;
  const promise = new Promise((resolve, reject) => {
    cancel = reject;
    setTimeout(resolve, ms);
  });
  return { promise, cancel };
};

/**
 * Debounce - Features:
 * At most one promise pending at any given time (per debounced task)
 * Stop memory leaks by properly cancelling pending promises
 * Resolve only the latest promise
 *
 * @param {function} task - Task to be exacuted with debounce effect
 * @param {number} ms - Milliseconds of delay the latest taks will be executed
 *
 * Note: debounce depends on a reusable deferred function,
 * which creates a new promise that resolves in milliseconds
 *
 * @example
 * const [handleSave, cancelHandleSave] = debounce((evaluationId, tab, text) => {
    dispatch( updateProctoringEvaluation.initiate({ evaluationId, payload: { tab, text } }) );
  }, 2000);
 */
export const debounce = (task, ms) => {
  let t = { promise: null, cancel: (_) => void 0 };
  return [
    async (...args) => {
      try {
        t.cancel();
        t = deferred(ms);
        await t.promise;
        await task(...args);
      } catch (_) {
        /* prevent memory leak */
      }
    },
    (_) => t.cancel(), // return cancellation mechanism
  ];
};

/**
 * Returns intersection of user roles and route user rights
 *
 * @param {Object} routes - Routes configuration
 * @param {Array} userRoles - Array of user roles
 * @returns {Array} - Filtered array of object entries
 */
export const filterRoutesByUserRoles = (routes, userRoles) => {
  const legalRoutes = [];

  for (const routeSection of Object.values(routes)) {
    for (const route of Object.values(routeSection)) {
      if (
        !route.RIGHTS ||
        !isEmpty(intersection(route.RIGHTS, userRoles ?? []))
      )
        legalRoutes.push(route);
    }
  }

  return legalRoutes;
};

/**
 * Formats date
 *
 * @param {string} date to format
 * @param {string} type of scheme to use
 * @return {string} formated date
 * @example
 * formatDate('2022-03-21T17:35:50.727Z', 's'); => 'Mar 22, 2022'
 */
export const formatDate = (date, type) => {
  switch (type) {
    case 'xs':
      return new Date(date)
        .toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
        .replace(/\//g, '.');
    case 's':
    default:
      return new Date(date).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
  }
};

/**
 * Formats date object ommiting timezone and time part
 *
 * @param {Date} date Date object to format
 * @return {string} formatted date
 * @example
 * new Date(1995,0,1); => '1995-01-01T00:00:00.000Z'
 */
export const isoDateWithoutTimeZone = (date) => {
  if (date == null) return date;
  const timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
  const correctDate = new Date(timestamp);
  correctDate.setUTCHours(0, 0, 0, 0);
  return correctDate.toISOString();
};

/**
 * Simple email validation check
 *
 * @param {string} email - Email to get tested
 * @return {boolean}
 * @example
 * isEmailValid(''); => false | isEmailValid('nikolas.casar'); => false
 * isEmailValid('nikolas.casar@gmail'); => false | isEmailValid('nikolas.casar@gmail.com'); => true
 */
export const isEmailValid = (email) =>
  !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

/**
 * Creates key-value mirrored object
 *
 * @param {object} Object to get mirrored
 * @return {object}
 * @example
 * keyMirror({ KEY: null }); => { KEY: 'KEY' }
 */
export const keyMirror = mapObjIndexed(nthArg(1));

/**
 * LSM - Local storage manager: set, get, delete items from local storage
 * in more secure, automated and generic way
 */
class _LSM {
  _keyList = keyMirror({
    USER: null,
    LOCALE: null,
  });

  get = (key) => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.warn(`error reading ${key} from localStorage`, e);
      return null;
    }
  };

  set = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (e) {
      console.error(`error setting ${key} to localStorage`, e);
      return false;
    }
  };

  delete = (key) => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      console.warn(`error removing ${key} from localStorage`, e);
      return null;
    }
  };

  deleteAll = () => Object.values(this._keyList).map((key) => this.delete(key));

  // User
  getUser = () => this.get(this._keyList.USER);
  setUser = (user) => this.set(this._keyList.USER, user);
  deleteUser = () => this.delete(this._keyList.USER);

  // Locale
  getLocale = () => this.get(this._keyList.LOCALE);
  setLocale = (locale) => this.set(this._keyList.LOCALE, locale);
  deleteLocale = () => this.delete(this._keyList.LOCALE);
}

export const LSM = new _LSM();

/**
 * Convert seconds to time format
 *
 * @param {number} s - seconds
 * @param {bool} wholeFormat - format settings
 * @returns {obj} translations without nested objects
 */
export const msToTime = (s, wholeFormat) => {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;
  mins = mins < 10 ? '0' + mins : mins;
  hrs = hrs < 10 ? '0' + hrs : hrs;
  secs = secs < 10 ? '0' + secs : secs;

  return wholeFormat
    ? hrs + ':' + mins + ':' + secs
    : hrs === '00'
    ? mins + ':' + secs
    : hrs + ':' + mins + ':' + secs;
};

/**
 * Prepare translations of nested objects for react-intl
 *
 * @param {obj} translations with nested objects
 * @returns {obj} translations without nested objects
 */
export const prepareTranslations = (translations) => {
  const prepared = {};
  let keyPath = '';
  const extRegex = new RegExp(/\.[^.]*$/g);

  const iterDeep = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string') {
        prepared[(keyPath ? keyPath + '.' : keyPath) + key] = value;
      } else {
        keyPath += keyPath ? '.' + key : key;
        iterDeep(value);
        keyPath = keyPath.match(extRegex)
          ? keyPath.replace(/\.[^.]*$/, '')
          : '';
      }
    }
  };

  iterDeep(translations);

  return prepared;
};

/**
 * Sorts array of objects by an object property
 *
 * @param {string} prop to sort by
 * @param {array} list to sort
 * @return {array}
 * @example
 * sortArrByObjProp('name', [ { id: 1, name: 'Honzo' }, { id: 2, name: 'Peter' }, { id: 3, name: 'Adam' } ]);
 * => [  { id: 3, name: 'Adam' }, { id: 1, name: 'Honzo' }, { id: 2, name: 'Peter' } ]
 */
export const sortArrByObjProp = (prop, list) =>
  sort((a, b) => a[prop] - b[prop], list);

/**
 * Custom react hook
 *
 * @param {any} value we store as previous state value
 * @return {any} previous value after state changed
 */
export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

/**
 * Validate all form state properties (deep iteration). Execute api call if validation passed,
 * if not, set notification message.
 *
 * @param {func} intl to translate error notifications
 * @param {func} apiCall to execute (if validation passed)
 * @param {object} state to get validated
 * @param {func} setState to set error notification
 */
export const validateAndSubmit = (intl, apiCall, state, setState) => {
  let isValid = true;

  const deepIterator = (target, key) => {
    if (typeof target === 'object') {
      // eslint-disable-next-line guard-for-in
      for (const key in target) {
        deepIterator(target[key], key);
      }
    } else {
      // isEmpty check
      if (key !== 'notification' && isEmpty(target)) {
        setState({
          ...state,
          notification: (
            <span style={{ color: 'red' }}>
              {intl.formatMessage(messages.allFieldsRequired)}.{' '}
            </span>
          ),
        });
        isValid = false;
      }

      // email format check
      if (key === 'email' && !isEmailValid(target)) {
        setState({
          ...state,
          notification: (
            <span style={{ color: 'red' }}>
              {intl.formatMessage(messages.wrongEmailFormat)}.{' '}
            </span>
          ),
        });
        isValid = false;
      }
    }
  };

  deepIterator(state);

  if (isValid) {
    apiCall();
    return true;
  } else {
    return false;
  }
};

/**
 * Finds index of an object with matching property
 *
 * @param {string} property in object to look for
 * @param {any} value of the property to match
 * @param {array} array to search
 * @return {number} returns index of the first matching element or -1 if not found
 * @example
 * const names = [
 *  {name: 'Adam'},
 *  {name: 'Ester'},
 *  {name: 'Ondra'},
 * ];
 * getIndex('name', 'Ester', names); => 1
 */
export const getIndex = (property, value, array) => {
  return findIndex(propEq(property, value))(array);
};

/**
 * Capitalize the string
 *
 * @param {string} string to capitalize
 * @return {string} capitalized string
 * @example
 * capitalize('SOMETHING'); => Something
 */
export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
