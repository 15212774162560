import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';

const AccessForbidden = () => {
  return (
    <section className="display-flex flex-align-center flex-justify-center">
      <div>
        <img
          className="w-48"
          alt="pale red circle with more vivid red cross inside"
          src="/images/accessForbidden.svg"
        />
      </div>
      <h3 className="ff-public-sans-medium font-weight-500">
        <FormattedMessage {...messages.accessForbidden} />
      </h3>
      <p className="margin-0 font-size-14 gray-500">
        <FormattedMessage {...messages.accessForbiddenText} />
      </p>
    </section>
  );
};

export default AccessForbidden;
