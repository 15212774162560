import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { API, API_SEGMENT } from '../../consts/API';
import { EXAMINEE, EXAMINEES } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const examineesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get examinee
    [EXAMINEE.GET.ENDPOINT]: builder.query({
      query: (email) => ({
        url: `${API[API_SEGMENT.TESTING].EXAMINEE}?email=${email}`,
      }),
      providesTags: ['Examinee'],
    }),
    // Invite examinee
    [EXAMINEE.INVITE.ENDPOINT]: builder.mutation({
      query: (examinee) => ({
        url: API[API_SEGMENT.TESTING].EXAMINEE,
        method: 'POST',
        body: examinee,
      }),
      invalidatesTags: ['Examinee', 'Examinees'],
    }),
    // Edit examinee
    [EXAMINEE.UPDATE.ENDPOINT]: builder.mutation({
      query: (examinee) => ({
        url: API[API_SEGMENT.TESTING].EXAMINEE,
        method: 'PUT',
        body: examinee,
      }),
      invalidatesTags: ['Examinees'],
    }),
    // Get org examinees
    [EXAMINEES.GET.ENDPOINT]: builder.query({
      query: (queryConfig) => ({
        url: `${API[API_SEGMENT.TESTING].EXAMINEES}?${toDataSourceRequestString(
          queryConfig
        )}`,
      }),
      providesTags: ['Examinees'],
    }),
    // Get organization examinees
    [EXAMINEES.GET_ORGANIZATION_EXAMINEES.ENDPOINT]: builder.query({
      query: (queryConfig) => ({
        url: `${
          API[API_SEGMENT.TESTING].ORGANIZATION_EXAMINEES
        }?${toDataSourceRequestString(queryConfig)}`,
      }),
    }),
  }),
});

export const endpoints = examineesApiSlice.endpoints;
