import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { invertObj, omit } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { QUESTION_TYPE } from '../../consts/ORGANIZATOR';
import { QUESTION_TYPE_ICONS } from '../../consts/DESIGN';
import ROUTES from '../../consts/ROUTES';
import { QUESTION } from '../../consts/RTK';
import messages from '../../intl/messages';
import { apiSlice } from '../../store/api/apiSlice';
import { closeModal } from '../../store/modals/modalsSlice';
import { validateAndSubmit } from '../../utils';
import ModalTemplate from './ModalTemplate';
import SelectCategories from '../Common/SelectCategories';

const initState = {
  name: '',
  notification: null,
  type: parseInt(invertObj(QUESTION_TYPE)['multipleChoice']),
  categories: [],
};

const CreateQuestionModal = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initState);
  const navigate = useNavigate();
  const intl = useIntl();
  const [createQuestion, result] = apiSlice[QUESTION.CREATE.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  // Fetch all categories
  const { data: allCategories } = apiSlice[QUESTION.GET_CATEGORIES.QUERY](
    null,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(closeModal());
      navigate(`${ROUTES.DASHBOARD.QUESTIONS.PATH}/${result.data.idItem}`);
    }
  }, [dispatch, navigate, result]);

  const radioGroupItems = (questionTypes) => {
    const radioButtons = [];

    Object.entries(questionTypes).forEach((entry) => {
      const [key, value] = entry;
      radioButtons.push({
        label: '',
        value: parseInt(value),
        children: (
          <>
            <div>
              <p>{intl.formatMessage(messages.questionType[key].label)}</p>
              <p>{intl.formatMessage(messages.questionType[key].text)}</p>
            </div>
            <img
              alt={QUESTION_TYPE_ICONS[value].alt}
              src={QUESTION_TYPE_ICONS[value].src}
            />
          </>
        ),
      });
    });

    return radioButtons;
  };

  const questionTypes = omit(
    ['dropdown', 'openText'],
    invertObj(QUESTION_TYPE)
  );
  const radioGroupData = radioGroupItems(questionTypes);

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.createQuestion} />}
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={() =>
        validateAndSubmit(
          intl,
          () => createQuestion(omit(['notification'], state)),
          state,
          setState
        )
      }
    >
      {/* Name */}
      <Label editorId={'create-question-name'}>
        {intl.formatMessage(messages.questionName)}
      </Label>
      <Input
        id={'create-question-name'}
        onChange={(e) =>
          setState({ ...state, notification: '', name: e.target.value })
        }
        placeholder={intl.formatMessage(messages.addQuestionName)}
        type="text"
        value={state.name}
      />

      {/* Categories */}
      <Label editorId={'create-question-categories'}>
        {intl.formatMessage(messages.categories)}
      </Label>
      {/* TODO - Sometimes diacritics are not shown properly, they get hidden when big caps, e.g. "ČeŠtina".
       Higher padding would fix the issue. Fix this when styling ! */}
      <SelectCategories
        data={allCategories}
        placeholder={intl.formatMessage(messages.selectCategory)}
        stateSetter={setState}
        state={state}
      />

      {/* Question type */}
      <Label editorId={'create-question-type'}>
        {intl.formatMessage(messages.selectQuestionType)}
      </Label>
      <RadioGroup
        id={'create-question-type'}
        className="create-question-type"
        data={radioGroupData}
        onChange={(e) =>
          setState({
            ...state,
            notification: '',
            type: e.value,
          })
        }
        value={state.type}
      />
      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default CreateQuestionModal;
