import DropDownButton from '../../Common/DropDownButton';
import Search from '../../Common/Search';
import messages from '../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { CLASSROOM } from '../../../consts/RTK';
import { GRID } from '../../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { apiSlice } from '../../../store/api/apiSlice';
import { capitalize, formatDate } from '../../../utils';
import { getClassroom } from '../../../store/classrooms/classroomsSlice';
import { showModal } from '../../../store/modals/modalsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { endpoints } from '../../../store/examinees/examineesApiSlice';
import { NameCell } from '../../Dashboard/StudentManagement/NameCell.js';
import ExamineeState from '../../Common/ExamineeState';
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from '../../Common/ColumnFilter.js';
import { EXAMINEE_STATE } from '../../../consts/ORGANIZATOR';
import { invertObj, omit } from 'ramda';
import {
  handleCustomKendoFilter,
  createFullNameOrEmailFilterDescriptor,
} from '../../../filters';

const ActionsCell = ({ dataItem }) => {
  const dispatch = useDispatch();
  const { idGroup } = useParams();

  const onDeleteExamineeFromClassroom = () =>
    dispatch(
      showModal({
        props: { item: { ...dataItem, idGroup } },
        type: MODAL_TYPES.DELETE_EXAMINEE_FROM_CLASSROOM,
      })
    );

  return (
    <td>
      <DropDownButton
        onDeleteExamineeFromClassroom={onDeleteExamineeFromClassroom}
      />
    </td>
  );
};

export const InviteExamineeButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { idGroup } = useParams();

  return (
    <Button
      className="primary"
      onClick={() =>
        dispatch(
          showModal({
            type: MODAL_TYPES.INVITE_EXAMINEE,
            props: { idGroup },
          })
        )
      }
      title={intl.formatMessage(messages.inviteExaminee)}
    >
      {intl.formatMessage(messages.inviteExaminee)}
    </Button>
  );
};

const labels = Object.values(
  invertObj(omit(['DELETED', 'DEFAULT'], EXAMINEE_STATE))
);
const labelsCapitalized = labels.map((label) => {
  return { accountState: capitalize(label) };
});

const Classroom = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { idGroup } = useParams();
  const classroom = useSelector(getClassroom(idGroup)) || {};
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);

  // Fetch classroom after page refresh
  useEffect(() => {
    if (!classroom.name)
      dispatch(endpoints[CLASSROOM.GET.ENDPOINT].initiate(idGroup));
  }, [classroom.name, dispatch, idGroup]);

  // Fetch examinees
  const { data } = apiSlice[CLASSROOM.GET_EXAMINEES.QUERY](
    { queryConfig: dataState, idGroup },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const examinees = classroom?.examinees ?? [];

  const handleSearch = (e, dataState, setDataState) => {
    const fullNameOrEmailFilterDescriptor =
      createFullNameOrEmailFilterDescriptor(e.target.value);

    handleCustomKendoFilter(
      dataState,
      setDataState,
      fullNameOrEmailFilterDescriptor,
      GRID.DEFAULT_DATA_STATE
    );
  };

  return (
    <section>
      {/* Classroom name */}
      <h3 className="display-flex flex-justify-between">
        <div className="display-flex gap-12 flex-align-center">
          <Button
            className="plain"
            imageUrl="/images/arrowLeft.svg"
            onClick={() => navigate(-1)}
          />
          {classroom?.name}
        </div>
        <div className="display-flex gap-12 flex-align-center">
          {/*TODO import multiple users 
          <Button
            imageUrl="/images/upload.svg"
            onClick={() => {}}
            title={intl.formatMessage(messages.importMultipleMembers)}
          >
            {intl.formatMessage(messages.importMultipleMembers)}
          </Button>
          */}
          <InviteExamineeButton />
        </div>
      </h3>
      <br />

      {
        <>
          {/* Search test by name */}
          <Search
            className="plain search k-floating-label-container"
            label={intl.formatMessage(messages.searchByNameOrEmail)}
            onChange={(e) => handleSearch(e, dataState, setDataState)}
          />

          {/* List */}
          <Grid
            data={examinees}
            filter={dataState.filter}
            onDataStateChange={(e) => setDataState(e.dataState)}
            pageSize={dataState.take}
            pageable={true}
            skip={dataState.skip}
            sort={dataState.sort}
            sortable={true}
            take={dataState.take}
            total={data?.total ?? 0}
          >
            <GridColumn
              GridColumn
              cell={(p) => <NameCell {...p} />}
              filterable={false}
              field="fullName"
              title={intl.formatMessage(messages.fullName)}
              width="50%"
            />

            {/* Status */}
            <GridColumn
              cell={({ dataItem }) => (
                <td>
                  <ExamineeState state={dataItem.accountState} />
                </td>
              )}
              columnMenu={(props) =>
                ColumnMenuCheckboxFilter(props, labelsCapitalized)
              }
              field="accountState"
              title={intl.formatMessage(messages.status)}
              width="20%"
            />

            {/* Date of Birth */}
            <GridColumn
              cell={({ dataItem }) => <td>{formatDate(dataItem.birthDate)}</td>}
              columnMenu={ColumnMenu}
              field="birthDate"
              title={intl.formatMessage(messages.dateOfBirth)}
              filter="date"
              width="30%"
            />

            {/* Actions */}
            <GridColumn cell={ActionsCell} width="13%" />
          </Grid>
        </>
      }
    </section>
  );
};

export default Classroom;
