import './EventList.scss';
//import DropDownButton from '../../Common/DropDownButton';
import ROUTES from '../../../consts/ROUTES';
import Svg from '../../Common/Svg';
import messages from '../../../intl/messages';
import { EVENT } from '../../../consts/ORGANIZATOR';
import { EVENTS } from '../../../consts/RTK';
import { GRID } from '../../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
//import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { ProgressBar } from '@progress/kendo-react-progressbars';
//import { ScheduleEventButton } from './Events';
import { apiSlice } from '../../../store/api/apiSlice';
import { capitalize, formatDate } from '../../../utils';
import { getEvents } from '../../../store/events/eventsSlice';
//import { isEmpty } from 'ramda';
//import { showModal } from '../../../store/modals/modalsSlice';
import { /*useDispatch,*/ useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useState } from 'react';
/*TODO: edit and delete event
const ActionsCell = ({ dataItem }) => {
  const dispatch = useDispatch();

  const handleDelete = () =>
    dispatch(
      showModal({
        props: { item: dataItem },
        type: MODAL_TYPES.DELETE_EVENT,
      })
    );

  // TODO: Waiting for Api
  const handleUpdate = () => {};

  return (
    <td>
      <DropDownButton onDelete={handleDelete} onUpdate={handleUpdate} />
    </td>
  );
};
*/
const EventsList = ({ filter, eventStage }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const isActiveAndUpcoming = eventStage !== EVENT.STAGE.PAST;
  const isActive = eventStage !== EVENT.STAGE.ACTIVE;
  const activeAndUpcomingEvents = useSelector(getEvents);
  const eventStageCapitalized = capitalize(eventStage);

  // Paging
  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);

  const { data: pastEvents } = apiSlice[
    EVENTS[isActiveAndUpcoming ? 'GET' : 'GET_PAST'].QUERY
  ](
    { ...dataState, ...filter },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const now = +new Date();
  const data = isActiveAndUpcoming
    ? Object.values(activeAndUpcomingEvents).filter(({ fromDateUtc }) =>
        !isActive ? +new Date(fromDateUtc) < now : +new Date(fromDateUtc) > now
      )
    : pastEvents;

  return (
    <div className={`event-list ${eventStage}`}>
      {/* Title */}
      <h3>
        {intl.formatMessage(messages[`eventListTitle${eventStageCapitalized}`])}
      </h3>
      <p>
        {intl.formatMessage(messages[`eventListDesc${eventStageCapitalized}`])}
      </p>

      {/* List */}
      <Grid
        data={data}
        onDataStateChange={(e) => setDataState(e.dataState)}
        pageSize={dataState.take}
        pageable={!isActiveAndUpcoming}
        skip={dataState.skip}
        take={dataState.take}
        total={data?.total ?? 0}
      >
        {/* Name */}
        <GridColumn
          cell={({ dataItem }) => (
            <td
              className="grid-cell-two-lines with-image cursor-pointer"
              onClick={() =>
                navigate(
                  ROUTES.SETTINGS.RESULTS.PATH.replace(
                    ':idExam',
                    dataItem.idExam
                  )
                )
              }
            >
              <Svg name="event" />
              {dataItem.name}
              <div>
                {`${formatDate(dataItem.fromDateUtc)} - ${formatDate(
                  dataItem.toDateUtc
                )}`}
              </div>
            </td>
          )}
          field="examName"
          title={intl.formatMessage(messages.title)}
          width="28%"
        />

        {/* Privacy */}
        <GridColumn
          cell={({ dataItem }) => (
            <td className="privacy">
              <div className="display-flex">
                <img
                  alt=""
                  src={`/images/${dataItem.privacy ? 'public' : 'private'}.svg`}
                />
                {intl.formatMessage(
                  messages[dataItem.privacy ? 'public' : 'private']
                )}
              </div>
            </td>
          )}
          field="isPublicExam"
          title={intl.formatMessage(messages.privacy)}
          width="7%"
        />

        {/* Proctoring */}
        <GridColumn
          cell={({ dataItem }) => (
            <td>
              <span
                className={`label bulletpoint ${
                  dataItem.proctoring ? 'success' : ''
                }`}
              >
                {intl.formatMessage(
                  messages[dataItem.proctoring ? 'active' : 'notSet']
                )}
              </span>
            </td>
          )}
          field="aiStrickness"
          title={intl.formatMessage(messages.proctoring)}
          width="7%"
        />

        {/* Completion rate */}
        <GridColumn
          cell={({ dataItem }) => {
            return (
              <td>
                <ProgressBar
                  className="percentage"
                  value={Math.round(dataItem.completionRate * 100)}
                />
                {Math.round(dataItem.completionRate * 100) + '%'}
              </td>
            );
          }}
          field="completionRate"
          title={intl.formatMessage(messages.completionRate)}
          width="12%"
        />

        {/* Actions - TODO */}
        {/*<GridColumn cell={ActionsCell} width="5%" />*/}
      </Grid>
    </div>
  );
};

export default EventsList;
