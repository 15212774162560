import { useSelector } from 'react-redux';
import { getUser } from '../../store/auth/authSlice';
import LogOutButton from '../Common/LogOutButton';
import './LogOut.scss';

const LogOut = () => {
  const { name, roles, surname } = useSelector(getUser);

  return (
    name && (
      <div className="log-out">
        {`${name} ${surname}`}{' '}
        {roles.map((role, i) => (
          <span
            key={`TopBarSuper-LogOut-${role}-${i}`}
            className="rounded-16 role"
          >
            {role}
          </span>
        ))}
        <LogOutButton name={`${name} ${surname}`} />
      </div>
    )
  );
};

export default LogOut;
