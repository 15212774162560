import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../intl/messages';

export const AppTitle = () => {
  const intl = useIntl();

  useEffect(() => {
    document.title = intl.formatMessage(messages.appTitle);
  }, [intl]);
};
