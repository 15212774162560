import { EVENT, EVENTS } from '../../consts/RTK';
import { arrToObjIdAsKey } from '../../utils';
import { createSlice } from '@reduxjs/toolkit';
import { curry } from 'ramda';
import { endpoints } from './eventsApiSlice';
import { v4 as uuidv4 } from 'uuid';

const eventsSlice = createSlice({
  name: 'events',
  initialState: [],
  reducers: {
    // TODO - Delete when apis ready, replace with mutation
    deleteEvent: (state, { payload: id }) => {
      Reflect.deleteProperty(state, id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        endpoints[EVENT.CREATE.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          // TODO: Redo after api sends event id
          const idExam = uuidv4();

          state[idExam] = {
            completionRate: 0,
            fromDateUtc: meta.arg.originalArgs.examSettings.startDay,
            toDateUtc: meta.arg.originalArgs.examSettings.endDay,
            idExam,
            name: meta.arg.originalArgs.examSettings.examName,
            privacy: meta.arg.originalArgs.examSettings.isPublicExam,
            proctoring: meta.arg.originalArgs.proctoringSettings ? true : false,
          };
        }
      )
      .addMatcher(
        endpoints[EVENTS.GET.ENDPOINT].matchFulfilled,
        (state, { payload }) => arrToObjIdAsKey(payload, 'idExam')
      );
  },
});

export const { createUpdateEvent, deleteEvent } = eventsSlice.actions;

export const getEvent = curry(
  (eventId, state) => state.events?.[eventId] ?? {}
);

export default eventsSlice.reducer;

export const getEvents = (state) => state.events;
