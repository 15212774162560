//TODO - import Account from '../components/Settings/Account';
import Billing from '../components/Settings/Billing';
import Events from '../components/Dashboard/Events/Events';
import LeftBarTemplate from '../components/LeftBarTemplate/LeftBarTemplate';
import Login from '../components/Login/Login';
import Members from '../components/Settings/Members';
import OrganizationSettings from '../components/Settings/OrganizationSetings/OrganizationSettings';
import Organizations from '../components/Organizations/Organizations';
import QuestionDetail from '../components/Dashboard/Questions/QuestionDetail/QuestionDetail';
import Questions from '../components/Dashboard/Questions/Questions';
import Results from '../components/Settings/Results/Results';
import StudentManagement from '../components/Dashboard/StudentManagement/StudentManagement';
import TestDetail from '../components/Dashboard/Tests/TestDetail/TestDetail';
import Tests from '../components/Dashboard/Tests/Tests';
import messages from '../intl/messages';
import { FormattedMessage } from 'react-intl';
import { USER_ROLES } from './API';
import Public from '../layout/Public';
import Faq from '../components/StaticPages/Faq';
import Terms from '../components/StaticPages/Terms';
import Privacy from '../components/StaticPages/Privacy';
import Cookies from '../components/StaticPages/Cookies';

const ROUTES_PUBLIC_PATH = process.env.ROUTES_PUBLIC_PATH ?? '/';

const ROUTES = {
  DASHBOARD: {
    CREATE_UPDATE_TEST: {
      CATEGORY: null,
      COMPONENT: <TestDetail />,
      PATH: `${ROUTES_PUBLIC_PATH}dashboard/tests/:id`,
      RIGHTS: [USER_ROLES.AUTHOR, USER_ROLES.EXPERT],
      TITLE: null,
    },
    QUESTIONS: {
      CATEGORY: <FormattedMessage {...messages.create} />,
      COMPONENT: <LeftBarTemplate content={<Questions />} />,
      PATH: `${ROUTES_PUBLIC_PATH}dashboard/questions`,
      RIGHTS: [USER_ROLES.AUTHOR, USER_ROLES.EXPERT],
      TITLE: <FormattedMessage {...messages.questions} />,
    },
    QUESTION_DETAIL: {
      CATEGORY: null,
      COMPONENT: <QuestionDetail />,
      PATH: `${ROUTES_PUBLIC_PATH}dashboard/questions/:id`,
      RIGHTS: [USER_ROLES.AUTHOR, USER_ROLES.EXPERT],
      TITLE: <FormattedMessage {...messages.addQuestion} />,
    },
    TESTS: {
      CATEGORY: <FormattedMessage {...messages.create} />,
      COMPONENT: <LeftBarTemplate content={<Tests />} />,
      PATH: `${ROUTES_PUBLIC_PATH}dashboard/tests`,
      RIGHTS: [USER_ROLES.AUTHOR, USER_ROLES.EXPERT],
      TITLE: <FormattedMessage {...messages.tests} />,
    },
    EVENTS: {
      CATEGORY: <FormattedMessage {...messages.schedule} />,
      COMPONENT: <LeftBarTemplate content={<Events />} />,
      PATH: `${ROUTES_PUBLIC_PATH}dashboard/events`,
      RIGHTS: [USER_ROLES.EVENT_MANAGER, USER_ROLES.EXPERT],
      TITLE: <FormattedMessage {...messages.events} />,
    },
  },
  ORGANIZATIONS: {
    ROOT: {
      CATEGORY: null,
      COMPONENT: <Organizations />,
      PATH: `${ROUTES_PUBLIC_PATH}organizations`,
      RIGHTS: [USER_ROLES.SUPERADMIN],
      TITLE: null,
    },
    ORGANIZATION_DETAIL: {
      CATEGORY: null,
      COMPONENT: <Organizations />,
      PATH: `${ROUTES_PUBLIC_PATH}organizations/:id`,
      RIGHTS: [USER_ROLES.SUPERADMIN],
      TITLE: null,
    },
  },
  PUBLIC: {
    LOGIN: {
      CATEGORY: null,
      COMPONENT: (
        <Public>
          <Login />
        </Public>
      ),
      PATH: `${ROUTES_PUBLIC_PATH}login`,
      RIGHTS: null,
    },
    CHECK_EMAIL: {
      CATEGORY: null,
      COMPONENT: (
        <Public>
          <Login />
        </Public>
      ),
      PATH: `${ROUTES_PUBLIC_PATH}check-your-email`,
      RIGHTS: null,
    },
    FORGOTTEN_PASSWORD: {
      CATEGORY: null,
      COMPONENT: (
        <Public>
          <Login />
        </Public>
      ),
      PATH: `${ROUTES_PUBLIC_PATH}forgotten-password`,
      RIGHTS: null,
    },
    FAQ: {
      CATEGORY: null,
      COMPONENT: (
        <Public>
          <Faq />
        </Public>
      ),
      PATH: `${ROUTES_PUBLIC_PATH}faq`,
      RIGHTS: null,
    },
    TERMS: {
      CATEGORY: null,
      COMPONENT: (
        <Public>
          <Terms />
        </Public>
      ),
      PATH: `${ROUTES_PUBLIC_PATH}terms`,
      RIGHTS: null,
    },
    PRIVACY: {
      CATEGORY: null,
      COMPONENT: (
        <Public>
          <Privacy />
        </Public>
      ),
      PATH: `${ROUTES_PUBLIC_PATH}privacy`,
      RIGHTS: null,
    },
    COOKIES: {
      CATEGORY: null,
      COMPONENT: (
        <Public>
          <Cookies />
        </Public>
      ),
      PATH: `${ROUTES_PUBLIC_PATH}cookies`,
      RIGHTS: null,
    },
  },
  SETTINGS: {
    /*  ACCOUNT: { 
      CATEGORY: <FormattedMessage {...messages.settings} />,
      COMPONENT: <LeftBarTemplate content={<Account />} />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/account`,
      RIGHTS: [USER_ROLES.ADMIN],
      TITLE: <FormattedMessage {...messages.account} />,
    }, TODO SC-686 */
    ORGANIZATION_SETTINGS: {
      CATEGORY: <FormattedMessage {...messages.settings} />,
      COMPONENT: <LeftBarTemplate content={<OrganizationSettings />} />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/organization-settings`,
      RIGHTS: [USER_ROLES.ADMIN],
      TITLE: <FormattedMessage {...messages.organizationSettings} />,
    },
    BILLING: {
      CATEGORY: <FormattedMessage {...messages.settings} />,
      COMPONENT: <LeftBarTemplate content={<Billing />} />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/billing`,
      RIGHTS: [USER_ROLES.ADMIN],
      TITLE: <FormattedMessage {...messages.billing} />,
    },
    CLASSROOMS: {
      CATEGORY: <FormattedMessage {...messages.settings} />,
      COMPONENT: <LeftBarTemplate content={<StudentManagement />} />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/student-management/`,
      RIGHTS: [USER_ROLES.ADMIN],
      TITLE: <FormattedMessage {...messages.studentManagement} />,
    },
    CLASSROOMS_DETAIL: {
      CATEGORY: null,
      COMPONENT: <LeftBarTemplate content={<StudentManagement />} />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/student-management/:idGroup`,
      RIGHTS: [USER_ROLES.ADMIN],
      TITLE: null,
    },
    EXAM_PREVIEW: {
      CATEGORY: null,
      COMPONENT: <Results />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/results/:idExam/:idExamInstance`,
      RIGHTS: [USER_ROLES.ADMIN, USER_ROLES.EVENT_MANAGER],
      TITLE: <FormattedMessage {...messages.answersReview} />,
    },
    MEMBERS: {
      CATEGORY: <FormattedMessage {...messages.settings} />,
      COMPONENT: <LeftBarTemplate content={<Members />} />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/members`,
      RIGHTS: [USER_ROLES.ADMIN],
      TITLE: <FormattedMessage {...messages.members} />,
    },
    RESULTS: {
      CATEGORY: null,
      COMPONENT: <Results />,
      PATH: `${ROUTES_PUBLIC_PATH}settings/results/:idExam`,
      RIGHTS: [USER_ROLES.ADMIN, USER_ROLES.EVENT_MANAGER],
      TITLE: <FormattedMessage {...messages.results} />,
    },
  },
};

export default ROUTES;

export const loginRedirectionPath = (userRoles) => {
  switch (true) {
    // ADMIN
    case userRoles.includes(USER_ROLES.ADMIN):
      return ROUTES.SETTINGS.ORGANIZATION_SETTINGS.PATH;
    // EVENT_MANAGER
    case userRoles.includes(USER_ROLES.EVENT_MANAGER):
      return ROUTES.DASHBOARD.EVENTS.PATH;
    // SUPERADMIN
    case userRoles.includes(USER_ROLES.SUPERADMIN):
      return ROUTES.ORGANIZATIONS.ROOT.PATH;
    // ORGANIZATOR
    default:
      return ROUTES.DASHBOARD.QUESTIONS.PATH;
  }
};
