export const handleCustomKendoFilter = (
  dataState,
  setDataState,
  customFilterDescriptor,
  initialDataState
) => {
  const gridFilters = dataState.filter.filters.filter(
    (x) => x.id !== customFilterDescriptor.id
  );
  const newFilters = [...gridFilters, customFilterDescriptor];

  const extendedFilter = {
    logic: 'and',
    filters: newFilters,
  };

  setDataState({
    ...initialDataState,
    filter: extendedFilter,
  });
};

export const createNameFilterDescriptor = (filterValue) => {
  return {
    id: 'name',
    logic: 'and',
    filters: [
      {
        field: 'name',
        operator: 'contains',
        value: filterValue,
      },
    ],
  };
};

export const createFullNameOrEmailFilterDescriptor = (filterValue) => {
  return {
    id: 'fullNameOrEmailFilterDescriptor',
    logic: 'or',
    filters: [
      {
        field: 'fullName',
        operator: 'contains',
        value: filterValue,
      },
      {
        field: 'email',
        operator: 'contains',
        value: filterValue,
      },
    ],
  };
};
