import { createSlice } from '@reduxjs/toolkit';
import { BILLING } from '../../consts/RTK';
import { arrToObjIdAsKey } from '../../utils';
import { endpoints } from './billingApiSlice';

const initialState = { invoices: {} };

const billingSlice = createSlice({
  name: 'billing',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      endpoints[BILLING.GET.INVOICES.ENDPOINT].matchFulfilled,
      (state, { payload }) => {
        state.invoices = arrToObjIdAsKey([...payload], 'invoiceId');
      }
    );
  },
});

export default billingSlice.reducer;

export const getInvoices = (state) => state?.billing?.invoices ?? {};
