import QuestionsList from './QuestionsList';
import messages from '../../../intl/messages.js';
import { MODAL_TYPES } from '../../Modals/ModalsRoot.js';
import { useIntl } from 'react-intl';
import CreateButton from '../../Common/CreateButton.js';

const Questions = () => {
  const intl = useIntl();

  return (
    <section>
      {/* Title + controls */}
      <h2>
        {intl.formatMessage(messages.questions)}
        <CreateButton type={MODAL_TYPES.CREATE_QUESTION} />
      </h2>
      <QuestionsList />
    </section>
  );
};

export default Questions;
