import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../store/modals/modalsSlice';
import { apiSlice } from '../../store/api/apiSlice';
import { Editor } from '@tinymce/tinymce-react';
import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';
import ModalTemplate from './ModalTemplate';
import { EDITOR_EXTENDED } from '../../consts/EDITOR';
import { CONTENT_WRAPPER } from '../../consts/ORGANIZATOR';
import { QUESTION } from '../../consts/RTK';
import { produce } from 'immer';

const UpdateDistractorModal = (props) => {
  const dispatch = useDispatch();
  const editorDistractorRef = useRef();
  const [updateQuestionContent, result] =
    apiSlice[QUESTION.UPDATE.QUESTION_CONTENT.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  const distractors = props.content.sections[props.answerId].distractors;
  const currentDistractor = props.distractorId;
  const wrapContentWithPolymorphFields = props.wrapContentWithPolymorphFields;

  const handleUpdateDistractorContent = () => {
    const editorText = editorDistractorRef.current?.editor.getContent();
    const textWrapped = CONTENT_WRAPPER(editorText);
    const sectionsCopy = produce(props.content.sections, (draft) => {
      draft[props.answerId].distractors[currentDistractor].content =
        textWrapped;
    });
    const body = {
      ...props.content,
      sections: sectionsCopy,
    };
    const payload = wrapContentWithPolymorphFields(body);
    updateQuestionContent(payload);
  };

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.updateAnswer} />}
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={() => handleUpdateDistractorContent()}
    >
      <Editor
        id="editor-distractor"
        apiKey={EDITOR_EXTENDED.API_KEY}
        init={EDITOR_EXTENDED.INIT}
        ref={editorDistractorRef}
        initialValue={distractors[currentDistractor].content}
      />
      {/* Notifications */}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default UpdateDistractorModal;
