import messages from '../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { CALCULATOR, EVENT } from '../../../consts/ORGANIZATOR';
import { KENDO_CONSTANTS } from '../../../consts/KENDO';
import { LOCALE } from '../../../consts/INTL';
import { Checkbox, Input, Switch } from '@progress/kendo-react-inputs';
import { Context } from './PublishEventModal';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Required from '../../Common/Required';
import { isEmpty } from 'ramda';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

const CheckboxRows = ({ description, rows }) => {
  const { exam, setExam } = useContext(Context);
  const intl = useIntl();

  return rows.map((row) => (
    <div
      className="display-flex with-image"
      key={`PublishEventModal-ScheduleEvent-rules-${row}`}
    >
      <img alt="" src={`/images/${row}.svg`} />
      <div>
        {intl.formatMessage(messages[row])}
        {description && <p>{intl.formatMessage(messages[`${row}Text`])}</p>}
      </div>
      <Checkbox
        checked={exam[row]}
        onClick={() => setExam({ ...exam, [row]: !exam[row] })}
      />
    </div>
  ));
};

const ScheduleEvent = () => {
  const { exam, locale, progress, setProgress, setExam, errorMessages } =
    useContext(Context);
  const intl = useIntl();

  const handleSwitchChange = (switchName, switchValue) => {
    setExam({
      ...exam,
      [switchName]: switchValue,
    });
  };

  const togglePrivacy = () =>
    setExam({
      ...exam,
      isPublicExam: !exam.isPublicExam,
    });

  const testTypeDropDownListData = EVENT.TYPE.map((value) => ({
    id: value.id,
    text: intl.formatMessage(messages[value.messageKey]),
  }));

  const calculatorDropDownListData = CALCULATOR.map((value) => ({
    id: value.id,
    text: intl.formatMessage(messages[value.messageKey]),
  }));

  // error message
  const renderErrorMessage = (name) => {
    const message = errorMessages.map((value) => {
      if (name === value.name) {
        return <div className="error-message">{value.message}</div>;
      }
    });
    return message;
  };

  const dateTimePickerFormat =
    KENDO_CONSTANTS.DATE_TIME_PICKER_FORMAT[locale ?? LOCALE.EN];

  // Enable continue button
  useEffect(() => {
    setProgress({
      ...progress,
      allowContinue:
        // !isEmpty(exam.examName) &&
        exam.startDay &&
        exam.endDay &&
        // exam.startDay < exam.endDay &&
        exam.examType !== null &&
        (!exam.isResilienceTime ||
          exam.resilienceTime.h > 0 ||
          exam.resilienceTime.m > 0) &&
        (!exam.isTestTimeLimit ||
          exam.testTimeLimit.h > 0 ||
          exam.testTimeLimit.m > 0) &&
        (!exam.isInstructionTimeLimit ||
          exam.instructionTimeLimit.h > 0 ||
          exam.instructionTimeLimit.m > 0),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    exam.endDay,
    exam.examName,
    exam.examType,
    exam.startDay,
    exam.isResilienceTime,
    exam.resilienceTime.h,
    exam.resilienceTime.m,
    exam.isTestTimeLimit,
    exam.testTimeLimit.h,
    exam.testTimeLimit.m,
    exam.isInstructionTimeLimit,
    exam.instructionTimeLimit.h,
    exam.instructionTimeLimit.m,
    setProgress,
  ]);

  return (
    <div className="schedule-event">
      <div>
        {/* Name */}
        {intl.formatMessage(messages.eventName)}
        <Required />
        <Input
          onChange={(e) => setExam({ ...exam, examName: e.target.value })}
          value={exam.examName}
          placeholder={intl.formatMessage(messages.enterEventName)}
          name="examName"
        />
        {renderErrorMessage('examName')}
      </div>
      <div className="display-flex gap-16">
        {/* Start day */}
        <div>
          {intl.formatMessage(messages.startDay)}
          <Required />
          <DateTimePicker
            onChange={(e) => setExam({ ...exam, startDay: e.value })}
            value={!!exam.startDay ? new Date(exam.startDay) : null}
            placeholder={intl.formatMessage(messages.selectDay)}
            format={dateTimePickerFormat}
            name="startDay"
          />
          {renderErrorMessage('startDay')}
        </div>

        {/* End day */}
        <div>
          {intl.formatMessage(messages.endDay)}
          <Required />
          <DateTimePicker
            onChange={(e) => setExam({ ...exam, endDay: e.value })}
            value={!!exam.endDay ? new Date(exam.endDay) : null}
            placeholder={intl.formatMessage(messages.selectDay)}
            format={dateTimePickerFormat}
            name="endDay"
          />
          {renderErrorMessage('endDay')}
        </div>
      </div>
      {renderErrorMessage('endDayBeforeStartDay')}
      {/* Privacy */}
      <div>
        {intl.formatMessage(messages.privacy)}
        <div className="display-flex switch">
          <Button
            className={!exam.isPublicExam ? 'active' : 'inactive'}
            onClick={togglePrivacy}
          >
            {intl.formatMessage(messages.private)}
          </Button>
          <Button
            className={exam.isPublicExam ? 'active' : 'inactive'}
            onClick={togglePrivacy}
          >
            {intl.formatMessage(messages.public)}
          </Button>
        </div>
      </div>
      {/* Test type */}
      <div>
        {intl.formatMessage(messages.selectTestType)}
        <DropDownList
          data={testTypeDropDownListData}
          textField="text"
          dataItemKey="id"
          value={
            exam.examType
              ? testTypeDropDownListData.filter(
                  (v) => v.id === exam.examType
                )[0]
              : testTypeDropDownListData[0]
          }
          onChange={(e) => setExam({ ...exam, examType: e.value.id })}
        />
      </div>

      <div>
        <hr />
      </div>

      <div>
        {intl.formatMessage(messages.timeSettings)}
        <p>{intl.formatMessage(messages.timeSettingsText)}</p>
      </div>

      <div className="display-flex">
        <div>
          {intl.formatMessage(messages.resilienceTime)}
          <p>{intl.formatMessage(messages.resilienceTimeText)}</p>
        </div>
        <div className="display-flex">
          <Input
            type="number"
            label="Hours"
            onChange={(e) =>
              setExam({
                ...exam,
                resilienceTime: {
                  ...exam.resilienceTime,
                  h: Number(e.target.value),
                },
              })
            }
            value={exam.resilienceTime.h}
            disabled={!exam.isResilienceTime}
            min={0}
            max={100}
          />
          :
          <Input
            label="Minutes"
            onChange={(e) =>
              setExam({
                ...exam,
                resilienceTime: {
                  ...exam.resilienceTime,
                  m: Number(e.value),
                },
              })
            }
            type="number"
            value={exam.resilienceTime.m}
            disabled={!exam.isResilienceTime}
            min={0}
            max={300}
          />
        </div>
        <div className="switcher-column">
          <Switch
            name="isResilienceTime"
            onChange={(e) => handleSwitchChange(e.target.props.name, e.value)}
            size="small"
            onLabel={''}
            offLabel={''}
            checked={exam.isResilienceTime}
          />
        </div>
      </div>

      <div className="display-flex">
        <div>
          {intl.formatMessage(messages.testTimeLimit)}
          <p>{intl.formatMessage(messages.testTimeLimitText)}</p>
        </div>
        <div className="display-flex">
          <Input
            type="number"
            label="Hours"
            onChange={(e) =>
              setExam({
                ...exam,
                testTimeLimit: {
                  ...exam.testTimeLimit,
                  h: Number(e.target.value),
                },
              })
            }
            value={exam.testTimeLimit.h}
            disabled={!exam.isTestTimeLimit}
            min={0}
            max={100}
          />
          :
          <Input
            label="Minutes"
            onChange={(e) =>
              setExam({
                ...exam,
                testTimeLimit: {
                  ...exam.testTimeLimit,
                  m: Number(e.value),
                },
              })
            }
            type="number"
            value={exam.testTimeLimit.m}
            disabled={!exam.isTestTimeLimit}
            min={0}
            max={300}
          />
        </div>
        <div className="switcher-column">
          <Switch
            name="isTestTimeLimit"
            onChange={(e) => handleSwitchChange(e.target.props.name, e.value)}
            size="small"
            onLabel={''}
            offLabel={''}
            checked={exam.isTestTimeLimit}
          />
        </div>
      </div>

      <div className="display-flex">
        <div>
          {intl.formatMessage(messages.instructionTimeLimit)}
          <p>{intl.formatMessage(messages.instructionTimeLimitText)}</p>
        </div>
        <div className="display-flex">
          <Input
            type="number"
            label="Hours"
            onChange={(e) =>
              setExam({
                ...exam,
                instructionTimeLimit: {
                  ...exam.instructionTimeLimit,
                  h: Number(e.target.value),
                },
              })
            }
            value={exam.instructionTimeLimit.h}
            disabled={!exam.isInstructionTimeLimit}
            min={0}
            max={100}
          />
          :
          <Input
            label="Minutes"
            onChange={(e) =>
              setExam({
                ...exam,
                instructionTimeLimit: {
                  ...exam.instructionTimeLimit,
                  m: Number(e.value),
                },
              })
            }
            type="number"
            value={exam.instructionTimeLimit.m}
            disabled={!exam.isInstructionTimeLimit}
            min={0}
            max={300}
          />
        </div>
        <div className="switcher-column">
          <Switch
            name="isInstructionTimeLimit"
            onChange={(e) => handleSwitchChange(e.target.props.name, e.value)}
            size="small"
            onLabel={''}
            offLabel={''}
            checked={exam.isInstructionTimeLimit}
          />
        </div>
      </div>

      <div>
        <hr />
      </div>

      <div>
        {intl.formatMessage(messages.tools)}
        <p>{intl.formatMessage(messages.toolsText)}</p>
      </div>

      <div className="display-flex with-image">
        <img alt="" src="/images/calculator.svg" />
        <div>
          {intl.formatMessage(messages.calculator)}
          <p>{intl.formatMessage(messages.calculatorText)}</p>
        </div>
        <DropDownList
          data={calculatorDropDownListData}
          textField="text"
          dataItemKey="id"
          onChange={(e) => setExam({ ...exam, calculatorVersion: e.value.id })}
          value={
            exam.calculatorVersion
              ? calculatorDropDownListData.filter(
                  (v) => v.id === exam.calculatorVersion
                )[0]
              : calculatorDropDownListData[0]
          }
        />
      </div>

      <CheckboxRows description rows={Object.values(EVENT.EXAM.TOOLS)} />

      <div>
        <hr />
      </div>

      <div>
        {intl.formatMessage(messages.rules)}
        <p>{intl.formatMessage(messages.rulesText)}</p>
      </div>

      <CheckboxRows rows={Object.values(EVENT.EXAM.RULES)} />
    </div>
  );
};

export default ScheduleEvent;
