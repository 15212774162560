import CreateButton from '../../Common/CreateButton.js';
import DropDownButton from '../../Common/DropDownButton.js';
import LabelsCell from '../../Common/LabelsCell.js';
import NoRecordFound from '../Events/NoRecordFound.js';
import ROUTES from '../../../consts/ROUTES';
import Row from '../../Common/SelectedGridRow.js';
import Search from '../../Common/Search';
import ItemState from '../../Common/ItemState';
import messages from '../../../intl/messages';
import {
  ColumnMenu,
  ColumnMenuMultiselect,
  ColumnMenuCheckboxFilter,
} from '../../Common/ColumnFilter';
import { Context } from '../../Modals/PublishEventModal/PublishEventModal.js';
import { GRID } from '../../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { MODAL_TYPES } from '../../Modals/ModalsRoot';
import { QUESTION, QUESTIONS } from '../../../consts/RTK.js';
import { QUESTION_TYPE_ICONS } from '../../../consts/DESIGN.js';
import { apiSlice } from '../../../store/api/apiSlice.js';
import { capitalize, formatDate } from '../../../utils';
import { showModal } from '../../../store/modals/modalsSlice';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { invertObj, omit } from 'ramda';
import {
  handleCustomKendoFilter,
  createNameFilterDescriptor,
} from '../../../filters';
import { QUESTION_STATE } from '../../../consts/ORGANIZATOR';
import KendoLocalizator from '../../../layout/KendoLocalizator.js';

const ActionsCell = ({ dataItem }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () =>
    dispatch(
      showModal({
        props: { item: dataItem },
        type: MODAL_TYPES.DELETE_QUESTION,
      })
    );

  const handleUpdate = () =>
    navigate(`${ROUTES.DASHBOARD.QUESTIONS.PATH}/${dataItem.idItem}`);

  return (
    <td>
      <DropDownButton onDelete={handleDelete} onUpdate={handleUpdate} />
    </td>
  );
};

const QuestionsList = () => {
  const navigate = useNavigate();
  const { idItem } = useContext(Context) ?? { idItem: null };
  const intl = useIntl();
  const [dataState, setDataState] = useState(GRID.QUESTIONS_TESTS_DATA_STATE);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const categoryIds =
    selectedCategories.length > 0
      ? selectedCategories.map((item) => item.idCategory)
      : undefined;

  const { data } = apiSlice[QUESTIONS.GET.QUERY](
    { dataState, categoryIds },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: questionsCount } = apiSlice[QUESTIONS.GET_COUNT.QUERY]({
    refetchOnMountOrArgChange: true,
  });
  const { data: allCategories } = apiSlice[QUESTION.GET_CATEGORIES.QUERY](
    null,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const questions = data?.data ?? [];

  // can't use Memo to make it unique every time
  const key = +new Date();

  const handleSearch = (e, dataState, setDataState) => {
    const nameFilterDescriptor = createNameFilterDescriptor(e.target.value);

    handleCustomKendoFilter(
      dataState,
      setDataState,
      nameFilterDescriptor,
      GRID.QUESTIONS_TESTS_DATA_STATE
    );
  };

  const labels = Object.values(
    invertObj(omit(['DEACTIVATED'], QUESTION_STATE))
  );
  const labelsCapitalized = labels.map((label) => {
    return { state: capitalize(label) };
  });

  return questionsCount === 0 ? (
    // No questions found
    <NoRecordFound
      actionButton={<CreateButton type={MODAL_TYPES.CREATE_QUESTION} />}
      icon="/images/noQuestionFound.svg"
      intlKey="question"
    />
  ) : (
    // Questions list
    <>
      {/* Search question by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByName)}
        onChange={(e) => handleSearch(e, dataState, setDataState)}
      />
      <KendoLocalizator>
        <Grid
          // force to clear React cache and recalculate categories
          key={`QuestionsListGrid=${key}`}
          data={questions}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageSize={dataState.take}
          pageable={true}
          rowRender={(row, { dataItem }) =>
            Row(row, dataItem.idItem === idItem)
          }
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={dataState.take}
          total={data?.total ?? 0}
        >
          {/* Name */}
          <GridColumn
            cell={({ dataItem }) => (
              <td
                className="cursor-pointer grid-cell-two-lines with-image"
                onClick={() =>
                  navigate(
                    `${ROUTES.DASHBOARD.QUESTIONS.PATH}/${dataItem.idItem}`
                  )
                }
              >
                <img
                  alt={QUESTION_TYPE_ICONS[dataItem.type].alt}
                  src={QUESTION_TYPE_ICONS[dataItem.type].src}
                />
                {dataItem.name}
                <div>
                  {`${intl.formatMessage(messages.updated)}: ${
                    dataItem.dateUpdatedUtc
                      ? formatDate(dataItem.dateUpdatedUtc)
                      : ''
                  }`}
                </div>
              </td>
            )}
            filterable={false}
            sortable={false}
            title={intl.formatMessage(messages.title)}
            width={'35%'}
          />

          {/* State */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>
                <ItemState state={dataItem.state} type={dataItem.type} />
              </td>
            )}
            columnMenu={(props) =>
              ColumnMenuCheckboxFilter(props, labelsCapitalized)
            }
            field="state"
            title={intl.formatMessage(messages.status)}
            width="10%"
          />

          {/* Categories */}
          <GridColumn
            cell={LabelsCell}
            columnMenu={() =>
              ColumnMenuMultiselect(
                allCategories,
                selectedCategories,
                setSelectedCategories
              )
            }
            filterable={false}
            sortable={false}
            title={intl.formatMessage(messages.category)}
            width="15%"
          />

          {/* Created at */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>{formatDate(dataItem.dateCreatedUtc)}</td>
            )}
            columnMenu={ColumnMenu}
            field="dateCreatedUtc"
            filter={'date'}
            title={intl.formatMessage(messages.created)}
            width="10%"
          />

          {/* Actions */}
          <GridColumn cell={ActionsCell} width="5%" />
        </Grid>
      </KendoLocalizator>
    </>
  );
};

export default QuestionsList;
