import { PROCTORING } from '../../consts/RTK';
import { TEXT_TYPES_BD } from '../../consts/PROCTORING';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { curry, pick } from 'ramda';
import { endpoints } from './proctoringApiSlice';

const proctoringSlice = createSlice({
  name: 'proctoring',
  initialState: { evaluations: [], texts: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        endpoints[PROCTORING.GET.EVALUATIONS.ENDPOINT].matchFulfilled,
        (state, { payload }) => {
          state.evaluations = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          endpoints[PROCTORING.GET.TEXTS.ENDPOINT].matchFulfilled,
          endpoints[PROCTORING.UPDATE.TEXTS.ENDPOINT].matchFulfilled
        ),
        (state, { meta, payload }) => {
          // example result => proctoring: { texts: { en: [ { id: 'gdpr', text: 'html' }, ... ] }, ... }
          state.texts[
            meta.arg.originalArgs?.language ?? meta.arg.originalArgs
          ] = Object.entries(pick(Object.values(TEXT_TYPES_BD), payload)).map(
            ([key, val]) => ({ id: key, text: val })
          );
        }
      );
  },
});

export default proctoringSlice.reducer;

export const getProctoringEvaluations = (state) => state.proctoring.evaluations;
export const getProctoringTexts = curry(
  (lang, state) => state.proctoring.texts[lang]
);
