import LabelsCell from '../Common/LabelsCell';
import ModalTemplate from './ModalTemplate';
import messages from '../../intl/messages';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ColumnMenu } from '../Common/ColumnFilter';
import { FormattedMessage, useIntl } from 'react-intl';
import { GRID } from '../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { QUESTIONS, TEST } from '../../consts/RTK';
import { QUESTION_TYPE_ICONS } from '../../consts/DESIGN';
import { apiSlice } from '../../store/api/apiSlice';
import { closeModal } from '../../store/modals/modalsSlice';
import { formatDate } from '../../utils';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import NoRecordFound from '../Dashboard/Events/NoRecordFound';
import { MODAL_TYPES } from './ModalsRoot';
import CreateButton from '../Common/CreateButton';

const CheckBoxCell = (props) => {
  const { dataItem, selected, setSelected } = props;
  const intl = useIntl();
  const checked = selected === dataItem.idItem;

  return (
    <td className="display-flex flex-align-center">
      <Checkbox
        checked={checked}
        onChange={() => setSelected(checked ? null : dataItem.idItem)}
      />
      <img
        alt={QUESTION_TYPE_ICONS[dataItem.type].alt}
        src={QUESTION_TYPE_ICONS[dataItem.type].src}
      />
      <div className="display-flex flex-column">
        {dataItem.name}
        <br />
        <div>
          {`${intl.formatMessage(messages.updated)}: ${
            dataItem.dateUpdatedUtc ? formatDate(dataItem.dateUpdatedUtc) : ''
          }`}
        </div>
      </div>
    </td>
  );
};

const AddInstructionsModal = ({ content, idTest }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selected, setSelected] = useState(content?.instructions?.idItem);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: GRID.DEFAULT_QUERY_LIMIT,
  });

  // Fetch questions
  const { data } = apiSlice[QUESTIONS.GET_INSTRUCTIONS.QUERY](dataState, {
    refetchOnMountOrArgChange: true,
  });

  // Update test content
  const [updateTestContent, result] =
    apiSlice[TEST.UPDATE.TEST_CONTENT.MUTATION]();

  // Update instruction
  const updateInstructions = () =>
    updateTestContent({
      idTest,
      content: {
        ...content,
        instructions: { idItem: selected },
      },
    });

  // Close modal
  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    <ModalTemplate
      isSaveBtnDisabled={!selected}
      title={<FormattedMessage {...messages.addInstruction} />}
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={() => {
        updateInstructions();
        dispatch(closeModal());
      }}
    >
      {data?.data.length > 0 ? (
        <Grid
          data={data?.data}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          pageSize={dataState.take}
          pageable={true}
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={dataState.take}
          total={data?.total ?? 0}
        >
          {/* Name */}
          <GridColumn
            cell={(p) => (
              <CheckBoxCell
                {...p}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            columnMenu={ColumnMenu}
            field="name"
            title={intl.formatMessage(messages.name)}
            width="50%"
          />

          {/* Categories */}
          <GridColumn
            cell={LabelsCell}
            columnMenu={ColumnMenu}
            field="categories"
            title={intl.formatMessage(messages.category)}
            width="20%"
          />

          {/* Created */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>{formatDate(dataItem.dateCreatedUtc)}</td>
            )}
            columnMenu={ColumnMenu}
            field="dateCreatedUtc"
            title={intl.formatMessage(messages.created)}
            width="15%"
          />
        </Grid>
      ) : (
        // No instructions found
        <NoRecordFound
          className="no-record-found"
          actionButton={<CreateButton type={MODAL_TYPES.CREATE_QUESTION} />}
          icon="/images/noTestFound.svg"
          intlKey={'instruction'}
        />
      )}
    </ModalTemplate>
  );
};

export default AddInstructionsModal;
