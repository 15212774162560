import AddInstructionsModal from './AddInstructionsModal';
import AddQuestionsModal from './AddQuestionsModal';
import AddSupportingMaterialsModal from './AddSupportingMaterialsModal';
import ConfirmModal from './ConfirmModal';
import CreateAdminUserModal from './CreateAdminUserModal';
import CreateOrganizationModal from './CreateOrganizationModal';
import CreateQuestionModal from './CreateQuestionModal';
import CreateUpdateClassroomModal from './CreateUpdateClassroomModal';
import CreateUpdateEventModal from './PublishEventModal/PublishEventModal';
import CreateUpdateTestModal from './CreateUpdateTestModal';
import CreateUserModal from './CreateUserModal';
import InviteExamineeModal from './InviteExamineeModal';
import PreviewModal from './PreviewModal';
import React from 'react';
import RejectExamModal from './RejectExamModal';
import UpdateDistractorModal from './UpdateDistractorModal';
import UpdateOrganizationModal from './UpdateOrganizationModal';
import UpdateUserModal from './UpdateUserModal';
import UpdateExamineeModal from './UpdateExamineeModal';
import { getModalProps, getModalType } from '../../store/modals/modalsSlice';
import { keyMirror } from '../../utils';
import { shallowEqual, useSelector } from 'react-redux';

export const MODAL_TYPES = keyMirror({
  ADD_INSTRUCTIONS: null,
  ADD_QUESTIONS: null,
  ADD_SUPPORTING_MATERIALS: null,
  CONFIRM_PROCTORING_TEXT: null,
  CONFIRM_QUESTION: null,
  CREATE_ADMIN_USER: null,
  CREATE_ORGANIZATION: null,
  CREATE_QUESTION: null,
  CREATE_UPDATE_CLASSROOM: null,
  CREATE_UPDATE_EVENT: null,
  CREATE_UPDATE_TEST: null,
  CREATE_USER: null,
  DELETE_CLASSROOM: null,
  DELETE_DISTRACTOR: null,
  DELETE_EVENT: null,
  DELETE_EXAMINEE_FROM_CLASSROOM: null,
  DELETE_ORGANIZATION: null,
  DELETE_QUESTION: null,
  DELETE_TEST: null,
  DELETE_USER: null,
  HAS_MISSING: null,
  INVITE_EXAMINEE: null,
  PREVIEW_QUESTION: null,
  REJECT_EXAM: null,
  SWITCH_TO_DRAFT: null,
  UPDATE_DISTRACTOR: null,
  UPDATE_ORGANIZATION: null,
  UPDATE_USER: null,
  UPDATE_EXAMINEE: null,
  ZOOM_IMAGE: null,
});

export const modals = {
  [MODAL_TYPES.ADD_INSTRUCTIONS]: AddInstructionsModal,
  [MODAL_TYPES.ADD_QUESTIONS]: AddQuestionsModal,
  [MODAL_TYPES.ADD_SUPPORTING_MATERIALS]: AddSupportingMaterialsModal,
  [MODAL_TYPES.CONFIRM_PROCTORING_TEXT]: ConfirmModal,
  [MODAL_TYPES.CONFIRM_QUESTION]: ConfirmModal,
  [MODAL_TYPES.CREATE_ADMIN_USER]: CreateAdminUserModal,
  [MODAL_TYPES.CREATE_ORGANIZATION]: CreateOrganizationModal,
  [MODAL_TYPES.CREATE_QUESTION]: CreateQuestionModal,
  [MODAL_TYPES.CREATE_UPDATE_CLASSROOM]: CreateUpdateClassroomModal,
  [MODAL_TYPES.CREATE_UPDATE_EVENT]: CreateUpdateEventModal,
  [MODAL_TYPES.CREATE_UPDATE_TEST]: CreateUpdateTestModal,
  [MODAL_TYPES.CREATE_USER]: CreateUserModal,
  [MODAL_TYPES.DELETE_CLASSROOM]: ConfirmModal,
  [MODAL_TYPES.DELETE_DISTRACTOR]: ConfirmModal,
  [MODAL_TYPES.DELETE_EVENT]: ConfirmModal,
  [MODAL_TYPES.DELETE_EXAMINEE_FROM_CLASSROOM]: ConfirmModal,
  [MODAL_TYPES.DELETE_ORGANIZATION]: ConfirmModal,
  [MODAL_TYPES.DELETE_QUESTION]: ConfirmModal,
  [MODAL_TYPES.DELETE_TEST]: ConfirmModal,
  [MODAL_TYPES.DELETE_USER]: ConfirmModal,
  [MODAL_TYPES.INVITE_EXAMINEE]: InviteExamineeModal,
  [MODAL_TYPES.HAS_MISSING]: ConfirmModal,
  [MODAL_TYPES.REJECT_EXAM]: RejectExamModal,
  [MODAL_TYPES.PREVIEW_QUESTION]: PreviewModal,
  [MODAL_TYPES.SWITCH_TO_DRAFT]: ConfirmModal,
  [MODAL_TYPES.UPDATE_DISTRACTOR]: UpdateDistractorModal,
  [MODAL_TYPES.UPDATE_ORGANIZATION]: UpdateOrganizationModal,
  [MODAL_TYPES.UPDATE_USER]: UpdateUserModal,
  [MODAL_TYPES.UPDATE_EXAMINEE]: UpdateExamineeModal,
  [MODAL_TYPES.ZOOM_IMAGE]: ConfirmModal,
};

const ModalRoot = () => {
  const modalType = useSelector(getModalType);
  const modalProps = useSelector(getModalProps, shallowEqual);
  const Modal = modals[modalType] || null;

  return Modal ? <Modal {...modalProps} modalType={modalType} /> : null;
};

export default ModalRoot;
