import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { API, API_SEGMENT } from '../../consts/API';
import { QUESTION, QUESTIONS } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const questionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create question
    [QUESTION.CREATE.ENDPOINT]: builder.mutation({
      query: ({ name, type, categories }) => ({
        url: API[API_SEGMENT.AUTHORING].QUESTION,
        method: 'POST',
        body: { name, type, categories },
      }),
      invalidatesTags: ['QuestionsCount'],
    }),
    // Create question categories
    [QUESTION.CREATE_CATEGORIES.ENDPOINT]: builder.mutation({
      query: (body) => ({
        url: API[API_SEGMENT.AUTHORING].QUESTION_CATEGORIES,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Categories'],
    }),
    // Get question
    [QUESTION.GET.ENDPOINT]: builder.query({
      query: (idItem) => ({
        method: 'GET',
        url: `${API[API_SEGMENT.AUTHORING].QUESTION}/?id=${idItem}`,
      }),
      providesTags: ['Question'],
    }),
    // Get all categories / get question categories (if id provided)
    [QUESTION.GET_CATEGORIES.ENDPOINT]: builder.query({
      query: (idItem) => ({
        url: `${API[API_SEGMENT.AUTHORING].QUESTION_CATEGORIES}${
          idItem ? '?idItem=' + idItem : ''
        }`,
      }),
      providesTags: ['Categories'],
    }),
    // Get html
    [QUESTION.GET_HTML.ENDPOINT]: builder.query({
      query: (idItem) => ({
        method: 'GET',
        url: `${API[API_SEGMENT.AUTHORING].QUESTION_HTML}/?id=${idItem}`,
      }),
    }),
    // Delete question
    [QUESTION.DELETE.ENDPOINT]: builder.mutation({
      query: (idItem) => ({
        method: 'DELETE',
        url: `${API[API_SEGMENT.AUTHORING].QUESTION}/?id=${idItem}`,
      }),
      invalidatesTags: ['Questions', 'QuestionsCount'],
    }),
    // Update question name
    [QUESTION.UPDATE.QUESTION_NAME.ENDPOINT]: builder.mutation({
      query: ({ idItem, name }) => ({
        body: { idItem, name },
        method: 'PUT',
        url: API[API_SEGMENT.AUTHORING].QUESTION_NAME,
      }),
      invalidatesTags: ['Question'],
    }),
    // Update question state
    [QUESTION.UPDATE.QUESTION_STATE.ENDPOINT]: builder.mutation({
      query: ({ idItem, state }) => ({
        body: { idItem, state },
        method: 'PUT',
        url: API[API_SEGMENT.AUTHORING].QUESTION_STATE,
      }),
      invalidatesTags: ['Question'],
    }),
    // Update question content
    [QUESTION.UPDATE.QUESTION_CONTENT.ENDPOINT]: builder.mutation({
      query: ({ typeDiscriminator, typeValue }) => ({
        body: { typeDiscriminator, typeValue },
        method: 'PUT',
        url: API[API_SEGMENT.AUTHORING].QUESTION_CONTENT,
      }),
      invalidatesTags: ['Question'],
    }),
    // Update question score
    [QUESTION.UPDATE.QUESTION_SCORE.ENDPOINT]: builder.mutation({
      query: ({ idItem, score, negativeScore }) => ({
        body: { idItem, score, negativeScore },
        method: 'PUT',
        url: API[API_SEGMENT.AUTHORING].QUESTION_SCORE,
      }),
      invalidatesTags: ['Question'],
    }),
    // Get questions / get questions filtered by category
    [QUESTIONS.GET.ENDPOINT]: builder.query({
      query: ({ dataState, categoryIds }) => ({
        url: `${
          API[API_SEGMENT.AUTHORING].QUESTIONS
        }?${toDataSourceRequestString(dataState)}${
          categoryIds
            ? '&idCategories=' + categoryIds.join('&idCategories=')
            : ''
        }`,
      }),
      providesTags: ['Questions'],
    }),
    // Get questions count
    [QUESTIONS.GET_COUNT.ENDPOINT]: builder.query({
      query: () => ({
        url: API[API_SEGMENT.AUTHORING].QUESTIONS_COUNT,
      }),
      providesTags: ['QuestionsCount'],
    }),
    // Get questions done
    [QUESTIONS.GET_DONE.ENDPOINT]: builder.query({
      query: (queryConfig) => ({
        url: `${
          API[API_SEGMENT.AUTHORING].QUESTIONS_DONE
        }?${toDataSourceRequestString(queryConfig)}`,
      }),
    }),
    // Get instructions
    [QUESTIONS.GET_INSTRUCTIONS.ENDPOINT]: builder.query({
      query: () => ({ url: API[API_SEGMENT.AUTHORING].INSTRUCTIONS }),
    }),
    // Get supporting materials
    [QUESTIONS.GET_SUPPORTING_MATERIALS.ENDPOINT]: builder.query({
      query: (queryConfig) => ({
        url: `${
          API[API_SEGMENT.AUTHORING].SUPPORTING_MATERIALS
        }?${toDataSourceRequestString(queryConfig)}`,
      }),
    }),
  }),
});

export const endpoints = questionsApiSlice.endpoints;
