import { Button } from '@progress/kendo-react-buttons';
import { useIntl } from 'react-intl';
import messages from '../../intl/messages';
import './Customers.scss';
import { useSelector } from 'react-redux';
import { getOrganizations } from '../../store/organizations/organizationsSlice';

const Customers = () => {
  const intl = useIntl();
  const organizations = Object.values(useSelector(getOrganizations) || {});
  const organizationsCount = organizations?.length;
  const membersCount = organizations?.reduce(
    (acc, cur) => acc + cur.userCount,
    0
  );

  return (
    <div className="customers">
      <h1>{intl.formatMessage(messages.customers)}</h1>
      <div className="cards">
        <div className="card rounded-8">
          <span className="title">
            {intl.formatMessage(messages.numberOfOrganizations)}
            <Button>
              <img alt="settings" src="/images/verticalDots.svg" />
            </Button>
          </span>
          <span className="number">{organizationsCount}</span>
        </div>
        <div className="card rounded-8">
          <span className="title">
            {intl.formatMessage(messages.members)}
            <Button>
              <img alt="settings" src="/images/verticalDots.svg" />
            </Button>
          </span>
          <span className="number">{membersCount}</span>
        </div>
      </div>
    </div>
  );
};

export default Customers;
