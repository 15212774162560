import { apiSlice } from '../api/apiSlice';
import { BILLING } from '../../consts/RTK';
import { API, API_SEGMENT } from '../../consts/API';

export const billingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get invoices
    [BILLING.GET.INVOICES.ENDPOINT]: builder.query({
      query: (organizationId) =>
        `${API[API_SEGMENT.ORGANIZATION].BILLING_INVOICES}/${organizationId}`,
    }),
    // Get invoice html
    [BILLING.GET.INVOICE_HTML.ENDPOINT]: builder.query({
      query: (organizationId) => ({
        url: `${
          API[API_SEGMENT.ORGANIZATION].BILLING_INVOICE_HTML
        }/${organizationId}`,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const endpoints = billingApiSlice.endpoints;
