import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
  load,
} from '@progress/kendo-react-intl';
import weekData from 'cldr-core/supplemental/weekData.json';
import caGregorian from 'cldr-dates-full/main/cs/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/cs/dateFields.json';
import { useSelector } from 'react-redux';
import { LOCALE } from '../consts/INTL';
import { KENDO_CONSTANTS } from '../consts/KENDO';
import { getLocale } from '../store/ui/uiSlice';
import messagesCs from '../intl/kendoCs.json';
load(weekData, caGregorian, dateFields);
loadMessages(messagesCs, 'cs-CZ');

const KendoLocalizator = (props) => {
  const locale = useSelector(getLocale);
  const language = KENDO_CONSTANTS.LANGUAGE[locale ?? LOCALE.EN];

  return (
    <LocalizationProvider language={language}>
      <IntlProvider locale={locale}>{props.children}</IntlProvider>
    </LocalizationProvider>
  );
};

export default KendoLocalizator;
