import Search from '../Common/Search';
import messages from '../../intl/messages';
import { BILLING } from '../../consts/RTK';
import { Button } from '@progress/kendo-react-buttons';
import { ColumnMenu } from '../Common/ColumnFilter';
import { GRID } from '../../consts/KENDO';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { apiSlice } from '../../store/api/apiSlice';
import { formatDate } from '../../utils';
import html2pdf from 'html2pdf.js';
import { getUserOrganizationId } from '../../store/auth/authSlice';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { endpoints } from '../../store/billing/billingApiSlice';
import {
  handleCustomKendoFilter,
  createNameFilterDescriptor,
} from '../../filters';
import KendoLocalizator from '../../layout/KendoLocalizator';

const getInvoiceName = (dataItem) =>
  `${dataItem?.items[0]?.planName} (${formatDate(dataItem?.targetDate, 's')})`;

const exportPDF = async (invoiceId, dispatch) => {
  try {
    const response = await dispatch(
      endpoints[BILLING.GET.INVOICE_HTML.ENDPOINT].initiate(invoiceId)
    );

    if (response?.error) {
      console.error('response error =', response.error);
    } else {
      const options = {
        filename: `${invoiceId}.pdf`,
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      html2pdf().set(options).from(response.data).save();
    }
  } catch (error) {
    console.error('internal error =', error);
  }
};

const Billing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userOrganizationId = useSelector(getUserOrganizationId);

  const [dataState, setDataState] = useState(GRID.DEFAULT_DATA_STATE);

  // Fetch billing invoices
  const { data } = apiSlice[BILLING.GET.INVOICES.QUERY](userOrganizationId);

  const invoices = data ?? [];

  // Paging
  const [page, setPage] = useState(GRID.DEFAULT_DATA_STATE);

  // Filter
  const invoicesExtended = invoices.map((invoice) => ({
    ...invoice,
    invoiceDateFull: new Date(invoice.invoiceDate),
    name: getInvoiceName(invoice),
  }));
  const invoicesSorted = orderBy(invoicesExtended, dataState.sort);
  const invoicesFiltered = filterBy(invoicesSorted, dataState.filter);

  const handleSearch = (e, dataState, setDataState) => {
    const nameFilterDescriptor = createNameFilterDescriptor(e.target.value);

    handleCustomKendoFilter(
      dataState,
      setDataState,
      nameFilterDescriptor,
      GRID.DEFAULT_DATA_STATE
    );
  };

  return (
    <section>
      {/* Title + controls */}
      <h2>{intl.formatMessage(messages.billing)}</h2>
      {/* Search by name */}
      <Search
        className="plain search k-floating-label-container"
        label={intl.formatMessage(messages.searchByName)}
        onChange={(e) => handleSearch(e, dataState, setDataState)}
      />
      <KendoLocalizator>
        <Grid
          data={invoicesFiltered}
          filter={dataState.filter}
          onDataStateChange={(e) => setDataState(e.dataState)}
          onPageChange={(e) => setPage(e.page)}
          pageSize={dataState.take}
          pageable={true}
          skip={dataState.skip}
          sort={dataState.sort}
          sortable={true}
          take={page.take}
          total={data?.length ?? 0}
        >
          {/* Name */}
          <GridColumn
            cell={({ dataItem }) => <td>{dataItem.name}</td>}
            field="name"
            title={intl.formatMessage(messages.title)}
            sortable={false}
            width="40%"
          />

          {/* Amount */}
          <GridColumn
            cell={({ dataItem }) =>
              dataItem.amount && (
                <td>
                  {dataItem.amount} {dataItem.currency}
                </td>
              )
            }
            columnMenu={ColumnMenu}
            field="amount"
            filter={'numeric'}
            title={intl.formatMessage(messages.amount)}
            width="15%"
          />

          {/* Date */}
          <GridColumn
            cell={({ dataItem }) => <td>{formatDate(dataItem.invoiceDate)}</td>}
            columnMenu={ColumnMenu}
            field="invoiceDateFull"
            filter={'date'}
            title={intl.formatMessage(messages.date)}
            width="15%"
          />

          {/* Download */}
          <GridColumn
            cell={({ dataItem }) => (
              <td>
                <Button
                  className="reset-icon-size save-close"
                  imageUrl="/images/download.svg"
                  onClick={() => exportPDF(dataItem.invoiceId, dispatch)}
                />
              </td>
            )}
            width="10%"
            sortable={false}
          />
        </Grid>
      </KendoLocalizator>
    </section>
  );
};

export default Billing;
