import TestsList from './TestsList';
import messages from '../../../intl/messages.js';
import { MODAL_TYPES } from '../../Modals/ModalsRoot.js';
import { useIntl } from 'react-intl';
import CreateButton from '../../Common/CreateButton.js';

const Tests = () => {
  const intl = useIntl();

  return (
    <section>
      {/* Title + controls */}
      <h2>
        {intl.formatMessage(messages.tests)}
        <CreateButton type={MODAL_TYPES.CREATE_UPDATE_TEST} />
      </h2>
      <TestsList />
    </section>
  );
};

export default Tests;
