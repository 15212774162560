import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { isEmpty, without } from 'ramda';
import { LOCALE } from '../../consts/INTL';
import NOTIFICATIONS from '../../consts/NOTIFICATIONS';
import {
  ERRORS,
  ORGANIZATIONS,
  PROCTORING,
  QUESTIONS,
  TEST,
  TESTS,
  USERS,
} from '../../consts/RTK';
import { LSM } from '../../utils';

const initialState = {
  locale: LSM.getLocale() || LOCALE.EN, // default ui lang
  notifications: [],
  pendingRequests: [],
};

// These endpoint names are not triggering any notification
const successNotificationsBlacklist = [
  ORGANIZATIONS.GET.ENDPOINT,
  PROCTORING.GET.EVALUATIONS.ENDPOINT,
  PROCTORING.GET.TEXTS.ENDPOINT,
  QUESTIONS.GET.ENDPOINT,
  TEST.DELETE.ENDPOINT,
  TEST.GET.ENDPOINT,
  // TODO start - Add notifications instead - needs to be discused first
  TEST.UPDATE.TEST_NAME,
  TEST.UPDATE.TEST_STATE,
  // TODO end
  TESTS.GET.ENDPOINT,
  USERS.GET_BY_ORGANIZATION.ENDPOINT,
];

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    deleteNotification: (state, { payload }) => ({
      ...state,
      notifications: state.notifications.filter(({ id }) => id !== payload),
    }),
    setLocale: (state, { payload }) => {
      LSM.setLocale(payload);
      return { ...state, locale: payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled, (state, { meta }) => {
        // Delete request from pendingRequests (if empty pendingRequests then no preloader)
        state.pendingRequests = without(
          [meta.requestId],
          state.pendingRequests
        );

        // Set success notification
        const endpointName = meta.arg.endpointName;

        if (!successNotificationsBlacklist.includes(endpointName)) {
          state.notifications = [
            {
              id: meta.requestId,
              message: endpointName,
              method: meta.baseQueryMeta.request.method,
              type: NOTIFICATIONS.SUCCESS,
            },
            ...state.notifications,
          ];
        }
      })
      .addMatcher(isPending, (state, { meta }) => {
        // Add request into pendingRequests (displays preloader)
        state.pendingRequests = [...state.pendingRequests, meta.requestId];
      })
      .addMatcher(isRejected, (state, { error, meta }) => {
        // console.log('RTKQ REJECTED:', { error, meta });

        // Delete request from pendingRequests (if empty pendingRequests then no preloader)
        state.pendingRequests = without(
          [meta.requestId],
          state.pendingRequests
        );

        if (
          error.name === ERRORS.CONDITION_ERROR &&
          process.env.NODE_ENV === 'development'
        ) {
          // Set warning notification - RTKQ uses cache
          state.notifications = [
            {
              id: meta.requestId,
              message: error.name,
              method: meta?.baseQueryMeta?.request?.method,
              type: NOTIFICATIONS.WARNING,
            },
            ...state.notifications,
          ];
        } else if (error.name !== ERRORS.CONDITION_ERROR) {
          // Set error notification
          state.notifications = [
            {
              id: meta.requestId,
              message: meta.arg.endpointName,
              method: meta.baseQueryMeta.request.method,
              type: NOTIFICATIONS.ERROR,
            },
            ...state.notifications,
          ];
        }
      });
  },
});

export const { deleteNotification, setLocale } = uiSlice.actions;

export default uiSlice.reducer;

export const getIsFetching = (state) => !isEmpty(state.ui.pendingRequests);
export const getLocale = (state) => state.ui.locale;
export const getNotifications = (state) => state.ui.notifications;
