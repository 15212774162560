import {
  Button,
  DropDownButton as KendoDropDownButton,
} from '@progress/kendo-react-buttons';
import { useIntl } from 'react-intl';
import messages from '../../intl/messages';

const composeOptions = (intl, props) => {
  const controls = [];

  if (props.onDelete)
    controls.push(
      <Button onClick={props.onDelete}>
        <img alt="" src="/images/trash.svg" />
        {intl.formatMessage(messages.delete)}
      </Button>
    );

  if (props.onUpdate)
    controls.push(
      <Button onClick={props.onUpdate}>
        <img alt="" src="/images/edit.svg" />
        {intl.formatMessage(messages.edit)}
      </Button>
    );

  if (props.onDeleteExamineeFromClassroom)
    controls.push(
      <Button onClick={props.onDeleteExamineeFromClassroom}>
        <img alt="" src="/images/deleteUser.svg" />
        {intl.formatMessage(messages.deleteExamineeFromClassroom)}
      </Button>
    );

  if (props.onResendInvitation)
    controls.push(
      <Button onClick={props.onResendInvitation}>
        <img alt="" src="/images/edit.svg" />
        {intl.formatMessage(messages.edit)}
      </Button>
    );

  return controls;
};

const DropDownButton = (props) => {
  const intl = useIntl();

  return (
    <KendoDropDownButton
      text={<img alt="Drop down" src="/images/horizontalDots.svg" />}
      className="plain"
      items={composeOptions(intl, props)}
      {...props}
    />
  );
};

export default DropDownButton;
