import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';
import ROUTES from '../../consts/ROUTES';
import { useState } from 'react';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { apiSlice } from '../../store/api/apiSlice';
import { AUTH } from '../../consts/RTK';
import './Login.scss';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberThisAccount, setRememberThisAccount] = useState(false);
  const intl = useIntl();
  const [login] = apiSlice[AUTH.LOGIN.MUTATION]();

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password, rememberThisAccount });
    e.stopPropagation();
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="login">
      <span className="logo">
        <img alt="Logo" src="/images/ellipse.svg" />
        <img alt="Logo" src="/images/ellipse.svg" />
      </span>
      <h2>
        <FormattedMessage {...messages.loginToYourAccount} />
      </h2>
      <h4>
        <FormattedMessage {...messages.welcomeBackEnterDetails} />
      </h4>
      <strong>
        <FormattedMessage {...messages.email} />
      </strong>
      <Input
        type="text"
        value={email}
        placeholder={intl.formatMessage(messages.enterYourEmail)}
        onChange={(event) => setEmail(event.target.value)}
      />
      <strong>
        <FormattedMessage {...messages.password} />
      </strong>
      <Input
        type="password"
        placeholder={intl.formatMessage(messages.enterYourPassword)}
        onChange={(event) => setPassword(event.target.value)}
        value={password}
      />
      <strong>
        <Checkbox
          value={rememberThisAccount}
          onChange={(e) => setRememberThisAccount(e.value)}
        />
        <FormattedMessage {...messages.rememberThisAccount} />
      </strong>
      <Button className="primary" type="submit">
        <FormattedMessage {...messages.login} />
      </Button>
      <div className="footer">
        <FormattedMessage {...messages.dontHaveAccount} />{' '}
        <Link to={`${ROUTES.PUBLIC.FAQ.PATH}`} className="link">
          <FormattedMessage {...messages.learnMore} />
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
