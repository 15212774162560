import ItemState from '../../../Common/ItemState';
import SelectTestCategories from './SelectTestCategories';
import messages from '../../../../intl/messages';
import { Context } from './TestDetail';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { PAGE_ALIGNMENT } from '../../../../consts/ORGANIZATOR';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { invertObj, update } from 'ramda';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

const TestDetail = () => {
  const intl = useIntl();
  const {
    content,
    currentPageId,
    idTest,
    instructions,
    isDone,
    pages,
    state,
    updateTestContent,
  } = useContext(Context);
  const pageAlignment =
    content?.pages?.[currentPageId]?.pageSettings?.pageAlignment;
  const isMissing = [];
  const pageAlignmentKey =
    invertObj(PAGE_ALIGNMENT)[pageAlignment ?? 1].toLowerCase();
  const pageAlignmentLabel = intl.formatMessage(messages[pageAlignmentKey]);

  if (!isDone) {
    const hasPage = pages.length > 0;
    instructions ||
      isMissing.push(intl.formatMessage(messages.missing.instructions));
    hasPage || isMissing.push(intl.formatMessage(messages.missing.page));

    if (hasPage) {
      const isEmptyPage = pages.some((page) => {
        return page.items.length === 0;
      });
      !isEmptyPage ||
        isMissing.push(intl.formatMessage(messages.missing.pageFilled));
    }
  }

  // Update page alignment
  const updatePageAlignment = (pageAlignment) =>
    updateTestContent({
      idTest,
      content: {
        ...content,
        pages: update(
          currentPageId,
          {
            ...content.pages[currentPageId],
            pageSettings: {
              ...content.pages[currentPageId].pageSettings,
              pageAlignment,
            },
          },
          content.pages
        ),
      },
    });

  return (
    <div className="detail-settings">
      <Tooltip anchorElement="target" position="top">
        {/* Header - Settings */}
        <div className="section-header">
          <h4 className="section-title">
            {intl.formatMessage(messages.settings)}
          </h4>
        </div>

        {/* Status */}
        <div className="section">
          <strong title={intl.formatMessage(messages.status)}>
            {intl.formatMessage(messages.status)}
          </strong>
          {state && <ItemState state={state} id={idTest} missing={isMissing} />}
        </div>

        {/* Categories */}
        <div className="section">
          <strong title={intl.formatMessage(messages.categories)}>
            {intl.formatMessage(messages.categories)}
          </strong>
          <SelectTestCategories idTest={idTest} />
        </div>

        {/* Page alignment */}
        {pageAlignment && (
          <div className="border-top" disabled={isDone}>
            <strong
              title={intl.formatMessage(messages.multipleSelectionDescription)}
            >
              Page alignment
            </strong>
            <DropDownList
              data={Object.entries(PAGE_ALIGNMENT).map(([key, id]) => ({
                id,
                intl: intl.formatMessage(messages[key.toLocaleLowerCase()]),
              }))}
              dataItemKey="id"
              onChange={(event) => updatePageAlignment(event.target.value.id)}
              textField="intl"
              value={{
                id: pageAlignment,
                intl: pageAlignmentLabel,
              }}
            />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export default TestDetail;
