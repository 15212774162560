import Svg from '../../../Common/Svg';
import messages from '../../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { Context } from './TestDetail';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ListBox,
  processListBoxDragAndDrop,
} from '@progress/kendo-react-listbox';
import { usePrevious } from '../../../../utils';
import { equals } from 'ramda';

const PageItem = (props) => {
  const { dataItem, selected, ...rest } = props;
  const {
    assocDissocPage,
    currentPageId,
    isDone,
    setCurrentPageId,
    viewToggle,
  } = useContext(Context);
  const intl = useIntl();
  const pageId = dataItem.pageId;

  return (
    <li {...rest} className={`${currentPageId === pageId && 'active'}`}>
      <Button className="plain" onClick={() => setCurrentPageId(pageId)}>
        <Svg name="testPage" />
        {viewToggle && `${intl.formatMessage(messages.page)}`} {pageId + 1}
      </Button>
      <Button
        className="plain delete"
        icon="delete"
        onClick={() => assocDissocPage(pageId)}
        title={intl.formatMessage(messages.delete)}
        disabled={isDone}
      />
    </li>
  );
};

const LeftBar = () => {
  const intl = useIntl();
  const {
    assocDissocPage,
    isDone,
    isSupportingMaterialsPage,
    isWelcomePage,
    pages,
    setCurrentPageId,
    setViewToggle,
    updatePages,
    viewToggle,
  } = useContext(Context);
  const [listState, setListState] = useState({
    pages,
    draggedItem: {},
  });
  const prevPages = usePrevious(pages);

  useEffect(() => {
    if (!equals(prevPages, pages)) {
      setListState({
        ...listState,
        pages: pages.map((page, pageId) => ({ ...page, pageId })),
      });
    }
  }, [listState, pages, prevPages]);

  const onDragStart = ({ dataItem }) =>
    setListState({ ...listState, draggedItem: dataItem });

  const onDrop = ({ dataItem }) => {
    const { listBoxOneData: pages } = processListBoxDragAndDrop(
      [...listState.pages],
      [],
      listState.draggedItem,
      dataItem,
      'pageId'
    );

    updatePages(pages, dataItem.pageId);
  };

  return (
    <div className={`detail-settings left ${!viewToggle && 'folded'}`}>
      <Tooltip anchorElement="target" position="top">
        {/* Header - Pages */}
        <div className="section gap-12 flex-align-end">
          <div className="section-header flex-justify-between">
            <h4 className="section-title">
              {intl.formatMessage(messages.pages)}
            </h4>
          </div>
          <Button
            imageUrl="/images/addPage.svg"
            className="plain float-right"
            onClick={() => assocDissocPage()}
            disabled={isDone}
          />
        </div>

        <ul>
          {/* Welcome page */}
          <li
            className={`${isWelcomePage && 'active'}`}
            key={`CreateUpdateTest-LeftBar-WelcomePage`}
          >
            <Button className="plain" onClick={() => setCurrentPageId(-2)}>
              <Svg name="welcomePage" />
              {viewToggle && intl.formatMessage(messages.welcomePage)}
            </Button>
          </li>

          {/* Supporting materials */}
          <li
            className={`${isSupportingMaterialsPage && 'active'}`}
            key={`CreateUpdateTest-LeftBar-SupportingMaterialsPage`}
          >
            <Button className="plain" onClick={() => setCurrentPageId(-1)}>
              <Svg name="welcomePage" />
              {viewToggle && intl.formatMessage(messages.supportingMaterials)}
            </Button>
          </li>
        </ul>

        {/* Pages */}
        <ListBox
          data={listState.pages}
          textField="pageId"
          item={PageItem}
          onDragStart={onDragStart}
          onDrop={onDrop}
          draggable={!isDone}
        />
      </Tooltip>

      {/* Toggle view */}
      <div className="view-switch">
        <Button
          className="plain"
          imageUrl={`/images/${viewToggle ? 'viewUnfold' : 'viewFold'}.svg`}
          onClick={() => setViewToggle(!viewToggle)}
        />
      </div>
    </div>
  );
};

export default LeftBar;
