import { Switch } from '@progress/kendo-react-inputs';
import messages from '../../../../intl/messages';
import { useIntl } from 'react-intl';
import { produce } from 'immer';

const Switches = ({
  content,
  id,
  settings,
  updateQuestionContent,
  disabled,
}) => {
  const intl = useIntl();

  const handleSwitchChange = (switchName, switchValue) => {
    const isMultipleSelection = switchName === 'multipleSelection';
    let body = {
      ...content,
      settings: {
        ...settings,
        [switchName]: switchValue,
      },
    };
    if (isMultipleSelection) {
      const sectionsCopy = produce(content.sections, (draft) => {
        // eslint-disable-next-line guard-for-in
        for (const sectionsIndex in draft) {
          for (const distractor of draft[sectionsIndex].distractors) {
            distractor.isCorrect = false;
          }
        }
      });
      body = {
        ...body,
        sections: sectionsCopy,
      };
    }
    updateQuestionContent({ idItem: id, content: body });
  };

  return (
    <div disabled={disabled} className="margin-bottom-20">
      <div className="section border-top">
        <strong
          title={intl.formatMessage(messages.multipleSelectionDescription)}
        >
          {intl.formatMessage(messages.multipleSelection)}
        </strong>
        <Switch
          name="multipleSelection"
          onChange={(e) => handleSwitchChange(e.target.props.name, e.value)}
          size="small"
          onLabel={''}
          offLabel={''}
          checked={content.settings.multipleSelection}
        />
      </div>
      <div className="section">
        <strong
          title={intl.formatMessage(messages.randomizeAnswersDescription)}
        >
          {intl.formatMessage(messages.randomizeAnswers)}
        </strong>
        <Switch
          name="randomize"
          onChange={(e) => handleSwitchChange(e.target.props.name, e.value)}
          size="small"
          onLabel={''}
          offLabel={''}
          checked={content.settings.randomize}
        />
      </div>
      <div className="section">
        <strong
          title={intl.formatMessage(messages.horizontalAlignmentDescription)}
        >
          {intl.formatMessage(messages.horizontalAlignment)}
        </strong>
        <Switch
          name="horizontalAlignment"
          onChange={(e) => handleSwitchChange(e.target.props.name, e.value)}
          size="small"
          onLabel={''}
          offLabel={''}
          checked={content.settings.horizontalAlignment}
        />
      </div>
    </div>
  );
};

export default Switches;
