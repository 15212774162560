import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import styles from './TopBar.module.scss';
import { showModal } from '../../store/modals/modalsSlice';
import { Button } from '@progress/kendo-react-buttons';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { MODAL_TYPES } from '../Modals/ModalsRoot';
import SaveCancelInput from '../SaveCancelInput';

const TopBar = ({ inputValue, onInputSave, item }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={styles['top-bar']}>
      <Button
        className="plain"
        imageUrl="/images/home.svg"
        onClick={() => navigate(-1)}
      />
      <img alt="" src="/images/chevronRight.svg" />
      {onInputSave ? (
        <SaveCancelInput
          onSave={onInputSave}
          value={inputValue}
          disabled={!onInputSave}
          className="detail-name"
        />
      ) : (
        <input
          type="text"
          disabled
          value={inputValue}
          className="detail-name k-input"
        />
      )}

      {item && (
        <Button
          className="plain"
          imageUrl="/images/watch.svg"
          onClick={() =>
            dispatch(
              showModal({
                props: { item, inputValue },
                type: MODAL_TYPES.PREVIEW_QUESTION,
              })
            )
          }
        />
      )}
      <Button
        onClick={() => navigate(-1)}
        title={intl.formatMessage(messages.close)}
      >
        {intl.formatMessage(messages.close)}
      </Button>
    </div>
  );
};

export default TopBar;
