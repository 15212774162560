import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { curry } from 'ramda';
import { USER, USERS } from '../../consts/RTK';
import { arrToObjIdAsKey } from '../../utils';
import { endpoints } from './usersApiSlice';

const initialState = {};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        endpoints[USER.DELETE.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          Reflect.deleteProperty(state, meta.arg.originalArgs);
        }
      )
      .addMatcher(
        endpoints[USERS.GET_BY_ORGANIZATION.ENDPOINT].matchFulfilled,
        (state, { payload }) => arrToObjIdAsKey(payload)
      )
      .addMatcher(
        isAnyOf(
          endpoints[USER.CREATE.ADMIN.ENDPOINT].matchFulfilled,
          endpoints[USER.CREATE.ENDPOINT].matchFulfilled,
          endpoints[USER.UPDATE.ENDPOINT].matchFulfilled
        ),
        (state, { payload }) => ({ ...state, [payload.id]: payload })
      );
  },
});

export default usersSlice.reducer;

export const getUser = curry((userId, state) => state.users?.[userId]);
export const getUsers = (state) => state.users;
