export const AUTH = {
  LOGIN: {
    ENDPOINT: 'login',
    MUTATION: 'useLoginMutation',
  },
  LOGIN_TO_ORGANIZATION: {
    ENDPOINT: 'loginToOrganization',
    MUTATION: 'useLoginToOrganizationMutation',
  },
};

export const BILLING = {
  GET: {
    INVOICES: {
      ENDPOINT: 'getBillingInvoices',
      QUERY: 'useGetBillingInvoicesQuery',
    },
    INVOICE_HTML: {
      ENDPOINT: 'getBillingInvoiceHtml',
      QUERY: 'useGetBillingInvoiceHtmlQuery',
    },
  },
};

export const CLASSROOM = {
  CREATE: {
    ENDPOINT: 'createClassroom',
    MUTATION: 'useCreateClassroomMutation',
  },
  DELETE: {
    ENDPOINT: 'deleteClassroom',
    MUTATION: 'useDeleteClassroomMutation',
  },
  GET: {
    ENDPOINT: 'getClassroom',
    QUERY: 'useGetClassroomQuery',
  },
  GET_EXAMINEES: {
    ENDPOINT: 'getClassroomExaminees',
    QUERY: 'useGetClassroomExamineesQuery',
  },
  DELETE_EXAMINEE: {
    ENDPOINT: 'deleteClassroomExaminee',
    MUTATION: 'useDeleteClassroomExamineeMutation',
  },
  UPDATE: {
    CLASSROOM_NAME: {
      ENDPOINT: 'updateClassroomName',
      MUTATION: 'useUpdateClassroomNameMutation',
    },
  },
};

export const CLASSROOMS = {
  GET: {
    ENDPOINT: 'getClassrooms',
    QUERY: 'useGetClassroomsQuery',
  },
};

export const EVENT = {
  CREATE: {
    ENDPOINT: 'createEvent',
    MUTATION: 'useCreateEventMutation',
  },
};

export const EVENTS = {
  GET: {
    ENDPOINT: 'getEvents',
    QUERY: 'useGetEventsQuery',
  },
  GET_COUNT: {
    ENDPOINT: 'getEventsCount',
    QUERY: 'useGetEventsCountQuery',
  },
  GET_PAST: {
    ENDPOINT: 'getEventsPast',
    QUERY: 'useGetEventsPastQuery',
  },
  GET_EXAM: {
    ENDPOINT: 'getEventExam',
    QUERY: 'useGetEventExamQuery',
  },
};

export const EXAM = {
  GET: {
    ENDPOINT: 'getExam',
    QUERY: 'useGetExamQuery',
  },
  GET_LIST: {
    ENDPOINT: 'getExamList',
    QUERY: 'useGetExamListQuery',
  },
  GET_SUSPICION_DETECTION: {
    ENDPOINT: 'getSuspicionDetection',
    QUERY: 'useGetSuspicionDetectionQuery',
  },
  SUSPICION_DETECTION: {
    ENDPOINT: 'suspicionDetection',
    MUTATION: 'useSuspicionDetectionMutation',
  },
};

export const EXAMINEE = {
  GET: {
    ENDPOINT: 'getExaminee',
    QUERY: 'useGetExamineeQuery',
  },
  INVITE: {
    ENDPOINT: 'inviteExaminee',
    MUTATION: 'useInviteExamineeMutation',
  },
  UPDATE: {
    ENDPOINT: 'updateExaminee',
    MUTATION: 'useUpdateExamineeMutation',
  },
};

export const EXAMINEES = {
  GET: {
    ENDPOINT: 'getExaminees',
    QUERY: 'useGetExamineesQuery',
  },
  GET_ORGANIZATION_EXAMINEES: {
    ENDPOINT: 'getOrganizationExaminees',
    QUERY: 'useGetOrganizationExamineesQuery',
  },
};

export const ERRORS = {
  CONDITION_ERROR: 'ConditionError',
};

export const ORGANIZATION = {
  GET: {
    ENDPOINT: 'getOrganization',
    QUERY: 'useGetOrganizationQuery',
  },
  CREATE: {
    ENDPOINT: 'createOrganization',
    MUTATION: 'useCreateOrganizationMutation',
  },
  DELETE: {
    ENDPOINT: 'deleteOrganization',
    MUTATION: 'useDeleteOrganizationMutation',
  },
  UPDATE: {
    ENDPOINT: 'updateOrganization',
    MUTATION: 'useUpdateOrganizationMutation',
  },
};

export const ORGANIZATIONS = {
  GET: {
    ENDPOINT: 'getOrganizations',
    QUERY: 'useGetOrganizationsQuery',
  },
};

export const PROCTORING = {
  GET: {
    EVALUATIONS: {
      ENDPOINT: 'getProctoringEvaluations',
      QUERY: 'useGetProctoringEvaluationsQuery',
    },
    TEXTS: {
      ENDPOINT: 'getProctoringTexts',
      QUERY: 'useGetProctoringTextsQuery',
    },
    EXAM_PREVIEW: {
      ENDPOINT: 'getProctoringExamPreview',
      QUERY: 'useGetProctoringExamPreviewQuery',
    },
  },
  UPDATE: {
    EVALUATION: {
      ENDPOINT: 'updateProctoringEvaluation',
      MUTATION: 'useUpdateProctoringEvaluationMutation',
    },
    TEXTS: {
      ENDPOINT: 'updateProctoringTexts',
      MUTATION: 'useUpdateProctoringTextsMutation',
    },
  },
  CREATE: {
    REVIEW: {
      ENDPOINT: 'createExamReview',
      MUTATION: 'useCreateExamReviewMutation',
    },
  },
};

export const QUESTION = {
  CREATE: {
    ENDPOINT: 'createQuestion',
    MUTATION: 'useCreateQuestionMutation',
  },
  CREATE_CATEGORIES: {
    ENDPOINT: 'createQuestionCategories',
    MUTATION: 'useCreateQuestionCategoriesMutation',
  },
  DELETE: {
    ENDPOINT: 'deleteQuestion',
    MUTATION: 'useDeleteQuestionMutation',
  },
  GET: {
    ENDPOINT: 'getQuestion',
    QUERY: 'useGetQuestionQuery',
  },
  GET_CONTENT: {
    ENDPOINT: 'getQuestionContent',
    QUERY: 'useGetQuestionContentQuery',
  },
  GET_CATEGORIES: {
    ENDPOINT: 'getQuestionCategories',
    QUERY: 'useGetQuestionCategoriesQuery',
  },
  GET_HTML: {
    ENDPOINT: 'getQuestionHtml',
    QUERY: 'useGetQuestionHtmlQuery',
  },
  UPDATE: {
    QUESTION_NAME: {
      ENDPOINT: 'updateQuestionName',
      MUTATION: 'useUpdateQuestionNameMutation',
    },
    QUESTION_STATE: {
      ENDPOINT: 'updateQuestionState',
      MUTATION: 'useUpdateQuestionStateMutation',
    },
    QUESTION_CONTENT: {
      ENDPOINT: 'updateQuestionContent',
      MUTATION: 'useUpdateQuestionContentMutation',
    },
    QUESTION_SCORE: {
      ENDPOINT: 'updateQuestionScore',
      MUTATION: 'useUpdateQuestionScoreMutation',
    },
  },
};

export const QUESTIONS = {
  GET: {
    ENDPOINT: 'getQuestions',
    QUERY: 'useGetQuestionsQuery',
  },
  GET_COUNT: {
    ENDPOINT: 'getQuestionsCount',
    QUERY: 'useGetQuestionsCountQuery',
  },
  GET_DONE: {
    ENDPOINT: 'getQuestionsDone',
    QUERY: 'useGetQuestionsDoneQuery',
  },
  GET_INSTRUCTIONS: {
    ENDPOINT: 'getInstructions',
    QUERY: 'useGetInstructionsQuery',
  },
  GET_SUPPORTING_MATERIALS: {
    ENDPOINT: 'getSupportingMaterials',
    QUERY: 'useGetSupportingMaterialsQuery',
  },
};

export const REQUEST_STATUS = {
  FULFILLED: 'fulfilled',
  PENDING: 'pending',
};

export const TEST = {
  CREATE: {
    ENDPOINT: 'createTest',
    MUTATION: 'useCreateTestMutation',
  },
  CREATE_CATEGORIES: {
    ENDPOINT: 'createTestCategories',
    MUTATION: 'useCreateTestCategoriesMutation',
  },
  DELETE: {
    ENDPOINT: 'deleteTest',
    MUTATION: 'useDeleteTestMutation',
  },
  GET: {
    ENDPOINT: 'getTest',
    QUERY: 'useGetTestQuery',
  },
  GET_CATEGORIES: {
    ENDPOINT: 'getTestCategories',
    QUERY: 'useGetTestCategoriesQuery',
  },
  GET_HTML: {
    ENDPOINT: 'getTestHtml',
    QUERY: 'useGetTestHtmlQuery',
  },
  UPDATE: {
    TEST_CONTENT: {
      ENDPOINT: 'updateTestContent',
      MUTATION: 'useUpdateTestContentMutation',
    },
    TEST_NAME: {
      ENDPOINT: 'updateTestName',
      MUTATION: 'useUpdateTestNameMutation',
    },
    TEST_STATE: {
      ENDPOINT: 'updateTestState',
      MUTATION: 'useUpdateTestStateMutation',
    },
  },
};

export const TESTS = {
  GET: {
    ENDPOINT: 'getTests',
    QUERY: 'useGetTestsQuery',
  },
  GET_COUNT: {
    ENDPOINT: 'getTestsCount',
    QUERY: 'useGetTestsCountQuery',
  },
  GET_DONE: {
    ENDPOINT: 'getTestsDone',
    QUERY: 'useGetTestsDoneQuery',
  },
};

export const USER = {
  CREATE: {
    ADMIN: {
      ENDPOINT: 'createAdminUser',
      MUTATION: 'useCreateAdminUserMutation',
    },
    ENDPOINT: 'createUser',
    MUTATION: 'useCreateUserMutation',
  },
  DELETE: {
    ENDPOINT: 'deleteUser',
    MUTATION: 'useDeleteUserMutation',
  },
  UPDATE: {
    ENDPOINT: 'updateUser',
    MUTATION: 'useUpdateUserMutation',
  },
};

export const USERS = {
  GET_BY_ORGANIZATION: {
    ENDPOINT: 'getUsersByOrganization',
    QUERY: 'useGetUsersByOrganizationQuery',
  },
};
