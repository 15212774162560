import { MultiSelect } from '@progress/kendo-react-dropdowns';

const SelectCategories = ({ state, stateSetter, ...rest }) => {
  const handleCategoriesChange = (event) => {
    const values = event.target.value;
    const lastItem = values[values.length - 1];
    const isCustom = lastItem.idCategory === undefined;
    if (lastItem && isCustom) {
      values.pop();
      const sameItem = values.find((value) => value.name === lastItem.name);
      if (sameItem === undefined) {
        values.push(lastItem);
      }
    }
    stateSetter({
      ...state,
      notification: '',
      categories: values,
    });
  };

  return (
    <MultiSelect
      {...rest}
      textField="name"
      onChange={handleCategoriesChange}
      value={state.categories}
      allowCustom={true}
    />
  );
};

export default SelectCategories;
