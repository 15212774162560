import { CLASSROOM, CLASSROOMS } from '../../consts/RTK';
import { arrToObjIdAsKey } from '../../utils';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { curry, mergeLeft } from 'ramda';
import { endpoints } from './classroomsApiSlice';

const classroomsSlice = createSlice({
  name: 'classrooms',
  initialState: {},
  reducers: {
    // TODO - Delete when apis ready, replace with mutation
    deleteMember: (state, { payload }) => {
      const { classroomId, memberId } = payload;

      state[classroomId] = {
        ...state[classroomId],
        members: state[classroomId].members.filter(({ id }) => id !== memberId),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          endpoints[CLASSROOM.CREATE.ENDPOINT].matchFulfilled,
          endpoints[CLASSROOM.UPDATE.CLASSROOM_NAME.ENDPOINT].matchFulfilled
        ),
        (state, { payload }) => {
          state[payload.idGroup] = payload;
        }
      )
      .addMatcher(
        endpoints[CLASSROOM.DELETE.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          Reflect.deleteProperty(state, meta.arg.originalArgs);
        }
      )
      .addMatcher(
        endpoints[CLASSROOM.DELETE_EXAMINEE.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          state[meta.arg.originalArgs.idGroup].examinees = state[
            meta.arg.originalArgs.idGroup
          ].examinees.filter(
            ({ idExaminee }) => idExaminee !== meta.arg.originalArgs.idExaminee
          );
        }
      )
      .addMatcher(
        endpoints[CLASSROOM.GET.ENDPOINT].matchFulfilled,
        (state, { payload }) => {
          state[payload.idGroup] = payload;
        }
      )
      .addMatcher(
        endpoints[CLASSROOMS.GET.ENDPOINT].matchFulfilled,
        (state, { payload }) => {
          return mergeLeft(state, arrToObjIdAsKey(payload.data, 'idGroup'));
        }
      )
      .addMatcher(
        endpoints[CLASSROOM.GET_EXAMINEES.ENDPOINT].matchFulfilled,
        (state, { meta, payload }) => {
          state[meta.arg.originalArgs.idGroup] = {
            ...state[meta.arg.originalArgs.idGroup],
            examinees: payload.data,
          };
        }
      );
  },
});

export const { createUpdateClassroom, deleteClassroom, deleteMember } =
  classroomsSlice.actions;

export default classroomsSlice.reducer;

export const getClassroom = curry(
  (classroomId, state) => state.classrooms?.[classroomId] || {}
);
