export const questionCategoriesMock = [
  'English',
  'Mathematics',
  'Psychology',
  'Priority',
  'Test',
];

export const termsMock = {
  1: {
    categories: ['Psychology'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 1,
    name: 'Independent School Entrance Examination',
    questions: 33,
    status: 'Draft',
    updatedAt: 1651311111155,
    pages: [
      { layout: 'horizontal', questions: [1] },
      { layout: 'horizontal', questions: [1, 2, 3, 4] },
      { layout: 'vertical', questions: [1, 2] },
      { layout: 'horizontal', questions: [1, 2, 3] },
    ],
  },
  2: {
    categories: ['Mathematics'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 2,
    name: 'Secondary School Admission Test',
    questions: 11,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  3: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 3,
    name: 'Cooperative admissions examination program',
    questions: 13,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  4: {
    categories: ['Psychology'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 4,
    name: 'Independent School Entrance Examination',
    questions: 73,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  5: {
    categories: ['Mathematics'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 5,
    name: 'Secondary School Admission Test',
    questions: 33,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  6: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 6,
    name: 'Cooperative admissions examination program',
    questions: 55,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  7: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 7,
    name: 'Independent School Entrance Examination',
    questions: 83,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  8: {
    categories: ['Mathematics'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 8,
    name: 'Secondary School Admission Test',
    questions: 33,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  9: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 9,
    name: 'Cooperative admissions examination program',
    questions: 13,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  10: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 10,
    name: 'Independent School Entrance Examination',
    questions: 42,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  11: {
    categories: ['Mathematics'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 11,
    name: 'Secondary School Admission Test',
    questions: 33,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  12: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 12,
    name: 'Cooperative admissions examination program',
    questions: 33,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  13: {
    categories: ['Mathematics'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 13,
    name: 'Independent School Entrance Examination',
    questions: 33,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  14: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 14,
    name: 'Secondary School Admission Test',
    questions: 33,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  15: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 15,
    name: 'Cooperative admissions examination program',
    questions: 33,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  16: {
    categories: ['Psychology'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 16,
    name: 'Independent School Entrance Examination',
    questions: 33,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  17: {
    categories: ['Mathematics'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 17,
    name: 'Secondary School Admission Test',
    questions: 33,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  18: {
    categories: ['Mathematics'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 18,
    name: 'Cooperative admissions examination program',
    questions: 41,
    status: 'Draft',
    updatedAt: 1651311111155,
  },
  19: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 19,
    name: 'Independent School Entrance Examination',
    questions: 3,
    status: 'Done',
    updatedAt: 1651311111155,
  },
  20: {
    categories: ['English'],
    createdAt: 1651311111155,
    creatorEmail: 'olivia@company.com',
    creatorName: 'Olivia Rhye',
    id: 20,
    name: 'Secondary School Admission Test',
    questions: 23,
    status: 'Done',
    updatedAt: 1651311111155,
  },
};

export const examMock = {
  event: 'Independent School Entrance Examination',
  id: '2b86c84c-6863-47c0-9dd7-28dce6cc0e12',
  externalId: '1400908f-d442-4e04-9552-f812def1cdae',
  customerId: 'bf4228a9-b092-48a5-9331-772e0ac19153',
  startedAt: '2022-09-20T11:05:13.586Z',
  endedAt: '2022-09-20T11:20:36.443Z',
  firstName: 'Jáchym',
  lastName: 'Vintr',
  dateOfBirth: '1995-09-17T00:00:00.000Z',
  status: 'FINISHED',
  jobStatus: 'COMPLETED',
  gdprConsentAt: '2022-09-20T11:06:19.353Z',
  rulesConsentAt: '2022-09-20T11:06:22.759Z',
  streamStartedAt: '2022-09-20T11:06:25.978Z',
  roomCheckpointStartedAt: '2022-09-20T11:07:43.572Z',
  examCheckpointStartedAt: '2022-09-20T11:14:50.728Z',
  idCheckpointStartedAt: '2022-09-20T11:10:27.993Z',
  faceCheckpointStartedAt: '2022-09-20T11:08:33.384Z',
  assignedTo: null,
  reviewerReview: null,
  commissionerReview: null,
  finalReview: null,
  reviewerReviewedBy: null,
  commissionerReviewedBy: null,
  reviewerReviewedAt: null,
  commissionerReviewedAt: null,
  reviewerReviewStartedAt: null,
  commissionerReviewStartedAt: null,
  reviewerReviewDurationSec: null,
  commissionerReviewDurationSec: null,
  postponed: false,
  violationNote: null,
  finalViolationMessage: null,
  evaluationDetail: null,
  finalViolationCode: null,
  isViolationIntentional: null,
  customViolationsCount: 0,
  aIViolationsCount: 0,
  consistency: true,
  crossTermConsistency: false,
  definitionId: '855fe184-a702-41b5-aaa2-e8db43d8ef5e',
  webcamUrl:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  screenUrl:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  idUrl: '',
  faceUrl: '',
  roomVideoUrl:
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
};

export const violationsMock = [
  { name: 'Forbidden object', time: 13000 },
  { name: 'High brightness', time: 27000 },
];
