import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ROUTES from '../../consts/ROUTES';
import { useEffect } from 'react';
import './Login.scss';

const CheckEmail = ({ resetPassEmail }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (resetPassEmail === '') navigate(ROUTES.PUBLIC.FORGOTTEN_PASSWORD.PATH);
  }, [navigate, resetPassEmail]);

  return (
    <div className="login">
      <span className="logo">
        <img alt="Logo" src="/images/ellipse.svg" />
        <img alt="Logo" src="/images/ellipse.svg" />
      </span>
      <h2>
        <FormattedMessage {...messages.checkYourEmail} />
      </h2>
      <h4>
        <FormattedMessage {...messages.weSentLinkToNewPassword} />
        <br />
        <span className="ff-public-sans-medium">{resetPassEmail}</span>
      </h4>
      <Button
        className="primary"
        onClick={() => navigate(ROUTES.PUBLIC.LOGIN.PATH)}
      >
        <FormattedMessage {...messages.goBackToLogIn} />
      </Button>
      <div className="footer">
        <FormattedMessage {...messages.didntReciveEmail} />{' '}
        <Link to={`${ROUTES.PUBLIC.FORGOTTEN_PASSWORD.PATH}`} className="link">
          <FormattedMessage {...messages.clickToResend} />
        </Link>
      </div>
    </div>
  );
};

export default CheckEmail;
