import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { USER_ROLES } from '../../consts/API';
import { AUTH } from '../../consts/RTK';
import { LSM } from '../../utils';
import { endpoints } from './authApiSlice';

const initialState = {
  user: LSM.getUser(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logOut: () => {
      LSM.deleteUser();
      return { user: null };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        endpoints[AUTH.LOGIN.ENDPOINT].matchFulfilled,
        endpoints[AUTH.LOGIN_TO_ORGANIZATION.ENDPOINT].matchFulfilled
      ),
      (state, { payload }) => {
        LSM.setUser(payload);
        state.user = payload;
      }
    );
  },
});

export const { logOut } = authSlice.actions;

export default authSlice.reducer;

export const getIsAdmin = (state) =>
  state.auth.user?.roles.includes(USER_ROLES.ADMIN);
export const getIsEventManager = (state) =>
  state.auth.user?.roles.includes(USER_ROLES.EVENT_MANAGER);
export const getIsOrganizator = (state) =>
  state.auth.user?.roles.includes(USER_ROLES.AUTHOR) ||
  state.auth.user?.roles.includes(USER_ROLES.EVENT_MANAGER) ||
  state.auth.user?.roles.includes(USER_ROLES.EXPERT);
export const getIsSuperadmin = (state) =>
  state.auth.user?.roles.includes(USER_ROLES.SUPERADMIN);
export const getUser = (state) => state.auth?.user ?? {};
export const getUserEmail = (state) => state.auth.user?.email;
export const getUserFullName = (state) =>
  `${state.auth.user?.name} ${state.auth.user?.surname}`;
export const getUserId = (state) => state.auth.user?.id;
export const getUserOrganizationId = (state) => state.auth.user?.organizationId;
export const getUserRoles = (state) => state.auth.user?.roles;
