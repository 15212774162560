import { apiSlice } from '../api/apiSlice';
import { ORGANIZATION, ORGANIZATIONS } from '../../consts/RTK';
import { API, API_SEGMENT } from '../../consts/API';

export const organizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get admin organization - returns organization admin is currently logged in to
    [ORGANIZATION.GET.ENDPOINT]: builder.query({
      query: (userId) =>
        `${API[API_SEGMENT.ORGANIZATION].ORGANIZATION_ADMIN}/${userId}`,
      providesTags: ['AdminOrganization'],
    }),
    // Create organization
    [ORGANIZATION.CREATE.ENDPOINT]: builder.mutation({
      query: (payload) => ({
        url: API[API_SEGMENT.ORGANIZATION].ORGANIZATIONS,
        method: 'POST',
        body: payload,
      }),
    }),
    // Delete organization
    [ORGANIZATION.DELETE.ENDPOINT]: builder.mutation({
      query: (id) => ({
        url: `${API[API_SEGMENT.ORGANIZATION].ORGANIZATIONS}/${id}`,
        method: 'DELETE',
      }),
    }),
    // Get organizations - returns organizations related to userId if provided,
    // otherwise returns all organizations (only superadmin)
    [ORGANIZATIONS.GET.ENDPOINT]: builder.query({
      query: (userId) =>
        `${API[API_SEGMENT.ORGANIZATION].ORGANIZATIONS}${
          userId ? '/' + userId : ''
        }`,
    }),
    // Update organization
    [ORGANIZATION.UPDATE.ENDPOINT]: builder.mutation({
      query: ({ id, body }) => ({
        url: `${API[API_SEGMENT.ORGANIZATION].ORGANIZATIONS}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AdminOrganization'],
    }),
  }),
});

export const endpoints = organizationsApiSlice.endpoints;
