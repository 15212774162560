import messages from '../../../intl/messages';
import { Context } from './PublishEventModal';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { equals, flatten, isEmpty } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePrevious } from '../../../utils';
import ClassroomList from './ClassroomList';
import StudentsList from './StudentsList';

const TABS = ['classrooms', 'students'];

const Classroom = () => {
  const { progress, exam, setCurrentClassroom, setProgress } =
    useContext(Context);
  const intl = useIntl();
  const prevExaminees = usePrevious(exam.examinees);
  const [tabState, setTabState] = useState(0);

  // Enable continue button
  useEffect(() => {
    if (!equals(prevExaminees, exam.examinees)) {
      setProgress({
        ...progress,
        allowContinue: !isEmpty(flatten(Object.values(exam.examinees))),
      });
    }
  }, [exam.examinees, prevExaminees, progress, setProgress]);

  // Null currentClassroom upon leaving Students tab
  useEffect(() => {
    if (tabState === 0) setCurrentClassroom(null);
  }, [setCurrentClassroom, tabState]);

  return (
    <div className="Classroom">
      {/* Tabs */}
      <TabStrip
        keepTabsMounted={true}
        onSelect={(e) => setTabState(e.selected)}
        selected={tabState}
      >
        {TABS.map((gridType, i) => (
          <TabStripTab
            key={`PublishEventModal-Classroom-${gridType}-${i}`}
            title={intl.formatMessage(messages[gridType])}
          >
            {gridType === TABS[0] ? (
              <ClassroomList setTabState={setTabState} />
            ) : (
              <StudentsList setTabState={setTabState} />
            )}
          </TabStripTab>
        ))}
      </TabStrip>
    </div>
  );
};

export default Classroom;
