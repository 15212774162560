import { Fade } from '@progress/kendo-react-animation';
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import NOTIFICATIONS from '../consts/NOTIFICATIONS';
import messages from '../intl/messages';
import { getNotifications, deleteNotification } from '../store/ui/uiSlice';

const displayed = [];

const Notifications = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const notifications = useSelector(getNotifications);
  const isSuccess = notifications[0]?.type === NOTIFICATIONS.SUCCESS;
  let count = 0;

  const timeout = setTimeout(
    () => {
      const notificationId = notifications[0]?.id;

      if (notificationId && !displayed.includes(notificationId)) {
        dispatch(deleteNotification(notifications[0].id));
        displayed.push(notificationId);
      }

      clearTimeout(timeout);
    },
    isSuccess ? 2000 : 10000
  );

  return (
    <NotificationGroup>
      {notifications
        .filter(({ method, type }) => {
          // Notifications for POST requests only
          if (method !== 'POST') return false;

          // Display only one success notification at the time
          if (type === 'success') count++;
          return type !== 'success' || count < 2;
        })
        .map(({ id, message, type }) => (
          <Fade key={`Notifications-${id}-${type}`}>
            <Notification
              closable={true}
              onClose={() => dispatch(deleteNotification(id))}
              type={{ icon: true, style: type }}
            >
              <span>
                {intl.formatMessage(messages[type])}
                {'! '}
                {/* TODO: Specific error message when BE is ready */}
                {/* {type === 'error'
                ? 'Something went wrong with the BE. TODO: Specific error message when BE is ready.'
                : intl.formatMessage(messages.notification[type][message])}
              {'.'} */}
              </span>
            </Notification>
          </Fade>
        ))}
    </NotificationGroup>
  );
};

export default Notifications;
