import LogOut from './LogOut';
import styles from './TopBarSuper.module.scss';

const TopBarSuper = () => {
  return (
    <div className={styles['top-bar-super']}>
      <LogOut />
      <img className="logo" alt="logo" src="/images/logo.png" />
    </div>
  );
};

export default TopBarSuper;
