import messages from '../../../intl/messages';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { CLASSROOMS } from '../../../consts/RTK';
import { apiSlice } from '../../../store/api/apiSlice';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import { Context } from '../../Modals/InviteExamineeModal';
import { GRID } from '../../../consts/KENDO';

const SelectExamineeClassrooms = ({ ...rest }) => {
  const { state, setState } = useContext(Context);
  const intl = useIntl();

  // Fetch all classrooms
  const { data } = apiSlice[CLASSROOMS.GET.QUERY](
    GRID.DEFAULT_DATA_STATE_1000,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const allClassrooms = data?.data || [];

  return (
    <MultiSelect
      data={allClassrooms}
      dataItemKey="idGroup"
      onChange={(e) => setState({ ...state, idGroups: e.value })}
      placeholder={intl.formatMessage(messages.typeOrSelectClassrooms)}
      textField="name"
      value={state.idGroups}
      {...rest}
    />
  );
};

export default SelectExamineeClassrooms;
