import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  CLASSROOM,
  ORGANIZATION,
  PROCTORING,
  QUESTION,
  TEST,
  USER,
} from '../../consts/RTK';
import messages from '../../intl/messages';
import { closeModal } from '../../store/modals/modalsSlice';
import { endpoints } from '../../store/api/apiSlice';
import { MODAL_TYPES } from './ModalsRoot';
import ModalTemplate from './ModalTemplate';
import { deleteEvent } from '../../store/events/eventsSlice';
import { useNavigate } from 'react-router';
import { EDITOR_PROCTORING } from '../../consts/EDITOR';

const composeMessage = (intl, { name }, intlKey) =>
  intl.formatMessage(messages.youAreDeletingItemWithName, {
    item: intl.formatMessage(messages[intlKey]),
    name,
  });

const ConfirmModal = ({ item, modalType }) => {
  const [apiError, setApiError] = useState(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const editorRef = useRef();

  let apiCall, goBack, message, list, noControls, saveBtnTitle, title;

  switch (modalType) {
    // Confirm proctoring text
    case MODAL_TYPES.CONFIRM_PROCTORING_TEXT:
      apiCall = async () => {
        await dispatch(
          endpoints[PROCTORING.UPDATE.TEXTS.ENDPOINT].initiate({
            language: item.language,
            payload: {
              ...item.texts,
              [item.textKey]: editorRef.current?.editor.getContent(),
            },
          })
        );
      };
      message = (
        <Editor
          apiKey={EDITOR_PROCTORING.API_KEY}
          init={EDITOR_PROCTORING.INIT}
          ref={editorRef}
          initialValue={item.texts[item.textKey]}
        />
      );
      saveBtnTitle = intl.formatMessage(messages.confirm);
      title = intl.formatMessage(messages[item.textKey]);
      break;

    // Delete classroom
    case MODAL_TYPES.DELETE_CLASSROOM:
      apiCall = () =>
        dispatch(endpoints[CLASSROOM.DELETE.ENDPOINT].initiate(item.idGroup));
      message = composeMessage(intl, item, 'accusativeClassroom');
      title = intl.formatMessage(messages.deleteClassroom);
      break;

    // Delete distractor
    case MODAL_TYPES.DELETE_DISTRACTOR:
      apiCall = () => {
        dispatch(
          endpoints[QUESTION.UPDATE.QUESTION_CONTENT.ENDPOINT].initiate({
            typeDiscriminator: item.typeDiscriminator,
            typeValue: item.typeValue,
          })
        );
      };
      message = composeMessage(intl, item, 'accusativeDistractor');
      title = intl.formatMessage(messages.deleteDistractor);
      break;

    // Delete event
    case MODAL_TYPES.DELETE_EVENT:
      // TODO - Connect RTKQ when api's ready
      apiCall = () => dispatch(deleteEvent(item.id));
      message = composeMessage(
        intl,
        { ...item, name: item.examName },
        'accusativeEvent'
      );
      title = intl.formatMessage(messages.deleteEvent);
      break;

    // Delete examinee from classroom
    case MODAL_TYPES.DELETE_EXAMINEE_FROM_CLASSROOM:
      apiCall = () =>
        dispatch(
          endpoints[CLASSROOM.DELETE_EXAMINEE.ENDPOINT].initiate({
            idExaminee: item.idExaminee,
            idGroup: item.idGroup,
          })
        );
      message = intl.formatMessage(messages.deleteExamineeFromClassroomDesc);
      title = intl.formatMessage(messages.deleteExamineeFromClassroom);
      saveBtnTitle = intl.formatMessage(messages.deleteExamineeFromClassroom);
      break;

    // Delete organization
    case MODAL_TYPES.DELETE_ORGANIZATION:
      apiCall = () =>
        dispatch(endpoints[ORGANIZATION.DELETE.ENDPOINT].initiate(item.id));
      message = composeMessage(intl, item, 'accusativeOrganization');
      title = intl.formatMessage(messages.deleteOrganization);
      break;

    // Delete question
    case MODAL_TYPES.DELETE_QUESTION:
      apiCall = () =>
        dispatch(endpoints[QUESTION.DELETE.ENDPOINT].initiate(item.idItem));
      message = composeMessage(intl, item, 'accusativeQuestion');
      title = intl.formatMessage(messages.deleteQuestion);
      break;

    // Delete test
    case MODAL_TYPES.DELETE_TEST:
      apiCall = () =>
        dispatch(endpoints[TEST.DELETE.ENDPOINT].initiate(item.idTest));
      message = composeMessage(intl, item, 'accusativeTest');
      title = intl.formatMessage(messages.deleteTest);
      break;

    // Delete user
    case MODAL_TYPES.DELETE_USER:
      apiCall = () =>
        dispatch(endpoints[USER.DELETE.ENDPOINT].initiate(item.id));
      message = composeMessage(intl, item, 'accusativeUser');
      title = intl.formatMessage(messages.deleteUser);
      break;

    // Has missing some properties
    case MODAL_TYPES.HAS_MISSING:
      message = intl.formatMessage(
        messages[`confirm${item.message}HasMissing`]
      );
      list = item.list.map((property) => {
        return <li key={`missing-property-${property}`}>{property}</li>;
      });
      title = intl.formatMessage(messages[`publish${item.message}`]);
      noControls = true;
      break;

    // Can't switch to draft
    case MODAL_TYPES.SWITCH_TO_DRAFT:
      message = intl.formatMessage(
        messages[`confirm${item.message}SwitchToDraft`]
      );
      title = intl.formatMessage(messages[`publish${item.message}`]);
      noControls = true;
      break;

    // Zoom image
    case MODAL_TYPES.ZOOM_IMAGE:
      message = <img alt="" src={item.imageSrc} />;
      title = ' ';
      noControls = true;
      break;

    default:
      break;
  }

  const handleApiCall = async () => {
    const result = await apiCall();

    if (result?.error) {
      setApiError(result.error.data.message);
    } else {
      dispatch(closeModal());
      if (goBack) navigate(-1);
    }
  };

  return (
    <ModalTemplate
      noControls={noControls}
      onSave={handleApiCall}
      saveBtnTitle={saveBtnTitle || <FormattedMessage {...messages.delete} />}
      title={title}
    >
      {message}
      {list && list.length > 0 ? <ul>{list}</ul> : null}
      <br />
      <br />
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default ConfirmModal;
