import { LOCALE } from '../../consts/INTL';

const plugins = [
  'anchor',
  'autolink',
  'charmap',
  'code',
  'link',
  'lists',
  'nonbreaking',
  'table',
  'wordcount',
];

const toolbar =
  'undo redo | ' +
  'boldText italicText underlineText | ' +
  'alignment | ' +
  'bullist numlist | ' +
  'link unlink | ' +
  'superscript subscript | ' +
  'table code nonbreaking';

const style = `
  body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }
  boldtext { font-weight: bold; }
  italictext { font-style: italic; }
  underlinetext { text-decoration: underline; }
`;

const proctoring = {
  entity_encoding: 'raw',
  height: 300,
  menubar: false,
  language: LOCALE.EN,
  plugins: plugins,
  toolbar: toolbar,
  toolbar_mode: 'wrap',
  nonbreaking_wrap: false,
  table_use_colgroups: false,
  link_default_target: '_blank',
  content_style: style,
  icons_url: '/images/ScioLinkCloud/icons.js',
  icons: 'ScioLinkCloud',
  extended_valid_elements: 'boldtext,italictext,underlinetext',
  custom_elements: '~boldtext,~italictext,~underlinetext',
  cleanup_on_startup: false,
  cleanup: false,
  formats: {
    boldtext: { inline: 'boldtext' },
    italictext: { inline: 'italictext' },
    underlinetext: { inline: 'underlinetext' },
    alignleft: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'left' },
    },
    aligncenter: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'center' },
    },
    alignright: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'right' },
    },
    alignjustify: {
      selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video',
      attributes: { align: 'justify' },
    },
  },
  setup: (editor) => {
    editor.ui.registry.addGroupToolbarButton('alignment', {
      icon: 'align-left',
      tooltip: 'Alignment',
      items: 'alignleft aligncenter alignright alignjustify',
    });
    const testElements = (testedElement, exclude) => {
      const elements = ['boldtext', 'italictext', 'underlinetext'];
      const filteredElements = elements.filter((item) => {
        return item !== exclude;
      });
      return !filteredElements.includes(testedElement);
    };
    const customAddToggleButton = (buttonName, icon, tooltip, elementName) => {
      editor.ui.registry.addToggleButton(buttonName, {
        icon: icon,
        enabled: true,
        // TODO translation
        tooltip: tooltip,
        onAction: (_) => {
          editor.execCommand('mceToggleFormat', false, elementName);
        },
        onSetup: (buttonApi) => {
          buttonApi.setActive(editor.formatter.match(elementName));
          const changed = editor.formatter.formatChanged(elementName, (state) =>
            buttonApi.setActive(state)
          );
          const editorEventCallback = (eventApi) => {
            const element = eventApi.element.nodeName.toLowerCase();
            buttonApi.setEnabled(testElements(element, elementName));
          };
          editor.on('NodeChange', editorEventCallback);

          return () => {
            editor.off('NodeChange', editorEventCallback);
            changed.unbind();
          };
        },
      });
    };
    customAddToggleButton('boldText', 'bold', 'Bold', 'boldtext');
    customAddToggleButton('italicText', 'italic', 'Italic', 'italictext');
    customAddToggleButton(
      'underlineText',
      'underline',
      'Underline',
      'underlinetext'
    );
  },
};

export default proctoring;
