import { TEST_STATE } from '../../consts/ORGANIZATOR';
import { TEST, TESTS } from '../../consts/RTK';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { curry } from 'ramda';
import { endpoints } from './testsApiSlice';
import { arrToObjIdAsKey } from '../../utils';

const testsSlice = createSlice({
  name: 'tests',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        endpoints[TEST.CREATE.ENDPOINT].matchFulfilled,
        (state, { payload }) => ({
          ...state,
          [payload.idTest]: {
            categories: [],
            createdAt: payload.dateCreatedUtc,
            creatorEmail: payload.userCreated,
            id: payload.idTest,
            name: payload.name,
            pages: [],
            status: TEST_STATE.DRAFT,
          },
        })
      )
      .addMatcher(
        endpoints[TEST.DELETE.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          Reflect.deleteProperty(state, meta.arg.originalArgs);
        }
      )
      .addMatcher(
        isAnyOf(endpoints[TESTS.GET.ENDPOINT].matchFulfilled),
        (state, { payload }) => arrToObjIdAsKey(payload.data, 'idTest')
      )
      .addMatcher(
        endpoints[TEST.CREATE_CATEGORIES.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          const idTest = meta.arg.originalArgs.idTest;

          state[idTest] = {
            ...state[idTest],
            categories: meta.arg.originalArgs.categories,
          };
        }
      )
      .addMatcher(
        isAnyOf(endpoints[TEST.GET.ENDPOINT].matchFulfilled),
        (state, { payload }) => {
          state[payload.idTest] = { ...state[payload.idTest], ...payload };
        }
      )
      .addMatcher(
        endpoints[TEST.UPDATE.TEST_CONTENT.ENDPOINT].matchFulfilled,
        (state, { meta, payload }) => {
          state[payload.idTest] = {
            ...state[payload.idTest],
            content: payload.content,
          };
        }
      )
      .addMatcher(
        isAnyOf(
          endpoints[TEST.UPDATE.TEST_NAME.ENDPOINT].matchFulfilled,
          endpoints[TEST.UPDATE.TEST_STATE.ENDPOINT].matchFulfilled
        ),
        (state, { payload }) => {
          state[payload.idTest] = { ...state[payload.idTest], ...payload };
        }
      );
  },
});

export const { createUpdateTest } = testsSlice.actions;

export default testsSlice.reducer;

export const getTest = curry((idTest, state) => state.tests?.[idTest]);
export const getTests = (state) => state.tests;
