import messages from '../../../../intl/messages';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { TEST } from '../../../../consts/RTK';
import { apiSlice } from '../../../../store/api/apiSlice';
import { useIntl } from 'react-intl';

const SelectTestCategories = ({ idTest }) => {
  const intl = useIntl();

  // Fetch all categories
  const { data: allCategories } = apiSlice[TEST.GET_CATEGORIES.QUERY](null, {
    refetchOnMountOrArgChange: true,
  });

  // Fetch test categories
  const { data: testCategories } = apiSlice[TEST.GET_CATEGORIES.QUERY](idTest, {
    refetchOnMountOrArgChange: true,
  });

  // Mutate test categories
  const [createTestCategories, { data: testCategoriesUpdated, isLoading }] =
    apiSlice[TEST.CREATE_CATEGORIES.MUTATION]();

  return (
    <MultiSelect
      allowCustom
      data={allCategories ?? []}
      dataItemKey="idCategory"
      onChange={(e) =>
        // Save categories
        createTestCategories({
          idTest,
          categories: e.value.map(({ idCategory, name }) => ({
            idCategory: idCategory ?? null,
            name,
          })),
        })
      }
      placeholder={intl.formatMessage(messages.typeOrSelectCategories)}
      textField="name"
      value={isLoading ? [] : testCategoriesUpdated || testCategories}
    />
  );
};

export default SelectTestCategories;
