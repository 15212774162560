import 'react-time-seek-slider/lib/ui-time-seek-slider.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import messages from '../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { Context } from '../../Settings/Results/Results';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Slider } from '@progress/kendo-react-inputs';
import { TimeSeekSlider } from 'react-time-seek-slider';
import { isEmpty } from 'ramda';
import { msToTime } from '../../../utils';
import { useIntl } from 'react-intl';

const SPEED_CONFIG = [0.5, 1, 2, 3, 4, 5];

const VideoControlBar = ({ video, originalVideo, checkpointOffsets }) => {
  const { videoState, setVideoState } = useContext(Context);
  const intl = useIntl();
  const selectRef = useRef();

  // https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
  // Event listeners for rewind and forward can access freshState through a reference
  const [vidState, _setVidState] = useState({
    currentTime: 0,
    duration: video?.duration ?? null,
    playbackRate: 1,
    volume: video.volume,
  });
  const vidStateRef = useRef(vidState);
  const setVidState = (data) => {
    vidStateRef.current = data;
    _setVidState(data);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  const isPaused = videoState === 'pause';
  // VIDEO EVENTS
  video.ondurationchange = (e) =>
    setVidState({
      ...vidState,
      duration: !isNaN(e.target.duration)
        ? e.target.duration
        : video?.duration ?? null,
    });
  video.onratechange = (e) => {
    setVidState({ ...vidState, playbackRate: e.target.playbackRate });

    // If there is a video playing in PiP, sync its speed
    if (originalVideo) {
      originalVideo.playbackRate = e.target.playbackRate;
    }
  };
  video.ontimeupdate = (e) =>
    setVidState({ ...vidState, currentTime: e.target.currentTime });
  video.onvolumechange = (e) =>
    setVidState({ ...vidState, volume: e.target.volume });

  useEffect(() => {
    const videoMoveHandler = (event) => {
      const nodeName = event?.target?.nodeName;

      if (
        (event.code === 'ArrowLeft' || event.code === 'ArrowRight') &&
        nodeName === 'BODY'
      ) {
        event.preventDefault();
        (() => {
          handleVideoMove(
            event.code === 'ArrowLeft' ? -3 : 3,
            vidStateRef.current
          );
        })();
      }
    };

    window.addEventListener('keydown', videoMoveHandler);
    return () => {
      window.removeEventListener('keydown', videoMoveHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFullscreen = () => {
    const videoElem = document.getElementById('exam-playback-video');
    if (videoElem.requestFullscreen) {
      if (document.fullscreenElement) {
        setIsFullscreen(false);
        document.exitFullscreen();
        setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
      } else {
        setIsFullscreen(true);
        videoElem.requestFullscreen();

        // Force the SeekSlider component to recalculate its total track width
        // The event is fired alongside fullscreen by default but SeekSlider cannot get proper values at that time
        setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

        // Remove focus from fullscreen button to enable SPACE video pause in fullscreen mode
        const button = document.getElementById('fullscreenButton');
        button.blur();
      }
    }
  };

  const handleDownload = (e) => {
    e.preventDefault();
    window.location.href = video.src;
  };

  const handleVideoMove = (seconds, vidState = vidStateRef.current) => {
    let newVideoTime;
    if (seconds > 0)
      newVideoTime = Math.min(
        vidState?.duration,
        vidState?.currentTime + seconds
      );
    else newVideoTime = Math.max(0, vidState?.currentTime + seconds);
    video.currentTime = newVideoTime;
  };

  const handleVolumeChange = (e, newValue) => {
    video.volume = isEmpty(e) ? newValue : e.value;
  };

  const isMuted = vidState.volume === 0;
  const isLoud = vidState.volume > 0.5;

  return (
    <div className="video-control-bar">
      <div className="controls">
        <div>
          <DropDownList
            data={SPEED_CONFIG}
            ref={selectRef}
            value={vidState.playbackRate}
            onChange={(e) => {
              video.playbackRate = e.target.value;
            }}
          />

          <Button
            iconClass={`k-icon k-i-volume-${
              isMuted ? 'off' : isLoud ? 'up' : 'down'
            }`}
            onClick={() => handleVolumeChange({}, isMuted ? 1 : 0)}
          />

          <Slider
            min={0}
            max={1}
            step={0.01}
            value={vidState.volume}
            defaultValue={vidState.volume}
            onChange={handleVolumeChange}
          />

          <Button
            icon="rewind"
            onClick={() => handleVideoMove(-3)}
            title={intl.formatMessage(messages.rewind)}
          />
          <Button
            icon={isPaused ? 'play' : 'pause'}
            title={intl.formatMessage(messages[isPaused ? 'play' : 'pause'])}
            onClick={() => setVideoState(isPaused ? 'play' : 'pause')}
          />
          <Button
            className="button"
            icon="forward"
            onClick={() => handleVideoMove(3)}
            title={intl.formatMessage(messages.forward)}
          />
        </div>

        <div>
          <Button
            id="fullscreenButton"
            icon="fullscreen"
            title={intl.formatMessage(messages.fullscreen)}
            onClick={handleFullscreen}
          />
          <Button
            icon="download"
            title={intl.formatMessage(messages.download)}
            onClick={handleDownload}
          />
        </div>
      </div>

      <div className="sliderContainer">
        {checkpointOffsets.map(({ tooltip, offset }, i) => (
          <div
            key={`VideoControlBar-checkpointOffsets-${i}`}
            className="dot tooltip"
            // Prevent the dot overflowing beyond 100% of videoBar
            style={{
              left:
                Math.min((offset / 1000 / vidState.duration) * 100, 100) + '%',
            }}
            onClick={() => {
              // In case the dot has an offset longer than the duration
              // Subtract a fraction of a second from duration, otherwise the video goes back to start
              const time = Math.min(offset / 1000, vidState.duration - 0.05);
              setVidState({ ...vidState, currentTime: time });
              video.currentTime = time;
            }}
          >
            <span className="tooltipText MuiTooltip-tooltip">{tooltip}</span>
          </div>
        ))}
        <TimeSeekSlider
          key={isFullscreen ? 'fullscreen' : 'no-fullscreen'}
          max={vidState.duration}
          currentTime={vidState.currentTime}
          // this works on localhost
          onChange={(time) => {
            setVidState({ ...vidState, currentTime: time });
            video.currentTime = time;
          }}
          // this works on server
          onSeeking={(time) => {
            setVidState({ ...vidState, currentTime: time });
            video.currentTime = time;
          }}
          offset={0}
          secondsPrefix="00:00:"
          minutesPrefix="00:"
        />
        <div className="time">
          {msToTime(vidState.currentTime * 1000)}
          {vidState.duration && '/' + msToTime(vidState.duration * 1000)}
        </div>
      </div>
    </div>
  );
};

export default VideoControlBar;
