import AddNewButton from './AddNewButton';
import NoRecordFound from '../../Events/NoRecordFound';
import messages from '../../../../intl/messages';
import { Button } from '@progress/kendo-react-buttons';
import { Context } from './TestDetail';
import { equals, findIndex, isEmpty, move, propEq } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePrevious } from '../../../../utils';
import {
  ListBox,
  processListBoxDragAndDrop,
} from '@progress/kendo-react-listbox';

const Item = (props) => {
  const { dataItem, selected, ...rest } = props;
  const { isDone, supportingMaterials, updateSupportingMaterials } =
    useContext(Context);
  const intl = useIntl();
  const i =
    findIndex(propEq('idItem', dataItem.idItem))(supportingMaterials) + 1;

  // Remove item
  const removeItem = (idItem) => {
    updateSupportingMaterials(
      supportingMaterials.filter(({ idItem: _idItem }) => _idItem !== idItem)
    );
  };

  return (
    <li {...rest} key={`SupportingMaterial-${i}`}>
      <div className="display-flex gap-12">
        <div>
          <span className="label">{i}</span> {dataItem.name}
        </div>
        <div className="display-flex gap-12">
          <Button
            className="plain"
            imageUrl="/images/arrowUp.svg"
            onClick={() =>
              updateSupportingMaterials(move(i - 1, i - 2, supportingMaterials))
            }
            title={intl.formatMessage(messages.moveUp)}
            disabled={isDone}
          />
          <Button
            className="plain"
            imageUrl="/images/arrowDown.svg"
            onClick={() =>
              updateSupportingMaterials(move(i, i - 1, supportingMaterials))
            }
            title={intl.formatMessage(messages.moveDown)}
            disabled={isDone}
          />
          <Button
            className="plain"
            imageUrl="/images/trashDarker.svg"
            onClick={() => removeItem(dataItem.idItem)}
            title={intl.formatMessage(messages.delete)}
            disabled={isDone}
          />
        </div>
      </div>
      <br />
      <div
        className="item"
        dangerouslySetInnerHTML={{ __html: dataItem.content }}
      />
    </li>
  );
};

const SupportingMaterials = () => {
  const {
    isDone,
    supportingMaterials = [],
    updateSupportingMaterials,
  } = useContext(Context);
  const [listState, setListState] = useState({
    supportingMaterials,
    draggedItem: {},
  });
  const prevSupportingMaterials = usePrevious(supportingMaterials);

  useEffect(() => {
    if (!equals(prevSupportingMaterials, supportingMaterials)) {
      setListState({ ...listState, supportingMaterials });
    }
  }, [listState, prevSupportingMaterials, supportingMaterials]);

  const onDragStart = ({ dataItem }) =>
    setListState({ ...listState, draggedItem: dataItem });

  const onDrop = ({ dataItem }) => {
    const { listBoxOneData: supportingMaterials } = processListBoxDragAndDrop(
      [...listState.supportingMaterials],
      [],
      listState.draggedItem,
      dataItem,
      'idItem'
    );

    updateSupportingMaterials(supportingMaterials);
  };

  if (isEmpty(listState.supportingMaterials)) {
    // No support material found
    return (
      <NoRecordFound
        actionButton={<AddNewButton isSupportMaterial isDone={isDone} />}
        className="no-record-found"
        icon="/images/noTestFound.svg"
        intlKey="supportingMaterials"
      />
    );
  } else {
    // Return simple list to avoid drag&drop issues with ListBox
    if (isDone) {
      const simpleList = listState.supportingMaterials.map((dataItem) => {
        return Item({ dataItem });
      });
      return <ul>{simpleList}</ul>;
    }
    return (
      <ListBox
        data={listState.supportingMaterials}
        item={Item}
        onDragStart={onDragStart}
        onDrop={onDrop}
        textField="idItem"
        draggable={!isDone}
      />
    );
  }
};

export default SupportingMaterials;
