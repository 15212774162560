import ModalTemplate from './ModalTemplate';
import ROUTES from '../../consts/ROUTES';
import messages from '../../intl/messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from '@progress/kendo-react-inputs';
import { TEST } from '../../consts/RTK';
import { TEST_STATE } from '../../consts/ORGANIZATOR';
import { apiSlice } from '../../store/api/apiSlice';
import { closeModal } from '../../store/modals/modalsSlice';
import { createUpdateTest, getTest } from '../../store/tests/testsSlice';
import { getUser } from '../../store/auth/authSlice';
import { omit, pick } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { validateAndSubmit } from '../../utils';
import SelectCategories from '../Common/SelectCategories';

/**
 * If { idTest } passed, the modal would update a test,
 * otherwise it would create a new one
 */
const CreateUpdateTestModal = ({ idTest }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const test = useSelector(getTest(idTest));

  // Fetch all categories
  const { data: allCategories } = apiSlice[TEST.GET_CATEGORIES.QUERY](null, {
    refetchOnMountOrArgChange: true,
  });

  const [state, setState] = useState({
    categories: test?.categories ?? [],
    name: test?.name ?? '',
    notification: null,
    status: test?.status ?? TEST_STATE.DRAFT,
  });

  // Create test
  const [createTest, result] = apiSlice[TEST.CREATE.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(closeModal());
      navigate(`${ROUTES.DASHBOARD.TESTS.PATH}/${result.data.idTest}`);
    }
  }, [dispatch, navigate, result?.data?.idTest, result?.isSuccess]);

  const handleSave = () => {
    // Mock test creation wo RTKQ
    const uuid = test?.id ?? uuidv4();

    const isCreated = validateAndSubmit(
      intl,
      () =>
        idTest
          ? // TODO - Remove when api's complete
            dispatch(
              createUpdateTest(
                omit(['notification'], {
                  ...state,
                  createdAt: test?.createdAt ?? +new Date(),
                  creatorEmail: test?.creatorEmail ?? user.creatorEmail,
                  id: uuid,
                })
              )
            )
          : createTest(pick(['categories', 'name', 'status'], state)),
      state,
      setState
    );

    // TODO - Remove when api's complete
    if (isCreated && idTest) {
      navigate(`${ROUTES.DASHBOARD.TESTS.PATH}/${uuid}`);
      dispatch(closeModal());
    }
  };

  return (
    <ModalTemplate
      title={
        <FormattedMessage {...messages[test ? 'updateTest' : 'createTest']} />
      }
      saveBtnTitle={<FormattedMessage {...messages.save} />}
      onSave={handleSave}
    >
      {/* Name */}
      <strong>{intl.formatMessage(messages.testName)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', name: e.target.value })
        }
        placeholder={intl.formatMessage(messages.addTestName)}
        type="text"
        value={state.name}
      />
      <br />
      <br />
      {/* Categories */}
      <strong>{intl.formatMessage(messages.categories)}</strong>
      <br />
      {/* TODO - Sometimes diacritics are not shown properly, they get hidden when big caps, e.g. "ČeŠtina".
       Higher padding would fix the issue. Fix this when styling ! */}
      <SelectCategories
        data={allCategories}
        placeholder={intl.formatMessage(messages.typeOrSelectCategories)}
        state={state}
        stateSetter={setState}
      />
      <br />
      <br />
      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default CreateUpdateTestModal;
