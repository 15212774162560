// eslint-disable-next-line import/no-unresolved
import { stripHtml } from 'string-strip-html';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Editor } from '@tinymce/tinymce-react';
import { EDITOR_EXTENDED } from '../../../../consts/EDITOR';
import messages from '../../../../intl/messages';
import TopBar from '../../../Common/TopBar';
import ItemState from '../../../Common/ItemState';
import Distractors from './Distractors';
import Switches from './Switches';
import { QUESTION } from '../../../../consts/RTK';
import { QUESTION_TYPE_ICONS } from '../../../../consts/DESIGN.js';
import {
  QUESTION_STATE,
  QUESTION_TYPE,
  CONTENT_WRAPPER,
} from '../../../../consts/ORGANIZATOR';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { apiSlice } from '../../../../store/api/apiSlice';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './QuestionDetail.scss';

const initialQuestionData = {
  typeValue: {
    content: {
      instructions: '',
      settings: {},
      sections: [],
    },
  },
};

const QuestionDetail = () => {
  const { id } = useParams();
  const { data } = apiSlice[QUESTION.GET.QUERY](id, {
    refetchOnMountOrArgChange: true,
  });
  const { data: allCategories } = apiSlice[QUESTION.GET_CATEGORIES.QUERY](
    null,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: categories } = apiSlice[QUESTION.GET_CATEGORIES.QUERY](id, {
    refetchOnMountOrArgChange: true,
  });

  const questionData = data;
  const question = questionData ?? initialQuestionData;

  const questionType = question.typeDiscriminator;
  const { content, name, score, state } = question.typeValue;
  const instructions = content?.instructions ?? '';
  const settings = content?.settings ?? {};
  const sections = content?.sections ?? [];
  const intl = useIntl();
  const editorRef = useRef();
  const isMultipleChoice = questionType === 1;
  const isDone = state === QUESTION_STATE.DONE;
  const isMissing = [];

  if (!isDone) {
    const strippedInstructions = stripHtml(instructions).result;
    strippedInstructions.length > 0 ||
      isMissing.push(intl.formatMessage(messages.missing.instructions));

    if (isMultipleChoice) {
      let hasDistractor = true;
      let hasFilledDistractor = true;
      let hasCorrectAnswer = false;

      sections.forEach((section) => {
        if (section.distractors.length > 0) {
          section.distractors.forEach((distractor) => {
            const strippedDistractor = stripHtml(distractor.content).result;
            if (strippedDistractor.length === 0) hasFilledDistractor = false;
            if (distractor.isCorrect === true) hasCorrectAnswer = true;
          });
        } else {
          hasDistractor = false;
        }
      });

      hasDistractor ||
        isMissing.push(intl.formatMessage(messages.missing.distractor));
      hasFilledDistractor ||
        isMissing.push(intl.formatMessage(messages.missing.distractorFilled));
      hasCorrectAnswer ||
        isMissing.push(intl.formatMessage(messages.missing.correctAnswer));
    }
  }

  const typeLabel = questionType
    ? intl.formatMessage(
        messages.questionType[QUESTION_TYPE[questionType ?? 1]].label
      )
    : '';

  const [updateQuestionName] =
    apiSlice[QUESTION.UPDATE.QUESTION_NAME.MUTATION]();
  const [updateQuestionContent] =
    apiSlice[QUESTION.UPDATE.QUESTION_CONTENT.MUTATION]();
  const [createQuestionCategories] =
    apiSlice[QUESTION.CREATE_CATEGORIES.MUTATION]();

  const handleUpdateQuestionName = (name) => {
    updateQuestionName({ idItem: id, name });
  };

  const handleUpdateQuestionContent = (editorLastState = false) => {
    if (editorLastState) {
      editorRef.current?.editor.setContent(editorLastState);
    } else {
      const editorText = editorRef.current?.editor.getContent();
      const textWrapped = CONTENT_WRAPPER(editorText);
      const body = {
        ...content,
        instructions: textWrapped,
      };
      updateQuestionContentPolymorphic({ idItem: id, content: body });
    }
    setShowButtons(false);
    editorRef.current?.editor.setDirty(false);
  };

  const wrapContentWithPolymorphFields = (content) => {
    const itemValue = {
      idItem: id,
      ...content,
    };
    const polymorphPayload = {
      typeDiscriminator: questionType,
      typeValue: itemValue,
    };
    return polymorphPayload;
  };

  const updateQuestionContentPolymorphic = (item) => {
    const payload = wrapContentWithPolymorphFields(item.content);
    updateQuestionContent(payload);
  };

  const [positiveScore, setPositiveScore] = useState(score);

  useEffect(() => {
    if (score) {
      setPositiveScore(score);
    }
  }, [score]);

  const [updateQuestionScore] =
    apiSlice[QUESTION.UPDATE.QUESTION_SCORE.MUTATION]();

  const handleInputChange = (e) => {
    if (e.target.value >= 1 && e.target.value <= 1000) {
      setPositiveScore(e.target.value);
    } else if (e.target.value > 1000) {
      setPositiveScore(1000);
    }
  };

  const [showButtons, setShowButtons] = useState(false);

  return (
    questionData && (
      <>
        {/* Top bar */}
        <TopBar
          inputValue={name}
          onInputSave={handleUpdateQuestionName}
          item={id}
        />
        <div className="display-flex flex-justify-between">
          {/* Content - Question */}
          <div className="question-content display-flex flex-column">
            <>
              <Editor
                apiKey={EDITOR_EXTENDED.API_KEY}
                init={EDITOR_EXTENDED.INIT}
                ref={editorRef}
                initialValue={instructions}
                onDirty={() => setShowButtons(true)}
                disabled={isDone}
              />
              <div className="editor-buttons display-flex flex-align-center flex-justify-end gap-12">
                {showButtons && (
                  <>
                    <Button
                      onClick={() => handleUpdateQuestionContent(instructions)}
                    >
                      {intl.formatMessage(messages.cancel)}
                    </Button>
                    <Button
                      className="primary"
                      onClick={() => handleUpdateQuestionContent()}
                    >
                      {intl.formatMessage(messages.save)}
                    </Button>
                  </>
                )}
              </div>
            </>
            {/* Distractors */}
            {isMultipleChoice && (
              <Distractors
                content={content}
                id={id}
                questionType={questionType}
                sections={sections}
                settings={settings}
                updateQuestionContent={updateQuestionContentPolymorphic}
                wrapContentWithPolymorphFields={wrapContentWithPolymorphFields}
                disabled={isDone}
              />
            )}
          </div>

          {/* Right bar - Question info */}
          <div className="detail-settings">
            <Tooltip anchorElement="target" position="top">
              <div className="section-header">
                <h4 className="section-title">
                  {intl.formatMessage(messages.settings)}
                </h4>
              </div>
              <div className="section">
                <strong title={intl.formatMessage(messages.status)}>
                  {intl.formatMessage(messages.status)}
                </strong>
                <ItemState
                  state={state}
                  type={questionType}
                  id={id}
                  missing={isMissing}
                />
              </div>
              <div className="section">
                <strong title={intl.formatMessage(messages.categories)}>
                  {intl.formatMessage(messages.categories)}
                </strong>
                <MultiSelect
                  data={allCategories ?? []}
                  dataItemKey="idCategory"
                  onChange={(e) =>
                    createQuestionCategories({
                      idItem: id,
                      categories: e.value.map(({ idCategory, name }) => ({
                        idCategory: idCategory ?? null,
                        name,
                      })),
                    })
                  }
                  placeholder={intl.formatMessage(
                    messages.typeOrSelectCategories
                  )}
                  textField="name"
                  value={categories}
                  allowCustom
                />
              </div>
              <div className="section with-image">
                <strong title={intl.formatMessage(messages.type)}>
                  {intl.formatMessage(messages.type)}
                </strong>
                <img
                  alt={QUESTION_TYPE_ICONS[questionType].alt}
                  src={QUESTION_TYPE_ICONS[questionType].src}
                />
                {typeLabel}
              </div>
              {/* Right bar - Page info */}
              {isMultipleChoice && (
                <>
                  <Switches
                    content={content}
                    id={id}
                    questionType={questionType}
                    settings={settings}
                    updateQuestionContent={updateQuestionContentPolymorphic}
                    disabled={isDone}
                  />
                  {/* Score */}
                  <div className="section" disabled={isDone}>
                    <strong
                      className="margin-right-20"
                      title={intl.formatMessage(messages.scoreDescription)}
                    >
                      {intl.formatMessage(messages.score)}
                    </strong>
                    <Input
                      name="positiveScore"
                      className="margin-0"
                      value={positiveScore}
                      type="number"
                      min="1"
                      max="1000"
                      step="any"
                      onChange={(e) => handleInputChange(e)}
                      onBlur={() =>
                        updateQuestionScore({
                          idItem: id,
                          score: positiveScore,
                          negativeScore: 0,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </Tooltip>
          </div>
        </div>
      </>
    )
  );
};

export default QuestionDetail;
