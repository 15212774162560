import { Input } from '@progress/kendo-react-inputs';
import { omit } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { closeModal } from '../../store/modals/modalsSlice';
import { apiSlice } from '../../store/api/apiSlice';
import { validateAndSubmit } from '../../utils';
import ModalTemplate from './ModalTemplate';
import { ORGANIZATION } from '../../consts/RTK';

const initState = {
  email: '',
  notification: null,
  name: '',
  organizationName: '',
  surname: '',
  address: '',
  city: '',
  postalCode: '',
};

const CreateOrganizationModal = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initState);

  const intl = useIntl();
  const [postOrganizations, result] = apiSlice[ORGANIZATION.CREATE.MUTATION]();
  const apiError = result?.error?.data?.message ?? null;

  useEffect(() => {
    if (result.isSuccess) dispatch(closeModal());
  }, [dispatch, result.isSuccess]);

  return (
    <ModalTemplate
      title={<FormattedMessage {...messages.createNewOrganization} />}
      saveBtnTitle={<FormattedMessage {...messages.create} />}
      onSave={() =>
        validateAndSubmit(
          intl,
          () => postOrganizations(omit(['notification'], state)),
          state,
          setState
        )
      }
    >
      {/* Organization name */}
      <strong>{intl.formatMessage(messages.organizationName)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({
            ...state,
            notification: '',
            organizationName: e.target.value,
          })
        }
        placeholder={intl.formatMessage(messages.addOrganization)}
        type="text"
        value={state.organizationName}
      />
      <br />
      <br />
      {/* Address */}
      <strong>{intl.formatMessage(messages.companyAddress)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({
            ...state,
            notification: '',
            address: e.target.value,
          })
        }
        placeholder={intl.formatMessage(messages.companyAddressStreet)}
        type="text"
        value={state.address}
        style={{ marginBottom: '10px' }}
      />
      <br />
      <div className="display-flex">
        <Input
          onChange={(e) =>
            setState({
              ...state,
              notification: '',
              city: e.target.value,
            })
          }
          placeholder={intl.formatMessage(messages.companyAddressCity)}
          type="text"
          value={state.city}
        />
        <div style={{ width: '30px' }}></div>
        <Input
          onChange={(e) =>
            setState({
              ...state,
              notification: '',
              postalCode: e.target.value,
            })
          }
          placeholder={intl.formatMessage(messages.companyAddressPostalCode)}
          type="text"
          value={state.postalCode}
        />
      </div>
      <br />
      {/* Administrator name */}
      <strong>{intl.formatMessage(messages.administratorName)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', name: e.target.value })
        }
        placeholder={intl.formatMessage(messages.firstName)}
        type="text"
        value={state.name}
        style={{ marginBottom: '10px' }}
      />{' '}
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', surname: e.target.value })
        }
        placeholder={intl.formatMessage(messages.lastName)}
        type="text"
        value={state.surname}
      />
      <br />
      <br />
      {/* Administrator email */}
      <strong>{intl.formatMessage(messages.administratorEmail)}</strong>
      <br />
      <Input
        onChange={(e) =>
          setState({ ...state, notification: '', email: e.target.value })
        }
        placeholder={intl.formatMessage(messages.email)}
        type="text"
        value={state.email}
      />
      <br />
      {/* Notifications */}
      {state.notification}
      {apiError && <span style={{ color: 'red' }}>{apiError}</span>}
    </ModalTemplate>
  );
};

export default CreateOrganizationModal;
