export const API_SEGMENT = {
  AUTHORING: 'authoring',
  MARKING: 'marking',
  ORGANIZATION: 'organization',
  TESTING: 'testing',
  PROCTORING: 'proctoringadmin',
};

const BASE_URL = {
  AUTHORING: `${process.env.REACT_APP_API_URL}/${API_SEGMENT.AUTHORING}`,
  MARKING: `${process.env.REACT_APP_API_URL}/${API_SEGMENT.MARKING}`,
  ORGANIZATION: `${process.env.REACT_APP_API_URL}/${API_SEGMENT.ORGANIZATION}`,
  TESTING: `${process.env.REACT_APP_API_URL}/${API_SEGMENT.TESTING}`,
  PROCTORING: `${process.env.REACT_APP_API_URL}/${API_SEGMENT.PROCTORING}`,
};

export const API = {
  [API_SEGMENT.AUTHORING]: {
    INSTRUCTIONS: `${BASE_URL.AUTHORING}/item/listtestinstructions`,
    QUESTION: `${BASE_URL.AUTHORING}/item`,
    QUESTIONS: `${BASE_URL.AUTHORING}/item/list`,
    QUESTIONS_COUNT: `${BASE_URL.AUTHORING}/item/count`,
    QUESTIONS_DONE: `${BASE_URL.AUTHORING}/item/listdonequestions`,
    QUESTION_CATEGORIES: `${BASE_URL.AUTHORING}/itemcategory`,
    QUESTION_CONTENT: `${BASE_URL.AUTHORING}/item/content`,
    QUESTION_HTML: `${BASE_URL.AUTHORING}/item/html`,
    QUESTION_NAME: `${BASE_URL.AUTHORING}/item/name`,
    QUESTION_STATE: `${BASE_URL.AUTHORING}/item/state`,
    QUESTION_SCORE: `${BASE_URL.AUTHORING}/item/score`,
    SUPPORTING_MATERIALS: `${BASE_URL.AUTHORING}/item/listsupportmaterials`,
    TEST: `${BASE_URL.AUTHORING}/test`,
    TESTS: `${BASE_URL.AUTHORING}/test/list`,
    TESTS_COUNT: `${BASE_URL.AUTHORING}/test/count`,
    TESTS_DONE: `${BASE_URL.AUTHORING}/test/listdone`,
    TEST_CATEGORIES: `${BASE_URL.AUTHORING}/testcategory`,
    TEST_CONTENT: `${BASE_URL.AUTHORING}/test/content`,
    TEST_NAME: `${BASE_URL.AUTHORING}/test/name`,
    TEST_STATE: `${BASE_URL.AUTHORING}/test/state`,
  },
  [API_SEGMENT.MARKING]: {
    EXAM: `${BASE_URL.MARKING}/examinstance`,
    EXAM_LIST: `${BASE_URL.MARKING}/examinstance/list`,
    EXAM_LIST_CSV: `${BASE_URL.MARKING}/examinstance/listcsv`,
    EXAM_LIST_DETAIL_CSV: `${BASE_URL.MARKING}/examinstance/listdetailcsv`,
    EXAM_SUSPICION_DETECTION: `${BASE_URL.MARKING}/examinstance/suspiciondetection`,
  },
  [API_SEGMENT.ORGANIZATION]: {
    BILLING_INVOICES: `${BASE_URL.ORGANIZATION}/billing/invoices`,
    BILLING_INVOICE_HTML: `${BASE_URL.ORGANIZATION}/billing/invoices/html`,
    EVALUATIONS: `${BASE_URL.ORGANIZATION}/evaluations`,
    LOGIN: `${BASE_URL.ORGANIZATION}/auth/login`,
    ORGANIZATION_ADMIN: `${BASE_URL.ORGANIZATION}/organizations/admin`,
    ORGANIZATIONS: `${BASE_URL.ORGANIZATION}/organizations`,
    PROCTORING_TEXTS: `${BASE_URL.ORGANIZATION}/proctoring-texts`,
    USERS: `${BASE_URL.ORGANIZATION}/users`,
    USERS_ADMIN: `${BASE_URL.ORGANIZATION}/users/admin`,
  },
  [API_SEGMENT.TESTING]: {
    CLASSROOM: `${BASE_URL.TESTING}/group`,
    CLASSROOM_EXAMINEE: `${BASE_URL.TESTING}/group/examinee`,
    CLASSROOMS: `${BASE_URL.TESTING}/group/list`,
    CLASSROOM_EXAMINEES: `${BASE_URL.TESTING}/group/examinees`,
    EVENT: `${BASE_URL.TESTING}/schedule`,
    EXAM: `${BASE_URL.TESTING}/exam`,
    EVENTS: `${BASE_URL.TESTING}/exam/list`,
    EVENTS_COUNT: `${BASE_URL.TESTING}/exam/count`,
    EVENTS_PAST: `${BASE_URL.TESTING}/exam/listpast`,
    EXAMINEE: `${BASE_URL.TESTING}/examinee`,
    EXAMINEES: `${BASE_URL.TESTING}/examinee/list`,
    ORGANIZATION_EXAMINEES: `${BASE_URL.TESTING}/group/listexaminee`,
  },
  [API_SEGMENT.PROCTORING]: {
    EXAM_PREVIEW: `${BASE_URL.PROCTORING}/exam_preview`,
    REVIEW_EXAM: `${BASE_URL.PROCTORING}/review`,
  },
};

export const USER_ROLES = {
  ADMIN: 'admin',
  AUTHOR: 'author',
  EVENT_MANAGER: 'eventManager',
  EXAMINEE: 'examinee',
  EXPERT: 'expert',
  REVIEWER: 'reviewer',
  SUPERADMIN: 'superadmin',
};

export const MEMBER_ROLES = {
  ADMIN: 'Admin',
  AUTHOR: 'Author',
  EVENT_MANAGER: 'Event Manager',
  EXPERT: 'Expert',
  REVIEWER: 'Reviewer',
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
};
