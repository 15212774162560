import AddNewButton from './AddNewButton';
import QuestionsList from './QuestionsList';
import messages from '../../../../intl/messages';
import { Context } from './TestDetail';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import Instructions from './Instructions';
import SupportingMaterials from './SupportingMaterials';

const Content = () => {
  const intl = useIntl();
  const {
    currentQuestions,
    supportingMaterials,
    currentPageId,
    isDone,
    isSupportingMaterialsPage,
    isWelcomePage,
  } = useContext(Context);

  return isSupportingMaterialsPage ? (
    // Supporting Materials
    <div className="content">
      <h1>
        {intl.formatMessage(messages.supportingMaterials)}
        {supportingMaterials.length > 0 && (
          <AddNewButton isSupportMaterial isDone={isDone} />
        )}
      </h1>
      <SupportingMaterials />
    </div>
  ) : isWelcomePage ? (
    // Welcome page / Instructions
    <div className="content">
      <h1>{intl.formatMessage(messages.welcomePage)}</h1>
      <Instructions />
    </div>
  ) : (
    // Questions
    <div className="content">
      <h1>
        {intl.formatMessage(messages.page)} {currentPageId + 1}
        {currentQuestions.length > 0 && <AddNewButton isDone={isDone} />}
      </h1>
      <QuestionsList key={currentPageId} />
    </div>
  );
};

export default Content;
