import { PROCTORING } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';
import { API, API_SEGMENT } from '../../consts/API';

export const proctoringApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get evaluations
    [PROCTORING.GET.EVALUATIONS.ENDPOINT]: builder.query({
      query: () => ({
        url: API[API_SEGMENT.ORGANIZATION].EVALUATIONS,
      }),
    }),
    // Get texts
    [PROCTORING.GET.TEXTS.ENDPOINT]: builder.query({
      query: (lang) => ({
        url: API[API_SEGMENT.ORGANIZATION].PROCTORING_TEXTS,
        params: { lang },
      }),
    }),
    // Update evaluations
    [PROCTORING.UPDATE.EVALUATION.ENDPOINT]: builder.mutation({
      query: ({ evaluationId, payload }) => ({
        body: payload,
        method: 'PUT',
        url: `${API[API_SEGMENT.ORGANIZATION].EVALUATIONS}/${evaluationId}`,
      }),
    }),
    // Update texts
    [PROCTORING.UPDATE.TEXTS.ENDPOINT]: builder.mutation({
      query: ({ language, payload }) => ({
        body: payload,
        method: 'PUT',
        params: { lang: language },
        url: API[API_SEGMENT.ORGANIZATION].PROCTORING_TEXTS,
      }),
    }),
    // Get exam preview
    [PROCTORING.GET.EXAM_PREVIEW.ENDPOINT]: builder.query({
      query: ({ idExamInstance }) => ({
        url: `${API[API_SEGMENT.PROCTORING].EXAM_PREVIEW}/${idExamInstance}`,
      }),
      providesTags: ['GetExamPreview'],
    }),
    // Review exam
    [PROCTORING.CREATE.REVIEW.ENDPOINT]: builder.mutation({
      query: ({ idExamInstance, body }) => ({
        body,
        method: 'POST',
        url: `${API[API_SEGMENT.PROCTORING].REVIEW_EXAM}/${idExamInstance}`,
      }),
      invalidatesTags: ['GetExamPreview', 'GetExamList'],
    }),
  }),
});

export const endpoints = proctoringApiSlice.endpoints;
