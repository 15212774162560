import authReducer from './auth/authSlice';
import billingReducer from './billing/billingSlice';
import classroomsReducer from './classrooms/classroomsSlice';
import eventsReducer from './events/eventsSlice';
import modalsReducer from './modals/modalsSlice';
import organizationsReducer from './organizations/organizationsSlice';
import proctoringReducer from './proctoring/proctoringSlice';
import questionsReducer from './questions/questionsSlice';
import testsReducer from './tests/testsSlice';
import uiReducer from './ui/uiSlice';
import usersReducer from './users/usersSlice';
import { REQUEST_STATUS } from '../consts/RTK';
import { apiSlice } from './api/apiSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  billing: billingReducer,
  classrooms: classroomsReducer,
  events: eventsReducer,
  modals: modalsReducer,
  organizations: organizationsReducer,
  proctoring: proctoringReducer,
  questions: questionsReducer,
  tests: testsReducer,
  ui: uiReducer,
  users: usersReducer,
});

const reducerWithReset = (state, action) => {
  const { meta, type } = action;

  if (
    type === 'auth/logOut' ||
    (meta?.requestStatus === REQUEST_STATUS.FULFILLED &&
      meta.arg.endpointName === 'loginToOrganization')
  ) {
    // Redux store hard reset (if logout or switching between organizations)
    state = {};
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: reducerWithReset,
  middleware: (getDefaultMiddleware) =>
    // TODO - task SC-855 - remove serializableCheck after serialized time is saved in redux
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware
    ),
  devTools: true,
  // devTools: process.env.NODE_ENV === 'development', TODO - Uncomment when BE is configured right
});
