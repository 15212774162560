import { QUESTION, QUESTIONS } from '../../consts/RTK';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { endpoints } from './questionsApiSlice';
import { curry } from 'ramda';
import { arrToObjIdAsKey } from '../../utils';

const questionsSlice = createSlice({
  name: 'questions',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        endpoints[QUESTION.CREATE.ENDPOINT].matchFulfilled,
        (state, { payload }) => ({
          ...state,
          [payload.idItem]: { ...payload },
        })
      )
      .addMatcher(
        endpoints[QUESTION.DELETE.ENDPOINT].matchFulfilled,
        (state, { meta }) => {
          Reflect.deleteProperty(state, meta.arg.originalArgs);
        }
      )
      .addMatcher(
        isAnyOf(endpoints[QUESTION.GET.ENDPOINT].matchFulfilled),
        (state, { payload }) => ({
          ...state,
          [payload.idItem]: { ...payload },
        })
      )
      .addMatcher(
        endpoints[QUESTIONS.GET.ENDPOINT].matchFulfilled,
        (state, { payload }) => arrToObjIdAsKey(payload.data, 'idItem')
      )
      .addMatcher(
        isAnyOf(
          endpoints[QUESTION.UPDATE.QUESTION_NAME.ENDPOINT].matchFulfilled,
          endpoints[QUESTION.UPDATE.QUESTION_STATE.ENDPOINT].matchFulfilled
        ),
        (state, { payload }) => {
          state[payload.idItem] = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          endpoints[QUESTION.UPDATE.QUESTION_CONTENT.ENDPOINT].matchFulfilled
        ),
        (state, { payload }) => {
          state[payload.idItem] = payload;
        }
      );
  },
});

export default questionsSlice.reducer;

export const getQuestion = curry((idItem, state) => state.questions?.[idItem]);
export const getQuestions = (state) => state.questions;
