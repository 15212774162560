import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { useState } from 'react';

const SaveCancelInput = ({ onSave, value: initValue, ...rest }) => {
  const [value, setValue] = useState(initValue);
  const isTouched = value !== initValue;

  return (
    <>
      <Input
        value={value}
        onChange={(e) => (!!onSave ? setValue(e.target.value) : null)}
        {...rest}
      />
      {isTouched && (
        <Button
          className="reset-icon-size save-close"
          imageUrl="/images/check.svg"
          onClick={() => onSave(value)}
        />
      )}
      {isTouched && (
        <Button
          className="reset-icon-size save-close"
          imageUrl="/images/close.svg"
          onClick={() => setValue(initValue)}
        />
      )}
    </>
  );
};

export default SaveCancelInput;
